// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Approvals/UserRequestApprovalsResponse.cs

import {z} from 'zod';
import {UserRequestApprovalSchema} from './UserRequestApprovalSchema.schema';

export const UserRequestApprovalsResponseSchema = z.object({
  currentPage: z.number(),
  requests: z.array(UserRequestApprovalSchema),
  resultsPerPage: z.number(),
  totalPages: z.number(),
  totalResults: z.number(),
});
