// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CreditCardAuthorizationStatus.cs

enum CreditCardAuthorizationStatus {
  Pending,
  Closed,
  Reversed,
}

export default CreditCardAuthorizationStatus;
