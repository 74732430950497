import {
  BalanceDetailsVM,
  ConnectedBankAccountVM,
  CreditCardDetailsVM,
  CreditCardOverviewVM,
  CreditCardPaymentDetailsVM,
  CreditCardTransactionVM,
  PlaidLinkConfig,
  TopUpReceiptVM,
} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {CreditCardDetailsVMSchema} from '../zodSchemas/CreditCardDetailsVMSchema.schema';
import {CreditCardTransactionVMSchema} from '../zodSchemas/CreditCardTransactionVMSchema.schema';
import {CreditCardPaymentDetailsVMSchema} from '../zodSchemas/CreditCardPaymentDetailsVMSchema.schema';
import {CreditCardOverviewVMSchema} from '../zodSchemas/CreditCardOverviewVMSchema.schema';
import {PlaidLinkConfigSchema} from '../zodSchemas/PlaidLinkConfigSchema.schema';
import {TopUpReceiptVMSchema} from '../zodSchemas/TopUpReceiptVMSchema.schema';
import {BalanceDetailsVMSchema} from '../zodSchemas/BalanceDetailsVMSchema.schema';
import {ConnectedBankAccountVMSchema} from '../zodSchemas/ConnectedBankAccountVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Payments/PaymentController.cs

/*
|--------------------------------------------------------------------------
|  card-payment-info/{cardId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCardPaymentInfoQuery} from '@generated/hooks';
 *
 *  const {
 *    data: cardPaymentInfo,
 *    isLoading: isCardPaymentInfoLoading,
 *    invalidateExact: invalidateCardPaymentInfo,
 *  } = useCardPaymentInfoQuery({
 *    cardId
 *  })
 *
 */

export const useCardPaymentInfoQuery = queryHookFactory<
  {cardId: number | string}, // path variables
  CreditCardPaymentDetailsVM // return type
>(
  'useCardPaymentInfoQuery',
  ({cardId}) => `payment/card-payment-info/${cardId}`,
  {responseSchema: CreditCardPaymentDetailsVMSchema}
);

// CACHED
export const useCardPaymentInfoQueryCached = queryHookFactory<
  {cardId: number | string}, // path variables
  CreditCardPaymentDetailsVM // return type
>(
  'useCardPaymentInfoQuery',
  ({cardId}) => `payment/card-payment-info/${cardId}`,
  {responseSchema: CreditCardPaymentDetailsVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-card-overview/{planItemId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCardOverviewQuery} from '@generated/hooks';
 *
 *  const {
 *    data: cardOverview,
 *    isLoading: isCardOverviewLoading,
 *    invalidateExact: invalidateCardOverview,
 *  } = useCardOverviewQuery({
 *    planItemId
 *  })
 *
 */

export const useCardOverviewQuery = queryHookFactory<
  {planItemId: number | string}, // path variables
  CreditCardDetailsVM // return type
>(
  'useCardOverviewQuery',
  ({planItemId}) => `payment/get-card-overview/${planItemId}`,
  {responseSchema: CreditCardDetailsVMSchema}
);

// CACHED
export const useCardOverviewQueryCached = queryHookFactory<
  {planItemId: number | string}, // path variables
  CreditCardDetailsVM // return type
>(
  'useCardOverviewQuery',
  ({planItemId}) => `payment/get-card-overview/${planItemId}`,
  {responseSchema: CreditCardDetailsVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-card-transactions/{planItemId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCardTransactionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: cardTransactions,
 *    isLoading: isCardTransactionsLoading,
 *    invalidateExact: invalidateCardTransactions,
 *  } = useCardTransactionsQuery({
 *    planItemId
 *  })
 *
 */

export const useCardTransactionsQuery = queryHookFactory<
  {planItemId: number | string}, // path variables
  CreditCardTransactionVM[] // return type
>(
  'useCardTransactionsQuery',
  ({planItemId}) => `payment/get-card-transactions/${planItemId}`,
  {responseSchema: z.array(CreditCardTransactionVMSchema)}
);

// CACHED
export const useCardTransactionsQueryCached = queryHookFactory<
  {planItemId: number | string}, // path variables
  CreditCardTransactionVM[] // return type
>(
  'useCardTransactionsQuery',
  ({planItemId}) => `payment/get-card-transactions/${planItemId}`,
  {responseSchema: z.array(CreditCardTransactionVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-connected-bank-accounts 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useConnectedBankAccountsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: connectedBankAccounts,
 *    isLoading: isConnectedBankAccountsLoading,
 *    invalidateExact: invalidateConnectedBankAccounts,
 *  } = useConnectedBankAccountsQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useConnectedBankAccountsQuery = queryHookFactory<
  null,
  ConnectedBankAccountVM // return type
>(
  'useConnectedBankAccountsQuery',
  () => `payment/get-connected-bank-accounts`,
  {responseSchema: ConnectedBankAccountVMSchema}
);

// CACHED
export const useConnectedBankAccountsQueryCached = queryHookFactory<
  null,
  ConnectedBankAccountVM // return type
>(
  'useConnectedBankAccountsQuery',
  () => `payment/get-connected-bank-accounts`,
  {responseSchema: ConnectedBankAccountVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-issuing-balance-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIssuingBalanceDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: issuingBalanceDetails,
 *    isLoading: isIssuingBalanceDetailsLoading,
 *    invalidateExact: invalidateIssuingBalanceDetails,
 *  } = useIssuingBalanceDetailsQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useIssuingBalanceDetailsQuery = queryHookFactory<
  null,
  BalanceDetailsVM // return type
>(
  'useIssuingBalanceDetailsQuery',
  () => `payment/get-issuing-balance-details`,
  {responseSchema: BalanceDetailsVMSchema}
);

// CACHED
export const useIssuingBalanceDetailsQueryCached = queryHookFactory<
  null,
  BalanceDetailsVM // return type
>(
  'useIssuingBalanceDetailsQuery',
  () => `payment/get-issuing-balance-details`,
  {responseSchema: BalanceDetailsVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-issuing-balance-history 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIssuingBalanceHistoryQuery} from '@generated/hooks';
 *
 *  const {
 *    data: issuingBalanceHistory,
 *    isLoading: isIssuingBalanceHistoryLoading,
 *    invalidateExact: invalidateIssuingBalanceHistory,
 *  } = useIssuingBalanceHistoryQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useIssuingBalanceHistoryQuery = queryHookFactory<
  null,
  TopUpReceiptVM[] // return type
>(
  'useIssuingBalanceHistoryQuery',
  () => `payment/get-issuing-balance-history`,
  {responseSchema: z.array(TopUpReceiptVMSchema)}
);

// CACHED
export const useIssuingBalanceHistoryQueryCached = queryHookFactory<
  null,
  TopUpReceiptVM[] // return type
>(
  'useIssuingBalanceHistoryQuery',
  () => `payment/get-issuing-balance-history`,
  {responseSchema: z.array(TopUpReceiptVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-onboarding-link 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useOnboardingLinkQuery} from '@generated/hooks';
 *
 *  const {
 *    data: onboardingLink,
 *    isLoading: isOnboardingLinkLoading,
 *    invalidateExact: invalidateOnboardingLink,
 *  } = useOnboardingLinkQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useOnboardingLinkQuery = queryHookFactory<
  null,
  string // return type
>('useOnboardingLinkQuery', () => `payment/get-onboarding-link`, {
  responseSchema: z.string(),
});

// CACHED
export const useOnboardingLinkQueryCached = queryHookFactory<
  null,
  string // return type
>(
  'useOnboardingLinkQuery',
  () => `payment/get-onboarding-link`,
  {responseSchema: z.string()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-plaid-link-config 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePlaidLinkConfigQuery} from '@generated/hooks';
 *
 *  const {
 *    data: plaidLinkConfig,
 *    isLoading: isPlaidLinkConfigLoading,
 *    invalidateExact: invalidatePlaidLinkConfig,
 *  } = usePlaidLinkConfigQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const usePlaidLinkConfigQuery = queryHookFactory<
  null,
  PlaidLinkConfig // return type
>('usePlaidLinkConfigQuery', () => `payment/get-plaid-link-config`, {
  responseSchema: PlaidLinkConfigSchema,
});

// CACHED
export const usePlaidLinkConfigQueryCached = queryHookFactory<
  null,
  PlaidLinkConfig // return type
>(
  'usePlaidLinkConfigQuery',
  () => `payment/get-plaid-link-config`,
  {responseSchema: PlaidLinkConfigSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-user-credit-cards 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserCreditCardsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userCreditCards,
 *    isLoading: isUserCreditCardsLoading,
 *    invalidateExact: invalidateUserCreditCards,
 *  } = useUserCreditCardsQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useUserCreditCardsQuery = queryHookFactory<
  null,
  CreditCardOverviewVM[] // return type
>('useUserCreditCardsQuery', () => `payment/get-user-credit-cards`, {
  responseSchema: z.array(CreditCardOverviewVMSchema),
});

// CACHED
export const useUserCreditCardsQueryCached = queryHookFactory<
  null,
  CreditCardOverviewVM[] // return type
>(
  'useUserCreditCardsQuery',
  () => `payment/get-user-credit-cards`,
  {responseSchema: z.array(CreditCardOverviewVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  is-onboarding-started 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useIsOnboardingStartedQuery} from '@generated/hooks';
 *
 *  const {
 *    data: isOnboardingStarted,
 *    isLoading: isIsOnboardingStartedLoading,
 *    invalidateExact: invalidateIsOnboardingStarted,
 *  } = useIsOnboardingStartedQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useIsOnboardingStartedQuery = queryHookFactory<
  null,
  boolean // return type
>('useIsOnboardingStartedQuery', () => `payment/is-onboarding-started`, {
  responseSchema: z.boolean(),
});

// CACHED
export const useIsOnboardingStartedQueryCached = queryHookFactory<
  null,
  boolean // return type
>(
  'useIsOnboardingStartedQuery',
  () => `payment/is-onboarding-started`,
  {responseSchema: z.boolean()},
  {staleTime: 600000}
);
