import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import CohortsTable from './CohortsTable.container';
import CustomProgramsTable from './../../CustomProgramsTable.container';
import {COLORS} from '@utils/constants';
import {CustomProgramAdminDetailVM} from '@models/serverModels';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ContentContainer = styled.div`
  margin: 24px auto;
  max-width: 1200px;
  padding: 0 32px;
`;

const TableHeader = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 24px;
  colors: ${COLORS.Neutral950};
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramCohortsPageProps {
  visible: boolean;
  onClose: () => void;
  customProgram?: any;
  openCustomProgramEditDrawerAfterDuplication?: (
    customProgram: Pick<CustomProgramAdminDetailVM, 'id' | 'status'>
  ) => void;
}

function CustomProgramCohortsPage({
  visible,
  onClose,
  customProgram,
  openCustomProgramEditDrawerAfterDuplication,
}: CustomProgramCohortsPageProps) {
  return (
    <LearnInPageDrawer
      zIndex={997}
      onClose={onClose}
      visible={visible && !!customProgram}
      title={customProgram?.title}>
      <ContentContainer>
        <TableHeader>{i18n.t(k.PROGRAM__CUSTOM__PARENT)}</TableHeader>
        <CustomProgramsTable
          disableClickRow
          hasContainer={true}
          dataSource={[customProgram]}
          pagination={false}
          closeCohortsDrawer={onClose}
          openCustomProgramEditDrawerAfterDuplication={
            openCustomProgramEditDrawerAfterDuplication
          }
        />
        <div style={{marginBottom: '32px'}} />
        <CohortsTable customProgramId={customProgram?.id} />
      </ContentContainer>
    </LearnInPageDrawer>
  );
}

export default CustomProgramCohortsPage;
