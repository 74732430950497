import {AcademyPeopleLeadershipTabContainerProps} from './AcademyPeopleLeadershipTab.interfaces';
import AcademyPeopleLeadershipTabView from './AcademyPeopleLeadershipTab.view';
import {useParams} from 'react-router-dom';
import {
  useAcademyEditorsQueryCached,
  useAcademyProjectReviewersQueryCached,
} from '@generated/hooks/academy.get.hooks';

function AcademyPeopleLeadershipTabContainer({}: AcademyPeopleLeadershipTabContainerProps) {
  const {academyId} = useParams();
  const {data: editors} = useAcademyEditorsQueryCached({
    academyId: parseInt(academyId),
  });
  const {data: projectReviewers} = useAcademyProjectReviewersQueryCached({
    academyId: parseInt(academyId),
  });

  return (
    <AcademyPeopleLeadershipTabView
      editors={editors}
      projectReviewers={projectReviewers}
      showEditors={!!editors?.length}
      showProjectReviewers={!!projectReviewers?.length}
    />
  );
}

export default AcademyPeopleLeadershipTabContainer;
