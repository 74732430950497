import {validate} from '@blocks/AddEditContentModal/Form/utils';
import {i18n, k} from '@i18n/translate';
import {AcademyScreens} from '@utils/ClientPaths';
import moment from 'moment';

export const getLabelByScreen = (screen: AcademyScreens) => {
  switch (screen) {
    case AcademyScreens.branding:
      return i18n.t(k.BRAND__BRANDING);
    case AcademyScreens.overview:
      return i18n.t(k.GENERIC__OVERVIEW);
    case AcademyScreens.permissions:
      return i18n.t(k.PERMISSION__PLURAL);
    case AcademyScreens.setup:
      return i18n.t(k.GENERIC__SETUP);
    default:
      return screen;
  }
};

/*
 * Validation rules for duration, start date and end date
 */
export const getDateFieldErrors = () => ({
  duration: i18n.t(k.VALIDATION__DURATION),
  startDate: i18n.t(k.VALIDATION__START_DATE),
  endDate: i18n.t(k.VALIDATION__END_DATE),
});

export const getDurationRules = () => [
  {
    validator: async (_, value: number) => {
      if (value && value <= 0) {
        return validate(false, getDateFieldErrors().duration);
      }
      return Promise.resolve();
    },
  },
];

export const getRunDateRules = (startDate: Date) => [
  {
    // Require end date when start date is entered
    required: !!startDate,
    message: getDateFieldErrors().endDate,
  },
  {
    validator: async (_, value) => {
      if (!startDate && value) {
        // Start date is not valid but end date is provided
        return validate(false, getDateFieldErrors().startDate);
      }
      if (value && startDate && moment(value).isBefore(startDate)) {
        // Check if end date is before start date
        return validate(false, getDateFieldErrors().endDate);
      }
    },
  },
];
