import {CustomProgramStatus} from './../enums';
import {
  AttachmentVM,
  CustomProgramAdminDetailVM,
  CustomProgramAgendaVM,
  CustomProgramCohortAdminVM,
  CustomProgramPreviewVM,
  CustomProgramSearchResult,
  CustomProgramSectionAdminVM,
  GetStartedItemVM,
  LinkVM,
  PermissionVM,
  ProgramSkillVM,
  ProjectAdminVM,
  ProjectSubmissionAdminVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {CustomProgramAdminDetailVMSchema} from '../zodSchemas/CustomProgramAdminDetailVMSchema.schema';
import {CustomProgramPreviewVMSchema} from '../zodSchemas/CustomProgramPreviewVMSchema.schema';
import {CustomProgramAgendaVMSchema} from '../zodSchemas/CustomProgramAgendaVMSchema.schema';
import {GetStartedItemVMSchema} from '../zodSchemas/GetStartedItemVMSchema.schema';
import {ProgramSkillVMSchema} from '../zodSchemas/ProgramSkillVMSchema.schema';
import {CustomProgramSectionAdminVMSchema} from '../zodSchemas/CustomProgramSectionAdminVMSchema.schema';
import {LinkVMSchema} from '../zodSchemas/LinkVMSchema.schema';
import {AttachmentVMSchema} from '../zodSchemas/AttachmentVMSchema.schema';
import {ProjectAdminVMSchema} from '../zodSchemas/ProjectAdminVMSchema.schema';
import {ProjectSubmissionAdminVMSchema} from '../zodSchemas/ProjectSubmissionAdminVMSchema.schema';
import {CustomProgramSearchResultSchema} from '../zodSchemas/CustomProgramSearchResultSchema.schema';
import {CustomProgramCohortAdminVMSchema} from '../zodSchemas/CustomProgramCohortAdminVMSchema.schema';
import {PermissionVMSchema} from '../zodSchemas/PermissionVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramController.cs

/*
|--------------------------------------------------------------------------
|  check-published-custom-programs-exist 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCheckPublishedCustomProgramsExistQuery} from '@generated/hooks';
 *
 *  const {
 *    data: checkPublishedCustomProgramsExist,
 *    isLoading: isCheckPublishedCustomProgramsExistLoading,
 *    invalidateExact: invalidateCheckPublishedCustomProgramsExist,
 *  } = useCheckPublishedCustomProgramsExistQuery(null)
 *
 */

export const useCheckPublishedCustomProgramsExistQuery = queryHookFactory<
  null,
  boolean // return type
>(
  'useCheckPublishedCustomProgramsExistQuery',
  () => `custom-program/check-published-custom-programs-exist`,
  {responseSchema: z.boolean()}
);

// CACHED
export const useCheckPublishedCustomProgramsExistQueryCached = queryHookFactory<
  null,
  boolean // return type
>(
  'useCheckPublishedCustomProgramsExistQuery',
  () => `custom-program/check-published-custom-programs-exist`,
  {responseSchema: z.boolean()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-content-attachment/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentAttachmentQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentAttachment,
 *    isLoading: isCustomProgramContentAttachmentLoading,
 *    invalidateExact: invalidateCustomProgramContentAttachment,
 *  } = useCustomProgramContentAttachmentQuery({
 *    attachmentId
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useCustomProgramContentAttachmentQuery = queryHookFactory<
  {attachmentId: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useCustomProgramContentAttachmentQuery',
  ({attachmentId}) => `custom-program/get-content-attachment/${attachmentId}`,
  {responseSchema: null}
);

// CACHED
export const useCustomProgramContentAttachmentQueryCached = queryHookFactory<
  {attachmentId: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useCustomProgramContentAttachmentQuery',
  ({attachmentId}) => `custom-program/get-content-attachment/${attachmentId}`,
  {responseSchema: null},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-content-attachment-url/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentAttachmentUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentAttachmentUrl,
 *    isLoading: isCustomProgramContentAttachmentUrlLoading,
 *    invalidateExact: invalidateCustomProgramContentAttachmentUrl,
 *  } = useCustomProgramContentAttachmentUrlQuery({
 *    attachmentId
 *  })
 *
 */

export const useCustomProgramContentAttachmentUrlQuery = queryHookFactory<
  {attachmentId: number | string}, // path variables
  string // return type
>(
  'useCustomProgramContentAttachmentUrlQuery',
  ({attachmentId}) =>
    `custom-program/get-content-attachment-url/${attachmentId}`,
  {responseSchema: z.string()}
);

// CACHED
export const useCustomProgramContentAttachmentUrlQueryCached = queryHookFactory<
  {attachmentId: number | string}, // path variables
  string // return type
>(
  'useCustomProgramContentAttachmentUrlQuery',
  ({attachmentId}) =>
    `custom-program/get-content-attachment-url/${attachmentId}`,
  {responseSchema: z.string()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-project-submissions/{projectId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramProjectSubmissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramProjectSubmissions,
 *    isLoading: isCustomProgramProjectSubmissionsLoading,
 *    invalidateExact: invalidateCustomProgramProjectSubmissions,
 *  } = useCustomProgramProjectSubmissionsQuery({
 *    projectId
 *  })
 *
 */

export const useCustomProgramProjectSubmissionsQuery = queryHookFactory<
  {projectId: number}, // path variables
  ProjectSubmissionAdminVM[] // return type
>(
  'useCustomProgramProjectSubmissionsQuery',
  ({projectId}) => `custom-program/get-project-submissions/${projectId}`,
  {responseSchema: z.array(ProjectSubmissionAdminVMSchema)}
);

// CACHED
export const useCustomProgramProjectSubmissionsQueryCached = queryHookFactory<
  {projectId: number}, // path variables
  ProjectSubmissionAdminVM[] // return type
>(
  'useCustomProgramProjectSubmissionsQuery',
  ({projectId}) => `custom-program/get-project-submissions/${projectId}`,
  {responseSchema: z.array(ProjectSubmissionAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-project-submission-url/{submissionId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramProjectSubmissionUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramProjectSubmissionUrl,
 *    isLoading: isCustomProgramProjectSubmissionUrlLoading,
 *    invalidateExact: invalidateCustomProgramProjectSubmissionUrl,
 *  } = useCustomProgramProjectSubmissionUrlQuery({
 *    submissionId
 *  })
 *
 */

export const useCustomProgramProjectSubmissionUrlQuery = queryHookFactory<
  {submissionId: number | string}, // path variables
  string // return type
>(
  'useCustomProgramProjectSubmissionUrlQuery',
  ({submissionId}) =>
    `custom-program/get-project-submission-url/${submissionId}`,
  {responseSchema: z.string()}
);

// CACHED
export const useCustomProgramProjectSubmissionUrlQueryCached = queryHookFactory<
  {submissionId: number | string}, // path variables
  string // return type
>(
  'useCustomProgramProjectSubmissionUrlQuery',
  ({submissionId}) =>
    `custom-program/get-project-submission-url/${submissionId}`,
  {responseSchema: z.string()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-started 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramStartedQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramStarted,
 *    isLoading: isCustomProgramStartedLoading,
 *    invalidateExact: invalidateCustomProgramStarted,
 *  } = useCustomProgramStartedQuery(null)
 *
 */

export const useCustomProgramStartedQuery = queryHookFactory<
  null,
  GetStartedItemVM[] // return type
>('useCustomProgramStartedQuery', () => `custom-program/get-started`, {
  responseSchema: z.array(GetStartedItemVMSchema),
});

// CACHED
export const useCustomProgramStartedQueryCached = queryHookFactory<
  null,
  GetStartedItemVM[] // return type
>(
  'useCustomProgramStartedQuery',
  () => `custom-program/get-started`,
  {responseSchema: z.array(GetStartedItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-user-permissions/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramUserPermissionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramUserPermissions,
 *    isLoading: isCustomProgramUserPermissionsLoading,
 *    invalidateExact: invalidateCustomProgramUserPermissions,
 *  } = useCustomProgramUserPermissionsQuery({
 *    customProgramId
 *  })
 *
 */

export const useCustomProgramUserPermissionsQuery = queryHookFactory<
  {customProgramId: number}, // path variables
  PermissionVM[] // return type
>(
  'useCustomProgramUserPermissionsQuery',
  ({customProgramId}) =>
    `custom-program/get-user-permissions/${customProgramId}`,
  {responseSchema: z.array(PermissionVMSchema)}
);

// CACHED
export const useCustomProgramUserPermissionsQueryCached = queryHookFactory<
  {customProgramId: number}, // path variables
  PermissionVM[] // return type
>(
  'useCustomProgramUserPermissionsQuery',
  ({customProgramId}) =>
    `custom-program/get-user-permissions/${customProgramId}`,
  {responseSchema: z.array(PermissionVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramSearch,
 *    isLoading: isCustomProgramSearchLoading,
 *    invalidateExact: invalidateCustomProgramSearch,
 *  } = useCustomProgramSearchQuery({
 *    queryParams: {
 *        durationIds,
 *        keywords,
 *        skillIds
 *    }
 *  })
 *
 */

export const useCustomProgramSearchQuery = queryHookFactory<
  {
    queryParams?: {
      durationIds?: number[];
      keywords?: string;
      skillIds?: number[];
    };
  },
  CustomProgramSearchResult[] // return type
>(
  'useCustomProgramSearchQuery',
  ({queryParams}) =>
    addQueryParams(() => `custom-program/search`, null, queryParams),
  {responseSchema: z.array(CustomProgramSearchResultSchema)}
);

// CACHED
export const useCustomProgramSearchQueryCached = queryHookFactory<
  {
    queryParams?: {
      durationIds?: number[];
      keywords?: string;
      skillIds?: number[];
    };
  },
  CustomProgramSearchResult[] // return type
>(
  'useCustomProgramSearchQuery',
  ({queryParams}) =>
    addQueryParams(() => `custom-program/search`, null, queryParams),
  {responseSchema: z.array(CustomProgramSearchResultSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-cohorts/{parentCustomProgramId:int} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCohortsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: cohorts,
 *    isLoading: isCohortsLoading,
 *    invalidateExact: invalidateCohorts,
 *  } = useCohortsQuery({
 *    parentCustomProgramId
 *  })
 *
 */

export const useCohortsQuery = queryHookFactory<
  {parentCustomProgramId: number}, // path variables
  CustomProgramCohortAdminVM[] // return type
>(
  'useCohortsQuery',
  ({parentCustomProgramId}) =>
    `custom-program/get-cohorts/${parentCustomProgramId}`,
  {responseSchema: z.array(CustomProgramCohortAdminVMSchema)}
);

// CACHED
export const useCohortsQueryCached = queryHookFactory<
  {parentCustomProgramId: number}, // path variables
  CustomProgramCohortAdminVM[] // return type
>(
  'useCohortsQuery',
  ({parentCustomProgramId}) =>
    `custom-program/get-cohorts/${parentCustomProgramId}`,
  {responseSchema: z.array(CustomProgramCohortAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program/{customProgramId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgram,
 *    isLoading: isCustomProgramLoading,
 *    invalidateExact: invalidateCustomProgram,
 *  } = useCustomProgramQuery({
 *    customProgramId
 *  })
 *
 */

export const useCustomProgramQuery = queryHookFactory<
  {customProgramId: number}, // path variables
  CustomProgramAdminDetailVM // return type
>(
  'useCustomProgramQuery',
  ({customProgramId}) => `custom-program/get-custom-program/${customProgramId}`,
  {responseSchema: CustomProgramAdminDetailVMSchema}
);

// CACHED
export const useCustomProgramQueryCached = queryHookFactory<
  {customProgramId: number}, // path variables
  CustomProgramAdminDetailVM // return type
>(
  'useCustomProgramQuery',
  ({customProgramId}) => `custom-program/get-custom-program/${customProgramId}`,
  {responseSchema: CustomProgramAdminDetailVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-agenda/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramAgendaQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramAgenda,
 *    isLoading: isCustomProgramAgendaLoading,
 *    invalidateExact: invalidateCustomProgramAgenda,
 *  } = useCustomProgramAgendaQuery({
 *    customProgramId
 *  })
 *
 */

export const useCustomProgramAgendaQuery = queryHookFactory<
  {customProgramId: number | string}, // path variables
  CustomProgramAgendaVM[] // return type
>(
  'useCustomProgramAgendaQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-agenda/${customProgramId}`,
  {responseSchema: z.array(CustomProgramAgendaVMSchema)}
);

// CACHED
export const useCustomProgramAgendaQueryCached = queryHookFactory<
  {customProgramId: number | string}, // path variables
  CustomProgramAgendaVM[] // return type
>(
  'useCustomProgramAgendaQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-agenda/${customProgramId}`,
  {responseSchema: z.array(CustomProgramAgendaVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-content-attachments/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentAttachmentsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentAttachments,
 *    isLoading: isCustomProgramContentAttachmentsLoading,
 *    invalidateExact: invalidateCustomProgramContentAttachments,
 *  } = useCustomProgramContentAttachmentsQuery({
 *    contentId
 *  })
 *
 */

export const useCustomProgramContentAttachmentsQuery = queryHookFactory<
  {contentId: number | string}, // path variables
  AttachmentVM[] // return type
>(
  'useCustomProgramContentAttachmentsQuery',
  ({contentId}) =>
    `custom-program/get-custom-program-content-attachments/${contentId}`,
  {responseSchema: z.array(AttachmentVMSchema)}
);

// CACHED
export const useCustomProgramContentAttachmentsQueryCached = queryHookFactory<
  {contentId: number | string}, // path variables
  AttachmentVM[] // return type
>(
  'useCustomProgramContentAttachmentsQuery',
  ({contentId}) =>
    `custom-program/get-custom-program-content-attachments/${contentId}`,
  {responseSchema: z.array(AttachmentVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-content-links/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentLinksQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramContentLinks,
 *    isLoading: isCustomProgramContentLinksLoading,
 *    invalidateExact: invalidateCustomProgramContentLinks,
 *  } = useCustomProgramContentLinksQuery({
 *    contentId
 *  })
 *
 */

export const useCustomProgramContentLinksQuery = queryHookFactory<
  {contentId: number | string}, // path variables
  LinkVM[] // return type
>(
  'useCustomProgramContentLinksQuery',
  ({contentId}) =>
    `custom-program/get-custom-program-content-links/${contentId}`,
  {responseSchema: z.array(LinkVMSchema)}
);

// CACHED
export const useCustomProgramContentLinksQueryCached = queryHookFactory<
  {contentId: number | string}, // path variables
  LinkVM[] // return type
>(
  'useCustomProgramContentLinksQuery',
  ({contentId}) =>
    `custom-program/get-custom-program-content-links/${contentId}`,
  {responseSchema: z.array(LinkVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-preview/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramPreviewQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramPreview,
 *    isLoading: isCustomProgramPreviewLoading,
 *    invalidateExact: invalidateCustomProgramPreview,
 *  } = useCustomProgramPreviewQuery({
 *    customProgramId,
 *      queryParams: {
 *        includeProgress,
 *        skipEmptySections,
 *        userRequest
 *    }
 *  })
 *
 */

export const useCustomProgramPreviewQuery = queryHookFactory<
  {
    customProgramId: number | string;
    queryParams?: {
      includeProgress?: boolean;
      skipEmptySections?: boolean;
      userRequest?: boolean;
    };
  }, // path variables
  CustomProgramPreviewVM // return type
>(
  'useCustomProgramPreviewQuery',
  ({customProgramId, queryParams}) =>
    addQueryParams(
      ({customProgramId}) =>
        `custom-program/get-custom-program-preview/${customProgramId}`,
      {customProgramId},
      queryParams
    ),
  {responseSchema: CustomProgramPreviewVMSchema}
);

// CACHED
export const useCustomProgramPreviewQueryCached = queryHookFactory<
  {
    customProgramId: number | string;
    queryParams?: {
      includeProgress?: boolean;
      skipEmptySections?: boolean;
      userRequest?: boolean;
    };
  }, // path variables
  CustomProgramPreviewVM // return type
>(
  'useCustomProgramPreviewQuery',
  ({customProgramId, queryParams}) =>
    addQueryParams(
      ({customProgramId}) =>
        `custom-program/get-custom-program-preview/${customProgramId}`,
      {customProgramId},
      queryParams
    ),
  {responseSchema: CustomProgramPreviewVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-projects/{customProgramId:long} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramProjectsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramProjects,
 *    isLoading: isCustomProgramProjectsLoading,
 *    invalidateExact: invalidateCustomProgramProjects,
 *  } = useCustomProgramProjectsQuery({
 *    customProgramId
 *  })
 *
 */

export const useCustomProgramProjectsQuery = queryHookFactory<
  {customProgramId: number | string}, // path variables
  ProjectAdminVM[] // return type
>(
  'useCustomProgramProjectsQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-projects/${customProgramId}`,
  {responseSchema: z.array(ProjectAdminVMSchema)}
);

// CACHED
export const useCustomProgramProjectsQueryCached = queryHookFactory<
  {customProgramId: number | string}, // path variables
  ProjectAdminVM[] // return type
>(
  'useCustomProgramProjectsQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-projects/${customProgramId}`,
  {responseSchema: z.array(ProjectAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-custom-program-sections/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSectionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramSections,
 *    isLoading: isCustomProgramSectionsLoading,
 *    invalidateExact: invalidateCustomProgramSections,
 *  } = useCustomProgramSectionsQuery({
 *    customProgramId
 *  })
 *
 */

export const useCustomProgramSectionsQuery = queryHookFactory<
  {customProgramId: number | string}, // path variables
  CustomProgramSectionAdminVM[] // return type
>(
  'useCustomProgramSectionsQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-sections/${customProgramId}`,
  {responseSchema: z.array(CustomProgramSectionAdminVMSchema)}
);

// CACHED
export const useCustomProgramSectionsQueryCached = queryHookFactory<
  {customProgramId: number | string}, // path variables
  CustomProgramSectionAdminVM[] // return type
>(
  'useCustomProgramSectionsQuery',
  ({customProgramId}) =>
    `custom-program/get-custom-program-sections/${customProgramId}`,
  {responseSchema: z.array(CustomProgramSectionAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-parent-custom-programs 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useParentCustomProgramsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: parentCustomPrograms,
 *    isLoading: isParentCustomProgramsLoading,
 *    invalidateExact: invalidateParentCustomPrograms,
 *  } = useParentCustomProgramsQuery({
 *    queryParams: {
 *        status
 *    }
 *  })
 *
 */

export const useParentCustomProgramsQuery = queryHookFactory<
  {queryParams?: {status?: CustomProgramStatus}},
  CustomProgramAdminDetailVM[] // return type
>(
  'useParentCustomProgramsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `custom-program/get-parent-custom-programs`,
      null,
      queryParams
    ),
  {responseSchema: z.array(CustomProgramAdminDetailVMSchema)}
);

// CACHED
export const useParentCustomProgramsQueryCached = queryHookFactory<
  {queryParams?: {status?: CustomProgramStatus}},
  CustomProgramAdminDetailVM[] // return type
>(
  'useParentCustomProgramsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `custom-program/get-parent-custom-programs`,
      null,
      queryParams
    ),
  {responseSchema: z.array(CustomProgramAdminDetailVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-skill-tags 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillTagsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skillTags,
 *    isLoading: isSkillTagsLoading,
 *    invalidateExact: invalidateSkillTags,
 *  } = useSkillTagsQuery(null)
 *
 */

export const useSkillTagsQuery = queryHookFactory<
  null,
  ProgramSkillVM[] // return type
>('useSkillTagsQuery', () => `custom-program/get-skill-tags`, {
  responseSchema: z.array(ProgramSkillVMSchema),
});

// CACHED
export const useSkillTagsQueryCached = queryHookFactory<
  null,
  ProgramSkillVM[] // return type
>(
  'useSkillTagsQuery',
  () => `custom-program/get-skill-tags`,
  {responseSchema: z.array(ProgramSkillVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-upcoming-cohorts/{customProgramId:int} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpcomingCohortsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: upcomingCohorts,
 *    isLoading: isUpcomingCohortsLoading,
 *    invalidateExact: invalidateUpcomingCohorts,
 *  } = useUpcomingCohortsQuery({
 *    customProgramId
 *  })
 *
 */

export const useUpcomingCohortsQuery = queryHookFactory<
  {customProgramId: number}, // path variables
  CustomProgramCohortAdminVM[] // return type
>(
  'useUpcomingCohortsQuery',
  ({customProgramId}) =>
    `custom-program/get-upcoming-cohorts/${customProgramId}`,
  {responseSchema: z.array(CustomProgramCohortAdminVMSchema)}
);

// CACHED
export const useUpcomingCohortsQueryCached = queryHookFactory<
  {customProgramId: number}, // path variables
  CustomProgramCohortAdminVM[] // return type
>(
  'useUpcomingCohortsQuery',
  ({customProgramId}) =>
    `custom-program/get-upcoming-cohorts/${customProgramId}`,
  {responseSchema: z.array(CustomProgramCohortAdminVMSchema)},
  {staleTime: 600000}
);
