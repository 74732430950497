import {
  NewReimbursementApprovalApplicationVM,
  ReimbursementTargetVM,
  ReimbursementVM,
  UserRequestApprovalVM,
} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {ReimbursementTargetVMSchema} from '../zodSchemas/ReimbursementTargetVMSchema.schema';
import {UserRequestApprovalVMSchema} from '../zodSchemas/UserRequestApprovalVMSchema.schema';
import {ReimbursementVMSchema} from '../zodSchemas/ReimbursementVMSchema.schema';
import {NewReimbursementApprovalApplicationVMSchema} from '../zodSchemas/NewReimbursementApprovalApplicationVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Incentives/FinanceController.cs

/*
|--------------------------------------------------------------------------
|  get-reimbursement/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursement,
 *    isLoading: isReimbursementLoading,
 *    invalidateExact: invalidateReimbursement,
 *  } = useReimbursementQuery({
 *    id
 *  })
 *
 */

export const useReimbursementQuery = queryHookFactory<
  {id: number | string}, // path variables
  UserRequestApprovalVM // return type
>('useReimbursementQuery', ({id}) => `finance/get-reimbursement/${id}`, {
  responseSchema: UserRequestApprovalVMSchema,
});

// CACHED
export const useReimbursementQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  UserRequestApprovalVM // return type
>(
  'useReimbursementQuery',
  ({id}) => `finance/get-reimbursement/${id}`,
  {responseSchema: UserRequestApprovalVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-reimbursement-approval-applications 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementApprovalApplicationsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementApprovalApplications,
 *    isLoading: isReimbursementApprovalApplicationsLoading,
 *    invalidateExact: invalidateReimbursementApprovalApplications,
 *  } = useReimbursementApprovalApplicationsQuery(null)
 *
 */

export const useReimbursementApprovalApplicationsQuery = queryHookFactory<
  null,
  NewReimbursementApprovalApplicationVM[] // return type
>(
  'useReimbursementApprovalApplicationsQuery',
  () => `finance/get-reimbursement-approval-applications`,
  {responseSchema: z.array(NewReimbursementApprovalApplicationVMSchema)}
);

// CACHED
export const useReimbursementApprovalApplicationsQueryCached = queryHookFactory<
  null,
  NewReimbursementApprovalApplicationVM[] // return type
>(
  'useReimbursementApprovalApplicationsQuery',
  () => `finance/get-reimbursement-approval-applications`,
  {responseSchema: z.array(NewReimbursementApprovalApplicationVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-reimbursement-image/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementImageQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementImage,
 *    isLoading: isReimbursementImageLoading,
 *    invalidateExact: invalidateReimbursementImage,
 *  } = useReimbursementImageQuery({
 *    id
 *  })
 *
 */

export const useReimbursementImageQuery = queryHookFactory<
  {id: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useReimbursementImageQuery',
  ({id}) => `finance/get-reimbursement-image/${id}`,
  {responseSchema: null}
);

// CACHED
export const useReimbursementImageQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useReimbursementImageQuery',
  ({id}) => `finance/get-reimbursement-image/${id}`,
  {responseSchema: null},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-reimbursement-image-url/{imageId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementImageUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementImageUrl,
 *    isLoading: isReimbursementImageUrlLoading,
 *    invalidateExact: invalidateReimbursementImageUrl,
 *  } = useReimbursementImageUrlQuery({
 *    imageId
 *  })
 *
 */

export const useReimbursementImageUrlQuery = queryHookFactory<
  {imageId: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useReimbursementImageUrlQuery',
  ({imageId}) => `finance/get-reimbursement-image-url/${imageId}`,
  {responseSchema: null}
);

// CACHED
export const useReimbursementImageUrlQueryCached = queryHookFactory<
  {imageId: number | string}, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useReimbursementImageUrlQuery',
  ({imageId}) => `finance/get-reimbursement-image-url/${imageId}`,
  {responseSchema: null},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-reimbursement-targets/{planItemId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReimbursementTargetsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reimbursementTars,
 *    isLoading: isReimbursementTarsLoading,
 *    invalidateExact: invalidateReimbursementTars,
 *  } = useReimbursementTargetsQuery({
 *    planItemId
 *  })
 *
 */

export const useReimbursementTargetsQuery = queryHookFactory<
  {planItemId: number | string}, // path variables
  ReimbursementTargetVM // return type
>(
  'useReimbursementTargetsQuery',
  ({planItemId}) => `finance/get-reimbursement-targets/${planItemId}`,
  {responseSchema: ReimbursementTargetVMSchema}
);

// CACHED
export const useReimbursementTargetsQueryCached = queryHookFactory<
  {planItemId: number | string}, // path variables
  ReimbursementTargetVM // return type
>(
  'useReimbursementTargetsQuery',
  ({planItemId}) => `finance/get-reimbursement-targets/${planItemId}`,
  {responseSchema: ReimbursementTargetVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-user-reimbursements/{itemId}/{reimbursementItemType} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserReimbursementsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userReimbursements,
 *    isLoading: isUserReimbursementsLoading,
 *    invalidateExact: invalidateUserReimbursements,
 *  } = useUserReimbursementsQuery({
 *    itemId,
 *    reimbursementItemType
 *  })
 *
 */

export const useUserReimbursementsQuery = queryHookFactory<
  {itemId: number | string; reimbursementItemType: number | string}, // path variables
  ReimbursementVM[] // return type
>(
  'useUserReimbursementsQuery',
  ({itemId, reimbursementItemType}) =>
    `finance/get-user-reimbursements/${itemId}/${reimbursementItemType}`,
  {responseSchema: z.array(ReimbursementVMSchema)}
);

// CACHED
export const useUserReimbursementsQueryCached = queryHookFactory<
  {itemId: number | string; reimbursementItemType: number | string}, // path variables
  ReimbursementVM[] // return type
>(
  'useUserReimbursementsQuery',
  ({itemId, reimbursementItemType}) =>
    `finance/get-user-reimbursements/${itemId}/${reimbursementItemType}`,
  {responseSchema: z.array(ReimbursementVMSchema)},
  {staleTime: 600000}
);
