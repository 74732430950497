import {InitiativeAdminVM, InitiativePayload} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {InitiativePayloadSchema} from '../zodSchemas/InitiativePayloadSchema.schema';
import {InitiativeAdminVMSchema} from '../zodSchemas/InitiativeAdminVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Initiatives/InitiativeController.cs

/*
|--------------------------------------------------------------------------
|  get-initiatives-admin 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useInitiativesAdminQuery} from '@generated/hooks';
 *
 *  const {
 *    data: initiativesAdmin,
 *    isLoading: isInitiativesAdminLoading,
 *    invalidateExact: invalidateInitiativesAdmin,
 *  } = useInitiativesAdminQuery(null)
 *
 *  @access Admin Access Only
 *
 */

export const useInitiativesAdminQuery = queryHookFactory<
  null,
  InitiativeAdminVM[] // return type
>('useInitiativesAdminQuery', () => `initiative/get-initiatives-admin`, {
  responseSchema: z.array(InitiativeAdminVMSchema),
});

// CACHED
export const useInitiativesAdminQueryCached = queryHookFactory<
  null,
  InitiativeAdminVM[] // return type
>(
  'useInitiativesAdminQuery',
  () => `initiative/get-initiatives-admin`,
  {responseSchema: z.array(InitiativeAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-details/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useInitiativeDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: initiativeDetails,
 *    isLoading: isInitiativeDetailsLoading,
 *    invalidateExact: invalidateInitiativeDetails,
 *  } = useInitiativeDetailsQuery({
 *    id
 *  })
 *
 */

export const useInitiativeDetailsQuery = queryHookFactory<
  {id: number | string}, // path variables
  InitiativePayload | null // return type
>('useInitiativeDetailsQuery', ({id}) => `initiative/get-details/${id}`, {
  responseSchema: InitiativePayloadSchema.nullable(),
});

// CACHED
export const useInitiativeDetailsQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  InitiativePayload | null // return type
>(
  'useInitiativeDetailsQuery',
  ({id}) => `initiative/get-details/${id}`,
  {responseSchema: InitiativePayloadSchema.nullable()},
  {staleTime: 600000}
);
