import {LicenseStatus} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {Col, Form, Radio, Row, Typography} from 'antd';
import {LicenseLabel} from '../AddUpdateLicenseDrawer.styled';
import {LicenseFormFields} from '../AddUpdateLicenseDrawer.types';

interface LicenseEligibilityItemProps {
  setStatus: (status: LicenseStatus) => void;
}

function LicenseEligibilityItem({setStatus}: LicenseEligibilityItemProps) {
  return (
    <section>
      <Form.Item
        label={
          <LicenseLabel required>
            {i18n.t(k.LICENSE__ACCESS_QUESTION)}
          </LicenseLabel>
        }
        name={LicenseFormFields.Status}>
        <Radio.Group>
          <Row>
            <Col>
              <Radio
                value={LicenseStatus.Published}
                onChange={() => setStatus(LicenseStatus.Published)}>
                {i18n.t(k.GENERIC__EVERYONE)}
              </Radio>
            </Col>
          </Row>
          <Row>
            <Col>
              <Radio
                value={LicenseStatus.Draft}
                onChange={() => setStatus(LicenseStatus.Draft)}>
                <Typography.Text>
                  {i18n.t(k.AVAILABILITY__DRAFT)}
                </Typography.Text>
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
    </section>
  );
}

export default LicenseEligibilityItem;
