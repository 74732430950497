import {AcademyStatus} from './../enums';
import {AcademyPayload} from './../interfaces';
import {
  AttachmentPayloadMockData,
  getAttachmentPayloadMockData,
} from './AttachmentPayload.mock';
import {
  IAcademyBasicInfoMockData,
  getIAcademyBasicInfoMockData,
} from './IAcademyBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyPayloadMockData(
  args?: Partial<AcademyPayload>
): AcademyPayload {
  return {
    ...getIAcademyBasicInfoMockData(),
    academyProfileImageUrl: faker.image.imageUrl(),
    degreedId: String(faker.datatype.number()), // optional
    description: faker.lorem.lines(),
    discussionId: String(faker.datatype.number()), // optional
    duration: faker.datatype.number(), // optional
    endDate: String(faker.date.past()), // optional
    groupId: String(faker.datatype.number()), // optional
    id: faker.datatype.number(),
    images: [getAttachmentPayloadMockData()],
    invitationEmail: faker.datatype.boolean(),
    joinId: String(faker.datatype.number()),
    name: faker.name.fullName(),
    restrictedAccess: faker.datatype.boolean(),
    startDate: String(faker.date.past()), // optional
    status: AcademyStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyPayloadMockData: AcademyPayload = {
  ...IAcademyBasicInfoMockData,
  academyProfileImageUrl: 'https://loremflickr.com/640/480',
  degreedId: '1', // optional
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  discussionId: '1', // optional
  duration: 123, // optional
  endDate: '2023-03-29T22:17:19.738072', // optional
  groupId: '1', // optional
  id: 1,
  images: [AttachmentPayloadMockData],
  invitationEmail: true,
  joinId: '1',
  name: 'Bobby Johnson',
  restrictedAccess: true,
  startDate: '2023-03-29T22:17:19.738072', // optional
  status: AcademyStatus.Draft,
};
