import {LxpContentResponse} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {LxpContentResponseSchema} from '../zodSchemas/LxpContentResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/LxpContentController.cs

/*
|--------------------------------------------------------------------------
|  {type}/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useByTypeQuery} from '@generated/hooks';
 *
 *  const {
 *    data: byType,
 *    isLoading: isByTypeLoading,
 *    invalidateExact: invalidateByType,
 *  } = useByTypeQuery({
 *    id,
 *    type
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useByTypeQuery = queryHookFactory<
  {id: number | string; type: number | string}, // path variables
  LxpContentResponse // return type
>('useByTypeQuery', ({type, id}) => `lxpcontent/${type}/${id}`, {
  responseSchema: LxpContentResponseSchema,
});

// CACHED
export const useByTypeQueryCached = queryHookFactory<
  {id: number | string; type: number | string}, // path variables
  LxpContentResponse // return type
>(
  'useByTypeQuery',
  ({type, id}) => `lxpcontent/${type}/${id}`,
  {responseSchema: LxpContentResponseSchema},
  {staleTime: 600000}
);
