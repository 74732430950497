import {CreditCardAuthorizationStatus, MissingApprovalReason} from './../enums';
import {CreditCardAuthorization} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: CreditCardAuthorization

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreditCardAuthorizationMockData(
  args?: Partial<CreditCardAuthorization>
): CreditCardAuthorization {
  return {
    ...getBaseEntityMockData(),
    amount: faker.datatype.number(),
    approved: faker.datatype.boolean(),
    creditCardId: faker.datatype.number(),
    currency: faker.word.noun(),
    declinedReason: faker.word.noun(),
    id: faker.datatype.number(),
    matchedApprovalUserRequestId: faker.datatype.number(), // optional
    merchantId: String(faker.datatype.number()),
    missingApprovalReason: MissingApprovalReason.NoResponse,
    providerAuthorizationId: String(faker.datatype.number()),
    sendDeclinedEmail: faker.datatype.boolean(),
    status: CreditCardAuthorizationStatus.Pending,
    timeStamp: String(faker.datatype.number()),
    transactionId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreditCardAuthorizationMockData: CreditCardAuthorization = {
  ...BaseEntityMockData,
  amount: 5,
  approved: true,
  creditCardId: 1,
  currency: 'string',
  declinedReason: 'string',
  id: 1,
  matchedApprovalUserRequestId: 1, // optional
  merchantId: '1',
  missingApprovalReason: MissingApprovalReason.NoResponse,
  providerAuthorizationId: '1',
  sendDeclinedEmail: true,
  status: CreditCardAuthorizationStatus.Pending,
  timeStamp: '5',
  transactionId: 1, // optional
};
