import {AcademyStepVM} from '@generated/interfaces';
import IcLxpArticle from '@assets/svg/ic-lxp-article.svg';
import IcLxpAssessment from '@assets/svg/ic-lxp-assessment.svg';
import IcLxpCourse from '@assets/svg/ic-lxp-course.svg';
import IcLxpPodcast from '@assets/svg/ic-lxp-podcast.svg';
import IcLxpVideo from '@assets/svg/ic-lxp-video.svg';
import IcLxpEvent from '@assets/svg/ic-lxp-event.svg';
import {DATE_FORMAT, formatUtcTime} from '@utils/timeUtils';

import {
  getLxpContentNameByAcademyStepType,
  translateLxpDurationUnitType,
} from '../../CatalogContent/utils';
import {COLORS} from '@utils/constants';
import {LxpContentType} from '@generated/enums';
import {
  isBetaEU,
  isBetaUS,
  isDevelopmentAny,
  isLocal,
  isProductionEU,
  isProductionCA,
  isStagingAny,
} from '@utils/environment-helpers';
import {LxpBlobDomains} from '@utils/lxpPaths';

/*
 * @todo: remove this when DurationUnitType is updated to include Questions
 */
export enum DurationUnitType {
  Minutes,
  Hours,
  Days,
  Weeks,
  Months,
  Years,
  Questions,
}

/**
 * @todo replace LxpContentType with AcademyStepType once new types from the LXP are added
 */
export function getDefaultContentImage(lxpContentType: LxpContentType) {
  const previewInfo: {backgroundColor: COLORS; icon: string} = {
    backgroundColor: COLORS.Blue50,
    icon: IcLxpArticle,
  };

  switch (lxpContentType) {
    case LxpContentType.Article:
    case LxpContentType.Pathway:
      previewInfo.icon = IcLxpArticle;
      break;
    case LxpContentType.Assessment:
      previewInfo.icon = IcLxpAssessment;
      break;
    case LxpContentType.Course:
      previewInfo.icon = IcLxpCourse;
      break;
    case LxpContentType.Event:
      previewInfo.icon = IcLxpEvent;
      break;
    case LxpContentType.Episode:
      previewInfo.icon = IcLxpPodcast;
      break;
    case LxpContentType.Video:
      previewInfo.icon = IcLxpVideo;
      break;
    default:
      break;
  }

  return previewInfo;
}

export function getMetaData(step: AcademyStepVM) {
  const metadata = [getLxpContentNameByAcademyStepType(step.lxpContentType)];

  switch (step.type as unknown as LxpContentType) {
    case LxpContentType.Article:
    case LxpContentType.Video:
    case LxpContentType.Episode:
    case LxpContentType.Course:
    case LxpContentType.Pathway:
    case LxpContentType.Assessment:
      // Omit the duration meta data if either its value or the type is missing
      if (!step.lxpDurationValue || !step.lxpDurationUnitType) {
        break;
      }

      metadata.push(
        `${step.lxpDurationValue} ${translateLxpDurationUnitType(
          step.lxpDurationUnitType,
          step.lxpDurationValue !== 1
        )}`
      );
      break;
    case LxpContentType.Event: {
      const localMomentDate = !!step.startDateUtc
        ? formatUtcTime(step.startDateUtc, step.eventTimeZone)
        : null;

      const date = !!localMomentDate
        ? localMomentDate?.format(DATE_FORMAT.MONTH_LONG_DAY_YEAR)
        : '';
      const time = !!localMomentDate
        ? localMomentDate?.format(DATE_FORMAT.TIME_12_HR)
        : '';
      metadata.push(`${date} ${time}`);
      break;
    }
    default:
  }

  return metadata.filter(Boolean).join(' | ');
}

// Get blob base url for LXP content
export const getBlobBaseUrl = () => {
  if (isLocal || isDevelopmentAny || isStagingAny)
    return LxpBlobDomains.Staging;
  if (isBetaEU) return LxpBlobDomains.EUBeta;
  if (isBetaUS) return LxpBlobDomains.USBeta;
  if (isProductionEU) return LxpBlobDomains.EUProd;
  if (isProductionCA) return LxpBlobDomains.CAProd;
  return LxpBlobDomains.USProd;
};

// Get blob url for LXP content
export const getBlobUrl = (url: string) => {
  if (!url) {
    return undefined;
  }

  if (url.lastIndexOf('http', 0) === 0) {
    return url;
  } else if (url.lastIndexOf('~/', 0) === 0) {
    url = url.replace('~/', '/');
  }

  url = `${getBlobBaseUrl()}${url}`;
  return url;
};
