import {useRef} from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import LearnInPageDrawer, {
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import SectionNav from './SectionNav.container';
import ContentCard from './ContentCard.container';
import FirstSectionContentHeader from './FirstSectionContentHeader.container';
import {Breakpoints} from '@utils/responsiveUtils';
import EmptyDataText from '@blocks/NoDataText';
import CustomProgramUserDrawerAdminSideNav from './CustomProgramUserDrawerAdminSideNav.container';
import {ReportsContainer} from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawer';
import CustomProgramReportsScreen from '@components/admin/pages/programs/customPrograms/reports/CustomProgramReportsScreen.container';
import ProjectsScreenWithRouting from '@components/admin/pages/programs/customPrograms/projects/ProjectsScreenWithRouting';
import LoadingSpinner from '@blocks/LoadingSpinner';
import CustomProgramUserDrawerHeader from './CustomProgramUserDrawerHeader';
import AgendaScreen from './Agenda/AgendaScreen/AgendaScreen.container';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {NavigateFunction, Route, Routes} from 'react-router-dom';
import {sortAsc} from '@utils/sort';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {CustomProgramSectionPreviewVM} from '@generated/interfaces';
import {CustomizableLearningCategory} from '@generated/enums';
import {getCalendarLinks} from './EventFooterCTAs/utils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const MainContainer = styled.section<{showAdminNav: boolean}>`
  padding: 16px 0 72px ${96 + NAV_PANEL_WIDTH}px;
  width: 1400px;
  margin: auto;
  box-sizing: border-box;
  max-width: 90%;
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding: 0;
    width: 100%;
    max-width: unset;
    left: 96px;
  }
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-left: 50px;
  margin-bottom: 16px;
`;

const ContentCardsContainer = styled.div`
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding: 0;
  }
`;

const NextSection = styled.div`
  display: flex;
  margin-top: 12px;
`;

const NextSectionText = styled.span`
  align-items: center;
  display: flex;
  font-size: 1rem;
  line-height: 1.125rem;
  margin-left: auto;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramUserDrawerProps {
  currentScreen: CustomProgramScreens;
  isActivePlanItem: boolean;
  nextSection?: CustomProgramSectionPreviewVM | null;
  onChangeUpcomingCohortSelect: (id: number) => void;
  onClickEditProgram: () => void;
  onClickViewCohorts: () => void;
  onClickSectionNavItem: (id: number) => void;
  onClose: () => void;
  parentCustomProgramId: number | undefined;
  showCohortButton?: boolean;
  programId: number;
  programTitle: string;
  selectedContentId?: number;
  selectedSection: CustomProgramSectionPreviewVM | null;
  showAddToPlanButton: boolean;
  showAdminNav: boolean;
  showLoadingScreen: boolean;
  showPlanItemContextMenu?: boolean;
  showProgramHeader: boolean;
  visible: boolean;
  navigate: NavigateFunction;
  getScreenNavItemUrlPath: (programId: number, screen: string) => string;
  showFullPageContent: boolean;
}

export function CustomProgramUserDrawer({
  isActivePlanItem,
  onChangeUpcomingCohortSelect,
  onClickEditProgram,
  onClickViewCohorts,
  onClickSectionNavItem,
  onClose,
  parentCustomProgramId,
  showCohortButton,
  programId,
  programTitle,
  selectedContentId,
  selectedSection,
  nextSection,
  showAdminNav,
  showLoadingScreen,
  showProgramHeader,
  visible,
  currentScreen,
  navigate,
  getScreenNavItemUrlPath,
  showFullPageContent,
  showAddToPlanButton,
}: ICustomProgramUserDrawerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <LearnInPageDrawer
      zIndex={998}
      title={programTitle}
      visible={visible}
      onClose={onClose}
      className={showFullPageContent && 'white-background'}
      contentWrapperStyle={{transform: 'none'}}
      headerContent={
        <CustomProgramUserDrawerHeader
          isActivePlanItem={isActivePlanItem}
          customProgramId={programId}
          onChangeUpcomingCohortSelect={onChangeUpcomingCohortSelect}
          onClickEditProgram={onClickEditProgram}
          onClickViewCohorts={onClickViewCohorts}
          showCohortButton={showCohortButton}
          parentCustomProgramId={parentCustomProgramId}
          onClickSectionNavItem={onClickSectionNavItem}
          selectedSectionId={selectedSection?.id}
          showAddToPlanButton={showAddToPlanButton}
          onClickNavItem={(screen) => {
            navigate(
              getScreenNavItemUrlPath(programId, String(screen).toLowerCase())
            );
          }}
        />
      }>
      <CustomProgramUserDrawerAdminSideNav
        currentScreen={currentScreen}
        customProgramId={programId}
        onClickNavItem={(screen) => {
          navigate(
            getScreenNavItemUrlPath(programId, String(screen).toLowerCase())
          );
        }}
      />
      {showLoadingScreen && <LoadingSpinner />}
      <Routes>
        <Route
          path={CustomProgramScreens.Agenda.toLowerCase()}
          element={
            <AgendaScreen
              title={i18n.t(k.CALENDAR)}
              programId={programId}
              isActivePlanItem={isActivePlanItem}
            />
          }
        />
        <Route
          path={`/${CustomProgramScreens.Curriculum.toLowerCase()}`}
          element={
            <>
              <SectionNav
                title={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
                customProgramId={programId}
                selectedSectionId={selectedSection?.id}
                onClickSectionNavItem={(id) => {
                  onClickSectionNavItem?.(id);
                  containerRef.current?.scrollIntoView(false);
                }}
                aria-label={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
              />
              <MainContainer
                showAdminNav={showAdminNav}
                aria-labelledby="selected-section-title">
                {showProgramHeader && (
                  <FirstSectionContentHeader customProgramId={programId} />
                )}
                <SectionTitle ref={containerRef}>
                  {selectedSection?.title}
                </SectionTitle>
                <ContentCardsContainer>
                  {selectedSection?.content
                    ?.sort(sortAsc('order'))
                    .map(
                      (
                        {
                          allowPeerVisibility,
                          attachments,
                          textContentCompleted,
                          contentType,
                          description,
                          dueDateUtc,
                          endDateUtc,
                          eventLink,
                          eventTimeZone,
                          id,
                          links,
                          startDateUtc,
                          submissionType,
                          submittedFiles,
                          submittedLinks,
                          projectContentCompletedOn,
                          submittedResponse,
                          title,
                          rsvpStatus,
                          feedback,
                          outlookCalendarLink,
                          googleCalendarLink,
                          submissionCount,
                          discussionId,
                          messagesPreview,
                        },
                        idx
                      ) => {
                        if (showFullPageContent && idx !== 0) {
                          return null;
                        }
                        return (
                          <ContentCard
                            discussionId={discussionId}
                            allowPeerVisibility={allowPeerVisibility}
                            peerSubmissionCount={submissionCount}
                            isFullPage={showFullPageContent}
                            messagesPreview={messagesPreview}
                            showSkipProjectButton={false}
                            showSkipProgramButton={false}
                            showUnskipProgramButton={false}
                            isActivePlanItem={isActivePlanItem}
                            attachments={attachments}
                            projectContentCompletedOn={
                              projectContentCompletedOn
                            }
                            completed={
                              isActivePlanItem ? textContentCompleted : false
                            }
                            contentType={contentType}
                            customProgramId={programId}
                            levelId={selectedSection.id}
                            customizableLearningCategory={
                              CustomizableLearningCategory.CustomProgram
                            }
                            description={description}
                            dueDate={dueDateUtc}
                            dueDateUtc={dueDateUtc}
                            endDateUtc={endDateUtc}
                            eventLink={eventLink}
                            eventTimeZone={eventTimeZone}
                            id={id}
                            key={id}
                            links={links}
                            order={idx + 1}
                            shouldBeInFocus={selectedContentId === id}
                            startDateUtc={startDateUtc}
                            submissionType={submissionType}
                            title={title}
                            submittedFile={
                              !!submittedFiles?.length &&
                              submittedFiles[submittedFiles.length - 1]
                            }
                            submittedLink={
                              !!submittedLinks?.length && submittedLinks[0]
                            }
                            submittedResponse={submittedResponse}
                            showIndexRule={
                              selectedSection?.content?.length > 1 &&
                              idx !== selectedSection?.content?.length - 1
                            }
                            rsvpStatus={rsvpStatus}
                            feedback={feedback}
                            calendarLinks={getCalendarLinks({
                              googleCalendarLink,
                              outlookCalendarLink,
                            })}
                          />
                        );
                      }
                    )}
                  {nextSection && (
                    <NextSection>
                      <NextSectionText>
                        {i18n.t(k.SECTION__NEXT__GO_TO)}:
                      </NextSectionText>
                      <LearnInButton
                        color={COLORS.Blue800}
                        fontSize="16px"
                        padding="10px 0px 10px 8px"
                        hoverUnderline
                        showArrowIcon
                        tag={ButtonTags.Link}
                        onClick={() => {
                          onClickSectionNavItem?.(nextSection.id);
                          containerRef.current?.scrollIntoView(false);
                        }}>
                        {nextSection.title}
                      </LearnInButton>
                    </NextSection>
                  )}
                </ContentCardsContainer>
                {!selectedSection?.content.length && (
                  <EmptyDataText
                    header={i18n.t(k.PROGRAM__CUSTOM__EMPTY__TITLE)}
                    subHeader={i18n.t(
                      k.PROGRAM__CUSTOM__EMPTY__DESCRIPTION
                    )}></EmptyDataText>
                )}
              </MainContainer>
            </>
          }
        />
        <Route
          path={CustomProgramScreens.Reports.toLowerCase()}
          element={
            <ReportsContainer>
              <CustomProgramReportsScreen
                title={i18n.t(k.REPORT__PLURAL)}
                customProgramId={programId}
              />
            </ReportsContainer>
          }
        />
        <Route
          path={`${CustomProgramScreens.Projects.toLowerCase()}/*`}
          element={
            <ReportsContainer isCurriculumTabSelected>
              <ProjectsScreenWithRouting
                title={i18n.t(k.PROJECT__PLURAL)}
                basePath={getScreenNavItemUrlPath(
                  programId,
                  CustomProgramScreens.Projects
                )}
                onClose={() =>
                  navigate(
                    getScreenNavItemUrlPath(
                      programId,
                      CustomProgramScreens.Projects
                    )
                  )
                }
              />
            </ReportsContainer>
          }
        />
      </Routes>
    </LearnInPageDrawer>
  );
}

export default CustomProgramUserDrawer;
