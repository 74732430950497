import {i18n, k} from '@i18n/translate';
import {Checkbox, Typography} from 'antd';
import {LicenseRequiredByFormItem} from '../AddUpdateLicenseDrawer.styled';
import {LicenseFormFields} from '../AddUpdateLicenseDrawer.types';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';

interface LicenseApprovalByItemProps {
  requireAdminApproval: boolean;
  requireManagerApproval: boolean;
  setRequireAdminApproval: (value: boolean) => void;
  setRequireManagerApproval: (value: boolean) => void;
}

function LicenseApprovalByItem({
  requireAdminApproval,
  requireManagerApproval,
  setRequireAdminApproval,
  setRequireManagerApproval,
}: LicenseApprovalByItemProps) {
  return (
    <section>
      <LicenseRequiredByFormItem
        label={`${i18n.t(k.GENERIC__APPROVAL_REQUIRED_BY)}`}
        name={LicenseFormFields.Approvals}>
        <div>
          <Checkbox
            checked={requireManagerApproval}
            value={LicenseFormFields.RequireManagerApproval}
            onChange={(event: CheckboxChangeEvent) =>
              setRequireManagerApproval(event.target.checked)
            }>
            <Typography.Text>{i18n.t(k.GENERIC__MANAGER)}</Typography.Text>
          </Checkbox>
        </div>
        <div>
          <Checkbox
            checked={requireAdminApproval}
            value={LicenseFormFields.RequireAdminApproval}
            onChange={(event: CheckboxChangeEvent) =>
              setRequireAdminApproval(event.target.checked)
            }>
            <Typography.Text>{i18n.t(k.ADMIN)}</Typography.Text>
          </Checkbox>
        </div>
      </LicenseRequiredByFormItem>
    </section>
  );
}

export default LicenseApprovalByItem;
