import {Feature} from '@blocks/NudgeEmailPreview';
import {
  useAcademiesUsersNudgeMutation,
  useCustomProgramPreviewQuery,
  useCustomProgramsUsersNudgeMutation,
} from '@generated/hooks';
import {CustomProgramNudgeUserRequest} from '@generated/interfaces';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {AcademyReportsPageTabs, DrawerRoutes} from '@utils/ClientPaths';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useParticipantIfInRoute} from './useParticipantIfInRoute';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {useAcademyQueryCached} from '@generated/hooks/academy.get.hooks';

/**
 * @param feature - enum value for `Feature`
 * @param basePath - optional, if needing to navigate when opening the drawer
 * @param pathSuffix - optional, used with `basePath` to build destination path
 * @param contentId - optional, if a content item should be used
 * @param stepId - optional, if a step should be used
 * @param participants - optional, if filtering participants by an `userCompanyId` (i.e. with `useParticipantIfInRoute`)
 */
export interface UseNudgeEmailConfig {
  feature: Feature;
  route?: {
    basePath?: string;
    pathSuffix?: string;
  };
  contentId?: number;
  stepId?: number;
  participants?: Partial<{
    userCompanyId: number;
    employeeName: string;
    name: string;
  }>[];
}

/**
 * Encapsulates logic and builds the props for the NudgeEmailPreview component
 */
function useNudgeEmail(config: UseNudgeEmailConfig) {
  const navigate = useNavigate();
  const params = useParams();
  const {academyId, customProgramId} = params;
  const {isFeatureFlagOn} = useFeatureFlags();
  const {showSkillsTagging} = useShowSkillsTagging();

  const {data: academy} = useAcademyQueryCached(
    {
      academyId: parseInt(academyId),
      queryParams: {includeSkills: showSkillsTagging},
    },
    {
      enabled:
        config.feature === Feature.Academy &&
        !!academyId &&
        isFeatureFlagOn.NudgeEmails,
    }
  );

  const {data: customProgram} = useCustomProgramPreviewQuery(
    {
      customProgramId,
      queryParams: {includeProgress: false},
    },
    {
      enabled:
        config.feature === Feature.CustomProgram &&
        !!customProgramId &&
        isFeatureFlagOn.NudgeEmails,
    }
  );

  const sendAcademyNudgeMutation = useAcademiesUsersNudgeMutation();
  const sendCustomProgramNudgeMutation = useCustomProgramsUsersNudgeMutation();

  const [emailPreviewDrawer, setEmailPreviewDrawer] = useState<{
    isOpen: boolean;
    userName?: string;
    userCompanyId?: number;
  }>({isOpen: false});

  const toggleEmailPreviewDrawer = (isOpen: boolean) => {
    const updatePath = config?.route?.basePath && !isOpen;
    if (updatePath) {
      navigate([config.route?.basePath, config.route?.pathSuffix].join('/'));
    }
    setEmailPreviewDrawer((state) => ({...state, isOpen}));
  };

  const handleSendNudge = ({note, subject}) => {
    const payload: {
      note: string;
      subject: string;
      contentId?: number;
      stepId?: number;
    } = {
      note,
      subject,
      stepId: config?.stepId,
    };

    if (config.feature === Feature.CustomProgram) {
      payload.contentId = config.contentId || 0;
      sendCustomProgramNudgeMutation.mutate({
        payload: payload as CustomProgramNudgeUserRequest,
        pathVars: {
          customProgramId: parseInt(customProgramId),
          userCompanyId: emailPreviewDrawer.userCompanyId,
        },
      });
    } else if (config.feature === Feature.Academy) {
      payload.contentId = config.contentId || 0;
      sendAcademyNudgeMutation.mutate({
        payload,
        pathVars: {
          academyId: parseInt(academyId),
          userCompanyId: emailPreviewDrawer.userCompanyId,
        },
      });
    }

    toggleEmailPreviewDrawer(false);
  };

  const getProgramOrAcademyName = () => {
    if (config.feature === Feature.Academy) {
      return academy?.name;
    } else {
      return customProgram?.title;
    }
  };

  const getNudgeEmailPreviewRoute = (userCompanyId: number) =>
    [
      config?.route?.basePath,
      AcademyReportsPageTabs.Participants,
      userCompanyId,
      DrawerRoutes.Nudge,
    ].join('/');

  useParticipantIfInRoute(config, params['*'], setEmailPreviewDrawer);

  return {
    nudgeEmailsEnabled: isFeatureFlagOn.NudgeEmails,
    drawerProps: {
      key: emailPreviewDrawer.userName,
      onClose: () => toggleEmailPreviewDrawer(false),
      onSendNudge: handleSendNudge,
      open: emailPreviewDrawer.isOpen && isFeatureFlagOn.NudgeEmails,
      programOrAcademyName: getProgramOrAcademyName(),
      participantName: emailPreviewDrawer.userName,
      feature: config.feature,
    },
    onOpenNudgeEmailPreview: (data: {
      employeeName?: string;
      name?: string;
      userCompanyId: number;
    }) => {
      const updatePath = config?.route?.basePath || config?.route?.pathSuffix;
      if (updatePath) {
        navigate(getNudgeEmailPreviewRoute(data.userCompanyId));
      }
      setEmailPreviewDrawer({
        isOpen: true,
        userName: data.employeeName || data.name,
        userCompanyId: data.userCompanyId,
      });
    },
  };
}

export default useNudgeEmail;
