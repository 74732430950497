import {ErrorMessage} from '@components/reusable/LearnInForm';
import {i18n, k} from '@i18n/translate';
import {
  formatPastingOptions,
  slimToolbarOptionsWithLink,
} from '@utils/reactQuillUtils';
import ReactQuill from 'react-quill';
import {handleLicenseRedemptionGuidelineChange} from '../AddUpdateLicenseDrawer.handlers';
import {
  LicenseLabel,
  LicenseReactQuill,
} from '../AddUpdateLicenseDrawer.styled';
import {
  LicenseFormFieldErrors,
  LicenseFormFields,
} from '../AddUpdateLicenseDrawer.types';

interface LicenseRedemptionGuidelinesItemProps {
  formFieldErrors: LicenseFormFieldErrors;
  redemptionGuideline: string;
  redemptionGuidelineRef: React.MutableRefObject<ReactQuill>;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setRedemptionGuideline: (redemptionGuideline: string) => void;
}

function LicenseRedemptionGuidelinesItem({
  formFieldErrors,
  redemptionGuideline,
  redemptionGuidelineRef,
  setFormFieldErrors,
  setRedemptionGuideline,
}: LicenseRedemptionGuidelinesItemProps) {
  return (
    <section>
      <LicenseLabel
        margin="0 0 0.75rem 0"
        required
        htmlFor="redemption-guideline"
        onClick={() => redemptionGuidelineRef.current?.focus()}>
        {i18n.t(k.LICENSE__ACCESS_GUIDELINES__LABEL)}
      </LicenseLabel>
      <LicenseReactQuill
        id="redemption-guideline"
        formats={formatPastingOptions}
        theme="snow"
        value={redemptionGuideline || ''}
        modules={{toolbar: slimToolbarOptionsWithLink}}
        onChange={(editor) => {
          handleLicenseRedemptionGuidelineChange({
            editor,
            formFieldErrors,
            setFormFieldErrors,
            setLicenseRedemptionGuideline: setRedemptionGuideline,
          });
        }}
        ref={redemptionGuidelineRef}
      />
      <ErrorMessage
        message={formFieldErrors[LicenseFormFields.RedemptionGuideline]}
      />
    </section>
  );
}

export default LicenseRedemptionGuidelinesItem;
