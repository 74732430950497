import {useEffect} from 'react';
import {AcademyPeoplePageContainerProps} from './AcademyPeoplePage.interfaces';
import AcademyPeoplePageView from './AcademyPeoplePage.view';
import {AcademyPeoplePageTabs} from '@utils/ClientPaths';
import {useParams} from 'react-router-dom';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {LoadingScreenContainer} from './AcademyPeoplePage.styled';
import {Helmet} from 'react-helmet-async';
import {
  useAcademyEditorsQueryCached,
  useAcademyProjectReviewersQueryCached,
} from '@generated/hooks/academy.get.hooks';

function AcademyPeoplePageContainer(props: AcademyPeoplePageContainerProps) {
  const {activeTabKey, onChangeTabs, title} = props;
  const {academyId} = useParams();

  // Fetch data
  const {data: editors, isLoading: editorsLoading} =
    useAcademyEditorsQueryCached({academyId: parseInt(academyId)});
  const {data: projectReviewers, isLoading: reviewersLoading} =
    useAcademyProjectReviewersQueryCached({academyId: parseInt(academyId)});

  const thereAreLeaders = !!editors?.length || !!projectReviewers?.length;
  const leadershipDataIsLoading = editorsLoading && reviewersLoading;

  // Ensure correct url navigation
  useEffect(() => {
    if (thereAreLeaders && !activeTabKey) {
      // navigate to leadership tab if no active tab is set
      onChangeTabs(AcademyPeoplePageTabs.Leadership, {replace: true});
    } else if (
      !thereAreLeaders &&
      !leadershipDataIsLoading &&
      activeTabKey !== AcademyPeoplePageTabs.Participants
    ) {
      // navigate to participants tab it is not selected
      onChangeTabs(AcademyPeoplePageTabs.Participants, {replace: true});
    }
  }, [activeTabKey, thereAreLeaders, leadershipDataIsLoading]);

  if (leadershipDataIsLoading) {
    return (
      <LoadingScreenContainer>
        <LoadingSpinner />
      </LoadingScreenContainer>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AcademyPeoplePageView {...props} showLeadershipTab={thereAreLeaders} />
    </>
  );
}

export default AcademyPeoplePageContainer;
