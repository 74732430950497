import {useEffect, useRef, useState} from 'react';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInInput} from '@components/reusable/LearnInForm';
import {
  CalendarOutlined,
  EditOutlined,
  ExperimentOutlined,
  MessageOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  SnippetsOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import ContextMenuButton from '@blocks/ContextMenu';
import OptionsForm from '@components/reusable/OptionForm';
import {setShowContentUpsertModal} from '@components/admin/pages/academies/Levels/LevelsScreen';
import {ContentType} from '@generated/enums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {type InputRef, Input} from 'antd';
import useFeatureFlags from '@hooks/useFeatureFlags';
import AddStepDrawer from './AddStepDrawer';
import {pxToRem} from '@utils/styleMixins';

const {TextArea} = Input;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div<{fullWidthInput?: boolean}>`
  width: 100%;
  max-width: 900px;
  margin: 0px auto 32px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  button {
    &:focus-visible {
      outline: 2px solid ${COLORS.Blue950};
      cursor: pointer;
    }
  }

  .ant-input-group-wrapper,
  .ant-input-group-addon:last-child {
    background: none;
    border: none;
    padding: 0;
  }
`;

const HeaderContainer = styled.div<{fullWidth?: boolean}>`
  display: flex;
  flex-direction: column;
  flex: ${({fullWidth}) => (fullWidth ? '0 0 100%' : '0 0 79%')};
  gap: 6px;
`;

const LevelNameButton = styled.button`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  text-align: left;
  &:hover {
    color: ${COLORS.Blue800};
  }
  width: fit-content;
`;

const LevelDescriptionButton = styled.button`
  width: fit-content;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.Neutral600};
  &:hover {
    color: ${COLORS.Blue800};
    text-decoration: underline;
  }
`;

const MenuButtonContainer = styled.div`
  margin-left: 16px;
  .ant-btn-icon-only.ant-btn-sm {
    height: 40px;
    width: 40px;
  }
`;

const DescriptionLength = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const TitleField: typeof LearnInInput = styled(LearnInInput)`
  .ant-input-group {
    width: 400px;
    border-radius: unset;
  }
  input {
    border-radius: 4px;
    height: 40px;
  }
`;

const DescriptionField: typeof TextArea = styled(TextArea)`
  max-width: 500px !important;
  border-radius: 4px;
`;

//Define maximum description length
const DESCRIPTION_MAX_LENGTH = 150;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface LevelsScreenHeaderProps {
  title: string;
  description: string;
  hideAddStepButton: boolean;
  onClickDelete: () => void;
  onClickAddMarketplaceProgram: () => void;
  onClickAddCustomProgram: () => void;
  onClickShowCustomContentModal: () => void;
  onClickShowDiscussionModal: () => void;
  onClickAddCatalogContent: () => void;
  setShowContentUpsertModal: setShowContentUpsertModal;
  updateLevel: (newTitle: string, newDescription: string) => void;
  selectedLevelId: number;
  handleMoveLevelToTop: () => void;
  handleMoveLevelToBottom: () => void;
  isNewLevel: boolean;
  isLXPUser: boolean;
}

function LevelsScreenHeader({
  title,
  description,
  hideAddStepButton,
  onClickDelete,
  onClickAddMarketplaceProgram,
  onClickAddCustomProgram,
  onClickShowCustomContentModal,
  onClickShowDiscussionModal,
  onClickAddCatalogContent,
  setShowContentUpsertModal,
  updateLevel,
  selectedLevelId,
  handleMoveLevelToTop,
  handleMoveLevelToBottom,
  isNewLevel,
  isLXPUser,
}: LevelsScreenHeaderProps) {
  const [showLevelTitleEdit, setShowLevelTitleEdit] = useState(false);
  const [showLevelDescriptionEdit, setShowLevelDescriptionEdit] =
    useState(false);
  const [showLXPDrawer, setShowLXPDrawer] = useState(false);
  const [_title, setTitle] = useState('');
  const [_description, setDescription] = useState('');
  const levelScreenHeaderId = `level-screen-header`;
  const inputRef = useRef<InputRef>(null);
  const {isFeatureFlagOn} = useFeatureFlags();
  useEffect(() => {
    isNewLevel && showEditTitleInput();
  }, [selectedLevelId]);

  useEffect(() => {
    setTitle(title);
    setDescription(description);
  }, [title, description]);

  const showEditTitleInput = () => {
    setShowLevelTitleEdit(true);
    setTitle(title);
    setTimeout(() => {
      const editTitleInput = document.getElementById('edit-title-input');
      editTitleInput?.focus();
    }, 400);
  };

  const showEditDescriptionInput = () => {
    setShowLevelDescriptionEdit(true);
    setDescription(description);
    setTimeout(() => {
      const editDescriptionInput = document.getElementById(
        'edit-description-input'
      );
      editDescriptionInput?.focus();
    }, 400);
  };
  const formOptions = [
    {
      title: i18n.t(k.PROGRAM__MARKETPLACE__PLURAL),
      subtitle: i18n.t(k.PROGRAM__ADD__EXTERNAL_FROM_LEARN_IN),
      onClick: onClickAddMarketplaceProgram,
      color: COLORS.Green600,
      background: COLORS.Green200,
      Icon: PlusOutlined,
    },
    {
      title: i18n.t(k.PROGRAM__CUSTOM__PLURAL),
      subtitle: i18n.t(k.PROGRAM__CUSTOM__ADD_PROGRAMS_BY),
      onClick: onClickAddCustomProgram,
      color: COLORS.Yellow500,
      background: COLORS.Yellow200,
      Icon: EditOutlined,
    },
    {
      title: i18n.t(k.PROJECT),
      subtitle: i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT),
      onClick: () =>
        setShowContentUpsertModal(true, undefined, ContentType.Project),
      color: COLORS.Red500,
      background: COLORS.Red200,
      Icon: ExperimentOutlined,
    },
    {
      title: i18n.t(k.EVENT),
      subtitle: i18n.t(k.EVENT__SCHEDULE_LIVE),
      onClick: () =>
        setShowContentUpsertModal(true, undefined, ContentType.Event),
      color: COLORS.Blue800,
      background: COLORS.Blue50,
      Icon: CalendarOutlined,
    },
    {
      title: i18n.t(k.DISCUSSION),
      subtitle: i18n.t(k.DISCUSSION__CREATE),
      onClick: onClickShowDiscussionModal,
      color: COLORS.Purple150,
      background: COLORS.Purple350,
      Icon: MessageOutlined,
    },
    {
      title: '',
      subtitle: (
        <p>
          {i18n.t(k.LEVEL__FOOTER_PHRASE)}{' '}
          <span
            className="pseudo-link"
            style={{
              color: COLORS.Blue950,
            }}>
            {i18n.t(k.LEVEL__FOOTER_PHRASE__CREATE_CONTENT)}
          </span>
        </p>
      ),
      onClick: onClickShowCustomContentModal,
      color: COLORS.Red500,
      background: COLORS.White,
    },
  ];
  const LXPOptions = [
    {
      title: i18n.t(k.CONTENT__CATALOG),
      subtitle: i18n.t(k.CONTENT__ADD_FROM_CATALOG),
      onClick: onClickAddCatalogContent,
      color: COLORS.Blue350,
      background: COLORS.Blue150,
      iconBg: COLORS.Blue250,
      Icon: SnippetsOutlined,
    },
    {
      title: i18n.t(k.PROJECT),
      subtitle: i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT),
      onClick: () =>
        setShowContentUpsertModal(true, undefined, ContentType.Project),
      color: COLORS.Pink100,
      background: COLORS.Pink500,
      iconBg: COLORS.Pink400,
      Icon: ExperimentOutlined,
    },
    {
      title: i18n.t(k.DISCUSSION),
      subtitle: i18n.t(k.DISCUSSION__CREATE),
      onClick: onClickShowDiscussionModal,
      color: COLORS.Purple150,
      background: COLORS.Purple350,
      iconBg: COLORS.Purple200,
      Icon: MessageOutlined,
    },
    {
      title: i18n.t(k.CONTENT__BASIC),
      subtitle: i18n.t(k.CONTENT__ADD_CUSTOMIZED),
      onClick: onClickShowCustomContentModal,
      color: COLORS.Neutral700,
      background: COLORS.Neutral100,
      iconBg: COLORS.Neutral200,
      Icon: PaperClipOutlined,
    },
  ];

  return (
    <Container id={levelScreenHeaderId} style={{position: 'relative'}}>
      <HeaderContainer fullWidth={hideAddStepButton}>
        {selectedLevelId !== 0 &&
          (showLevelTitleEdit ? (
            <TitleField
              id="edit-title-input"
              value={_title}
              updateFunc={setTitle}
              onClick={() => {
                inputRef.current!.focus({
                  cursor: 'start',
                });
              }}
              onChange={(e) => setTitle(e.target.value)}
              onFocus={(e) => {
                e.target?.select();
                setShowLevelDescriptionEdit(false);
              }}
              onPressEnter={() => {
                setShowLevelTitleEdit(false);
                updateLevel(_title, _description);
              }}
              addonAfter={
                <div style={{display: 'flex'}}>
                  <LearnInButton
                    style={{padding: 6}}
                    tag={ButtonTags.SecondaryBlackRemovedBorder}
                    icon={<CheckCircleOutlined aria-hidden="true" />}
                    onClick={() => {
                      setShowLevelTitleEdit(false);
                      updateLevel(_title, _description);
                    }}
                  />
                  <LearnInButton
                    style={{paddingLeft: 2, margin: 0}}
                    tag={ButtonTags.SecondaryBlackRemovedBorder}
                    icon={<CloseCircleOutlined aria-hidden="true" />}
                    onClick={() => setShowLevelTitleEdit(false)}
                  />
                </div>
              }
            />
          ) : (
            <LearnInTooltip
              title={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
                item: i18n.t(k.SECTION__TITLE),
              })}
              childHasButton={true}>
              <LevelNameButton
                onClick={showEditTitleInput}
                data-testid="edit-title-button-academies"
                aria-label={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
                  item: i18n.t(k.SECTION__TITLE),
                })}>
                {title || i18n.t(k.LEVEL__STEP__TITLE__ADD)}
                <EditOutlined
                  aria-hidden="true"
                  style={{
                    marginLeft: '9px',
                    color: COLORS.Blue800,
                    fontSize: pxToRem(16, 16),
                  }}
                />
              </LevelNameButton>
            </LearnInTooltip>
          ))}
        {selectedLevelId !== 0 &&
          (showLevelDescriptionEdit ? (
            <>
              <div style={{display: 'flex'}}>
                <DescriptionField
                  id="edit-description-input"
                  className="edit-description-input"
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  value={_description}
                  placeholder={i18n.t(k.LEVEL__STEP__DESCRIPTION__ADD)}
                  onChange={(e) => setDescription(e.target.value)}
                  autoSize={{minRows: 2, maxRows: 3}}
                  onClick={() => {
                    inputRef.current!.focus({
                      cursor: 'start',
                    });
                  }}
                  onFocus={(e) => {
                    e.target?.select();
                    setShowLevelTitleEdit(false);
                  }}
                  onPressEnter={() => {
                    setShowLevelDescriptionEdit(false);
                    updateLevel(_title, _description);
                  }}
                />
                <div style={{display: 'flex'}}>
                  <LearnInButton
                    style={{padding: 6}}
                    tag={ButtonTags.SecondaryBlackRemovedBorder}
                    icon={<CheckCircleOutlined aria-hidden="true" />}
                    onClick={() => {
                      updateLevel(_title, _description);
                      setShowLevelDescriptionEdit(false);
                    }}
                  />
                  <LearnInButton
                    style={{paddingLeft: 2, margin: 0}}
                    tag={ButtonTags.SecondaryBlackRemovedBorder}
                    icon={<CloseCircleOutlined aria-hidden="true" />}
                    onClick={() => setShowLevelDescriptionEdit(false)}
                  />
                </div>
              </div>
            </>
          ) : (
            <LearnInTooltip
              placement="bottom"
              title={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
                item: i18n.t(k.SECTION__DESCRIPTION),
              })}
              childHasButton={true}>
              <LevelDescriptionButton
                data-testid="edit-description-button-academies"
                aria-label={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
                  item: i18n.t(k.SECTION__DESCRIPTION),
                })}
                onClick={showEditDescriptionInput}>
                {description || i18n.t(k.LEVEL__STEP__DESCRIPTION__ADD)}
                <EditOutlined
                  style={{
                    marginLeft: '9px',
                    color: COLORS.Blue800,
                    fontSize: pxToRem(12, 16),
                  }}
                />
              </LevelDescriptionButton>
            </LearnInTooltip>
          ))}
      </HeaderContainer>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {isLXPUser && isFeatureFlagOn.CatalogContentAcademies ? (
          <LearnInButton
            tag={ButtonTags.Secondary}
            onClick={() => setShowLXPDrawer(true)}>
            <span style={{whiteSpace: 'nowrap'}}>
              {i18n.t(k.LEVEL__STEP__ADD)}
            </span>
          </LearnInButton>
        ) : (
          !hideAddStepButton && (
            <OptionsForm
              buttonProps={{tag: ButtonTags.Secondary}}
              buttonLabel={
                <span style={{whiteSpace: 'nowrap'}}>
                  {i18n.t(k.LEVEL__STEP__ADD)}
                </span>
              }
              options={formOptions}
            />
          )
        )}
        <MenuButtonContainer>
          <ContextMenuButton
            overlayStyle={{width: '184px'}}
            popupContainerId={levelScreenHeaderId}
            itemId={selectedLevelId}
            menuItems={[
              {
                label: i18n.t(k.SECTION__RENAME),
                noBottomBorder: true,
                onClick: showEditTitleInput,
              },
              {
                label: i18n.t(k.LEVEL__MOVE_TO_BOTTOM),
                noBottomBorder: true,
                onClick: () => handleMoveLevelToBottom(),
              },
              {
                label: i18n.t(k.LEVEL__MOVE_TO_TOP),
                onClick: () => handleMoveLevelToTop(),
              },
              {
                label: (
                  <span style={{color: COLORS.Red800}}>
                    {i18n.t(k.SECTION__DELETE)}
                  </span>
                ),
                onClick: onClickDelete,
              },
            ]}
          />
        </MenuButtonContainer>
      </div>
      <AddStepDrawer
        visible={showLXPDrawer}
        options={LXPOptions}
        onClose={() => setShowLXPDrawer(false)}
      />
    </Container>
  );
}

export default LevelsScreenHeader;
