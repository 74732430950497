import {FinanceType} from '@generated/enums';
import {useAcademiesCountsQuery, useUserQuery} from '@generated/hooks';
import {useManagedAcademiesQueryCached} from '@generated/hooks/permission.get.hooks';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import {useHasResourcesEnabledQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import useCanShowPrograms from '@hooks/useCanShowPrograms';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useFlagsHaveLoaded} from '@hooks/useFlagsHaveLoaded';
import useTreatment from '@hooks/useTreatment';
import {AcmOptions, BaseOptions} from '@learnin-inc/apollo-react-data-access';
import {IncentivesCountVM} from '@models/serverModels';
import {
  getIncentiveAdminFinanceRq,
  getIncentivesCountRq,
} from '@store/apiEndpoints';
import {cacheForeverOptions, simpleQueryFn, STALE} from '@store/queryClient';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

/**
 * Fix later
 * Note: Accessive use of nested hook calls instead of managed state
 */
export const useAcmOptions = ({
  isLxpApiEnabled,
  featureFlags,
  translate,
  isAdminPath: isAdmin,
}: BaseOptions): AcmOptions => {
  const isLxpAdmin = useIsLxpAdmin();
  const {isFeatureFlagOn} = useFeatureFlags();
  const flagsReady = useFlagsHaveLoaded(isFeatureFlagOn);
  const {user} = useAuth();
  const isApprover = useCanManageApprovals(user);
  const incentivesCountRq = getIncentivesCountRq();
  const {data: incentivesCount, isSuccess: incentivesCountIsSuccess} =
    useQuery<IncentivesCountVM>(
      incentivesCountRq.queryKey,
      () => simpleQueryFn(incentivesCountRq.path),
      cacheForeverOptions
    );
  const showPrograms = useCanShowPrograms();

  const isAcademiesOn =
    useTreatment(FeatureFlagExperiments.Academies) ===
      FeatureFlagTreatments.On && user?.canViewAcademiesFeature;
  const {data: academiesCount, isSuccess: academiesCountIsSuccess} =
    useAcademiesCountsQuery(null, {
      enabled: isAcademiesOn,
      staleTime: STALE.MINUTES.TEN,
    });
  const getLearningBudgetInfoQuery = getIncentiveAdminFinanceRq();
  const {data: learningBudgetInfo} = useQuery(
    getLearningBudgetInfoQuery.queryKey,
    () => simpleQueryFn(getLearningBudgetInfoQuery.path)
  );
  const {data: customProgramPermissions} = useCustomProgramPermissionVMsQuery(
    null,
    {enabled: !isAdmin}
  );

  const {data: academyPermissions} = useManagedAcademiesQueryCached(null, {
    enabled: isFeatureFlagOn.Academies && !isAdmin,
  });

  const {data: showResources} = useHasResourcesEnabledQuery();

  const [options, setOptions] = useState<AcmOptions>(() => ({
    isLxpApiEnabled,
    translate,
    featureFlags,
    isAdminPath: isAdmin,
    isLxpAdmin,
    isFeatureFlagOn,
    learningBudgetInfo,
    incentivesCount,
    incentivesCountIsSuccess,
    showPrograms,
    isAcademiesOn,
    academiesCount,
    academiesCountIsSuccess,
    user,
    isApprover,
    customProgramPermissions,
    academyPermissions,
    showResources,
    isDirectBilling:
      isFeatureFlagOn?.DirectBilling &&
      learningBudgetInfo?.financeType === FinanceType.DirectBilling,
    isProduction: isProductionAny,
    isBeta: isBetaAny,
  }));

  useEffect(() => {
    if (!flagsReady) return;

    const isDirectBilling =
      isFeatureFlagOn?.DirectBilling &&
      learningBudgetInfo?.financeType === FinanceType.DirectBilling;

    setOptions({
      isLxpApiEnabled,
      translate,
      featureFlags,
      isAdminPath: isAdmin,
      isLxpAdmin,
      isFeatureFlagOn,
      learningBudgetInfo,
      incentivesCount,
      incentivesCountIsSuccess,
      showPrograms,
      isAcademiesOn,
      academiesCount,
      academiesCountIsSuccess,
      user,
      isApprover,
      customProgramPermissions,
      academyPermissions,
      showResources,
      isDirectBilling,
      isProduction: isProductionAny,
      isBeta: isBetaAny,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Not including isFeatureFlagOn in the dependency array because it causes infinite loop
    // This should be safe because isFeatureFlagOn is only used after flagsReady is true
    flagsReady,
    learningBudgetInfo,
    incentivesCount,
    incentivesCountIsSuccess,
    showPrograms.loading,
    showResources,
    isAcademiesOn,
    academiesCount,
    academiesCountIsSuccess,
    user,
    isApprover,
    customProgramPermissions,
    academyPermissions,
    isLxpApiEnabled,
    featureFlags,
    isAdmin,
    isLxpAdmin,
    translate,
  ]);

  return options;
};
