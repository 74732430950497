import {useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {useNavigate, useParams} from 'react-router-dom';
import AcademiesTable from './AcademiesTable';
import {Divider, Modal} from 'antd';
import {useDeleteAcademy} from '@hooks/apiEndpoints/academy/mutations';
import {
  useAcademiesQuery,
  useAcademiesCountsQuery,
  useDuplicateAcademyMutation,
} from '@generated/hooks';
import {editAcademyUrl} from '@utils/ClientPaths';
import {useMemo, useState} from 'react';
import {AcademyStatus} from '@generated/enums';
import {debounce} from 'lodash';
import Search from '@components/reusable/Search';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';

const {confirm} = Modal;

interface AcademiesTableContainerProps {
  search?: string;
  status?: AcademyStatus | undefined;
  permission?: string;
  showDevUtilSearch?: boolean;
}

export const AcademiesTableContainer = (
  props: AcademiesTableContainerProps
) => {
  const {search, status, permission, showDevUtilSearch} = props;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');

  const [debouncedQueryParams, setDebouncedQueryParams] = useState<{
    isRestricted?: boolean;
    searchText?: string;
    status?: AcademyStatus;
  }>({});

  const debouncedUpdateQueryParams = useMemo(
    () =>
      debounce((newParams) => {
        setDebouncedQueryParams(newParams);
      }, 300),
    []
  );

  useEffect(() => {
    const params: {
      isRestricted?: boolean;
      searchText?: string;
      status?: AcademyStatus;
    } = {};

    if (search) {
      params.searchText = search;
    }
    if (status === AcademyStatus.Draft) {
      params.status = AcademyStatus.Draft;
    }
    if (status === AcademyStatus.Published) {
      params.status = AcademyStatus.Published;
    }
    if (permission === 'public') {
      params.isRestricted = false;
    }
    if (permission === 'restricted') {
      params.isRestricted = true;
    }

    debouncedUpdateQueryParams(params);
  }, [search, status, permission, debouncedUpdateQueryParams]);

  const {
    data: academies,
    isFetching: isAcademiesFetching,
    invalidateExact: invalidateAcademies,
  } = useAcademiesQuery({
    queryParams: debouncedQueryParams,
  });

  const params = useParams();
  useEffect(() => {
    invalidateAcademies();
  }, [params]);

  const {invalidateExact: invalidateAcademiesCount} = useAcademiesCountsQuery(
    null,
    {
      enabled: false,
    }
  );
  // Delete Custom Program
  const deleteAcademyMutation = useDeleteAcademy({
    onSuccess: () => {
      invalidateAcademies();
      invalidateAcademiesCount();
    },
  });

  const duplicateAcademyMutation = useDuplicateAcademyMutation();

  const handleClickDeleteProgram = async (id: number) => {
    confirm({
      icon: false,
      content: (
        <>
          <p>{i18n.t(k.ACADEMY__TABLE__DELETE_CONFIRM)} </p>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.ACADEMY__DELETE),
      okType: 'danger',
      bodyStyle: {borderRadius: '25px'},
      title: i18n.t(k.ACADEMY__DELETE),
      okButtonProps: {type: 'primary', shape: 'round'},
      cancelButtonProps: {type: 'primary', shape: 'round', ghost: true},
      onOk() {
        deleteAcademyMutation.mutate({
          pathVars: {academyId: id},
        });
      },
    });
  };

  const handleDuplicateAcademy = (academyId: number) => {
    duplicateAcademyMutation.mutate(
      {pathVars: {academyId}},
      {
        onSuccess: (newAcademyId) => {
          navigate(editAcademyUrl(newAcademyId.toString()));
        },
      }
    );
  };

  const academiesDataSource =
    isProductionAny || !searchText
      ? academies
      : academies.filter((academy) => {
          return academy.name.toLowerCase().includes(searchText.toLowerCase());
        });

  return (
    <PageContentLayout fullWidth>
      {!isProductionAny && !isBetaAny && !!showDevUtilSearch && (
        <div style={{margin: '12px 0'}}>
          <Search
            buttonAriaLabel={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
              item: i18n.t(k.ACADEMY),
            })}
            id="academy-search-input"
            label={`${i18n.t(k.GENERIC__SEARCH)} (Developer Utility, Not Available in Production)`}
            onChange={setSearchText}
            value={searchText}
            placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
              item: i18n.t(k.ACADEMY),
            })}
          />
        </div>
      )}
      <AcademiesTable
        isLoading={isAcademiesFetching}
        dataSource={academiesDataSource || []}
        onClickDelete={handleClickDeleteProgram}
        onClickDuplicateAcademy={handleDuplicateAcademy}
      />
    </PageContentLayout>
  );
};
