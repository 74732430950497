import {i18n, k} from '@i18n/translate';
import {useQuery} from 'react-query';
import {
  IncentivesCountVM,
  ApprovalRequestDetailsVM,
  UserRequestDetailsVM,
} from '@models/serverModels';
import {
  getIncentivesCountRq,
  getManagerUserRequestApprovalsRq,
} from '@store/apiEndpoints';
import {UserGroup} from '@assets/svg/UserGroup';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {YourPlanPaths, UserPaths, ManagerPaths} from '@utils/ClientPaths';
import {useLocation} from 'react-router-dom';
import {simpleQueryFn} from '@store/queryClient';
import {ApprovalsDiscriminator, ApprovalStatus} from '@generated/enums';
import useGetExploreSubMenuItems from '@hooks/useGetExploreSubMenuItems';
import {cacheForeverOptions} from '@store/queryClient';
import {SubMenuKeys, NavMenuItem} from '@blocks/appNav/AppNavMenu';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import MessageOutlined from '@ant-design/icons/lib/icons/MessageOutlined';
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined';
import FlagOutlined from '@ant-design/icons/lib/icons/FlagOutlined';
import {useCustomProgramPermissionVMsQuery} from './apiEndpoints/permission/queries';
import {transformApprovalResponse} from '@utils/itemUtils';
import {ApprovalsDiscriminatorWithTime} from '@models/clientEnums';
import usePathInfo from './usePathInfo';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useManagedAcademiesQueryCached} from '@generated/hooks/permission.get.hooks';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/

function useGetUserNavMenuItems(): NavMenuItem[] {
  // Get session user data
  const {user} = useAuth();
  const {pathname} = useLocation();
  const {isAdminPath} = usePathInfo();
  const incentivesCountRq = getIncentivesCountRq();
  const incentivesCountQuery = useQuery<IncentivesCountVM>(
    incentivesCountRq.queryKey,
    () => simpleQueryFn(incentivesCountRq.path),
    cacheForeverOptions
  );
  const isApprover = user?.isManager || user?.isAcademyApprover;
  const {isFeatureFlagOn} = useFeatureFlags();

  const {data: academyPermissions} = useManagedAcademiesQueryCached(null, {
    enabled: isFeatureFlagOn.Academies && !isAdminPath,
  });

  const {data: customProgramPermissions} = useCustomProgramPermissionVMsQuery(
    null,
    {
      enabled: !isAdminPath,
    }
  );

  const showManageTab =
    customProgramPermissions?.length ||
    (academyPermissions?.length && isFeatureFlagOn.Academies);

  // Get User Nav Items
  const navMenuItems: NavMenuItem[] = [
    {
      label: isFeatureFlagOn.YourPlanNameChange
        ? i18n.t(k.GENERIC__HOME)
        : i18n.t(k.PLAN__YOUR),
      icon: <HomeOutlined aria-hidden="true" />,
      path: UserPaths.ManagePlan,
      isSelected: YourPlanPaths.some((path) => pathname.includes(path)),
    },
  ];

  const exploreSubMenu = useGetExploreSubMenuItems();
  if (!isFeatureFlagOn.HideMarketplaceFromLXP && exploreSubMenu.length) {
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.Explore]),
      icon: <SearchOutlined aria-hidden="true" />,
      path: '#',
      subItems: exploreSubMenu,
    });
  }

  if (showManageTab) {
    navMenuItems.push({
      label: i18n.t(k.CTA__MANAGE),
      icon: <FlagOutlined aria-hidden="true" />,
      path: UserPaths.Permissions,
    });
  }

  // Determine which manager nav menu items to show based on counts and flags
  const showManagerProgramsNavMenuItem =
    (isApprover &&
      !!incentivesCountQuery.data
        ?.programIncentivesAvailableToUsersWithManagerApprovalCount) ||
    !!incentivesCountQuery.data?.academiesWithCurrentUserAsAssignedApprover;

  const showManagerFinanceNavMenuItem =
    isApprover &&
    !!incentivesCountQuery.data
      ?.financeIncentivesAvailableToUsersWithManagerApprovalCount;
  const showManagerTimeNavMenuItem =
    isApprover &&
    isFeatureFlagOn.TimeIncentives &&
    !!incentivesCountQuery.data
      ?.timeIncentivesAvailableToUsersWithManagerApprovalCount;
  const showManagerResourcesNavMenuItem =
    isApprover &&
    !!incentivesCountQuery.data
      ?.resourcesAvailableToUsersWithManagerApprovalCount;

  // Get Manager Pending Approvals Data for Manager Menu Item Count Badges
  const managerApprovalsProgramRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.Program,
    ApprovalStatus.Requested
  );
  const getManagerApprovalsQueryProgram = useQuery<ApprovalRequestDetailsVM[]>(
    managerApprovalsProgramRq.queryKey,
    () => simpleQueryFn(managerApprovalsProgramRq.path),
    {
      enabled: showManagerProgramsNavMenuItem,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );
  const managerUserRequestApprovalsRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.License,
    ApprovalStatus.Requested
  );
  const getManagerUserRequestApprovalsQuery = useQuery<UserRequestDetailsVM[]>(
    managerUserRequestApprovalsRq.queryKey,
    () =>
      simpleQueryFn(
        managerUserRequestApprovalsRq.path,
        transformApprovalResponse
      ),
    {
      enabled: showManagerProgramsNavMenuItem,
      refetchOnWindowFocus: false,
    }
  );
  const managerApprovalsTimeRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminatorWithTime.Time as unknown as ApprovalsDiscriminator,
    ApprovalStatus.Requested
  );
  const getManagerApprovalsQueryTime = useQuery<ApprovalRequestDetailsVM[]>(
    managerApprovalsTimeRq.queryKey,
    () => simpleQueryFn(managerApprovalsTimeRq.path),
    {
      enabled: showManagerTimeNavMenuItem,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );
  const managerApprovalsFinanceRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.Incentive,
    ApprovalStatus.Requested
  );
  const getManagerApprovalsQueryFinance = useQuery<ApprovalRequestDetailsVM[]>(
    managerApprovalsFinanceRq.queryKey,
    () => simpleQueryFn(managerApprovalsFinanceRq.path),
    {
      enabled: showManagerFinanceNavMenuItem,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );

  const managerApprovalsResourceRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.LearningResource,
    ApprovalStatus.Requested
  );
  const getManagerApprovalsQueryResource = useQuery<ApprovalRequestDetailsVM[]>(
    managerApprovalsResourceRq.queryKey,
    () => simpleQueryFn(managerApprovalsResourceRq.path),
    {
      enabled: showManagerResourcesNavMenuItem,
      refetchOnWindowFocus: false,
      placeholderData: () => [],
    }
  );

  // Get Manager Nav Menu Items
  const managerNavMenuItems: any[] = [];
  if (showManagerProgramsNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.PROGRAM__PLURAL),
      path: ManagerPaths.ApprovalsProgram,
      count:
        getManagerApprovalsQueryProgram.data?.length +
          getManagerUserRequestApprovalsQuery.data?.length || 0,
    });
  }

  if (showManagerFinanceNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.GENERIC__FINANCING),
      path: ManagerPaths.ApprovalsFinance,
      count: getManagerApprovalsQueryFinance.data?.length || 0,
    });
  }

  if (showManagerTimeNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.TIME),
      path: ManagerPaths.ApprovalsTime,
      count: getManagerApprovalsQueryTime.data?.length || 0,
    });
  }

  if (showManagerResourcesNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.RESOURCE__PLURAL),
      path: ManagerPaths.ApprovalsResource,
      count: getManagerApprovalsQueryResource.data?.length || 0,
    });
  }

  isApprover &&
    !isFeatureFlagOn.DisableProgressUpdates &&
    managerNavMenuItems.unshift({
      label: i18n.t(k.PROGRESS),
      path: ManagerPaths.ApprovalsProgress,
    });

  if (!!managerNavMenuItems.length) {
    const totalApprovalRequestsCount = managerNavMenuItems.reduce(
      (acc, cur) => acc + (cur.count || 0),
      0
    );
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.Approvals]),
      icon: <AuditOutlined aria-hidden="true" />,
      path: '#',
      subItems: managerNavMenuItems,
      count: totalApprovalRequestsCount,
    });
  }

  if (user?.isManager && isFeatureFlagOn.TeamInsights) {
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.TeamInsights]),
      icon: <UserGroup aria-hidden="true" style={{marginRight: '10px'}} />,
      path: ManagerPaths.TeamInsights,
      isSelected: pathname.includes(ManagerPaths.TeamInsights),
    });
  }

  if (user?.isBeta) {
    navMenuItems.push({
      label: i18n.t(k.FEEDBACK),
      icon: <MessageOutlined aria-hidden="true" />,
      path: UserPaths.Feedback,
    });
  }

  return navMenuItems;
}

export default useGetUserNavMenuItems;
