// Do not modify directly
// Generated from file: learnin.core.application/Services/UserRequestApprovalService/Responses/UserRequestApproval.cs

import {z} from 'zod';
import {ApprovalTypeSchema} from './ApprovalTypeSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LearningResourceTypeSchema} from './LearningResourceTypeSchema.schema';
import {UserRequestTypeSchema} from './UserRequestTypeSchema.schema';

export const UserRequestApprovalSchema = z.object({
  amount: z.number(),
  approvalRequestId: z.number(),
  approvalType: ApprovalTypeSchema,
  balanceOverLimit: z.number().nullable().optional(),
  costCenter: z.string().nullable().optional(),
  id: z.number(),
  isAdditionalApproval: z.boolean(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseId: z.number().nullable().optional(),
  managerId: z.number().nullable().optional(),
  managerName: z.string(),
  programItemId: z.number().nullable().optional(),
  programTitle: z.string().nullable().optional(),
  provider: z.string().nullable().optional(),
  requestedById: z.number(),
  requestedByName: z.string(),
  requestedDate: z.string(),
  resourceType: LearningResourceTypeSchema.nullable().optional(),
  userRequestType: UserRequestTypeSchema,
});
