import ProjectResponseDrawer from '@components/admin/pages/programs/customPrograms/projects/ProjectResponseDrawer';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {NewFeedbackPayload} from '@models/serverModels';
import {getSubmitContentFeedbackRm} from '@store/apiEndpoints/academy/mutations';
import {notify} from '@components/user/notifications';
import useOpenUpload from '@hooks/useOpenUpload';
import {CustomizableLearningCategory} from '@generated/enums';
import usePathInfo from '@hooks/usePathInfo';
import {getAcademiesProjectsSubmissionsPath} from '@utils/ClientPaths';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useAcademiesProjectSubmissionsQueryCached} from '@generated/hooks/academy.get.hooks';

export interface ProjectResponseDrawerContainerProps {
  allowFeedback: boolean;
  title?: string;
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function ProjectResponseDrawerContainer({
  allowFeedback,
  title,
}: ProjectResponseDrawerContainerProps) {
  const {projectId, submissionId, academyId} = useParams();

  const {user} = useAuth();

  const navigate = useNavigate();

  const {state} = useLocation();

  const triggerElement = document.querySelector(state?.triggerSelector);

  useSetPageTitle(title);

  const openUpload = useOpenUpload(CustomizableLearningCategory.Academy);

  const {
    data: academiesProjectSubmissions,
    refetch: refetchAcademiesProjectSubmissions,
  } = useAcademiesProjectSubmissionsQueryCached({
    projectId: parseInt(projectId),
  });

  const submission = academiesProjectSubmissions?.find(
    ({id}) => id === parseInt(submissionId)
  );

  const submitContentFeedbackMutation = useMutation(
    (feedback: string) => {
      if (!feedback) return;
      const getSubmitContentFeedback = getSubmitContentFeedbackRm({
        feedback,
        projectSubmissionId: parseInt(submissionId),
      });
      return simpleMutationFn<NewFeedbackPayload[]>(
        getSubmitContentFeedback.path,
        getSubmitContentFeedback.payload
      );
    },
    {
      onSuccess: () => {
        handleClose();
        refetchAcademiesProjectSubmissions();
        notify.submitCustomProgramProjectFeedbackSuccess();
      },
      onError: () => {
        notify.submitCustomProgramProjectFeedbackError();
      },
    }
  );

  const {isAdminPath} = usePathInfo();

  const handleClose = () => {
    navigate(
      getAcademiesProjectsSubmissionsPath(
        academyId,
        projectId,
        isAdminPath ? 'admin' : 'user'
      )
    );

    triggerElement?.focus();
  };

  return (
    <ProjectResponseDrawer
      companyId={user?.companyId}
      allowFeedback={allowFeedback}
      submission={submission}
      visible={!!submission}
      onClose={handleClose}
      onClickUploadLink={openUpload}
      onClickSubmitFeedback={async (feedbackText) => {
        await submitContentFeedbackMutation.mutateAsync(feedbackText);
      }}
    />
  );
}

export default ProjectResponseDrawerContainer;
