// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CreditCardTransactionCause.cs

enum CreditCardTransactionCause {
  CardUse,
  ManualBalanceUpdate,
  ManualBalanceUpdateAdvance,
  AutomatedBalanceUpdate,
  AutomatedBalanceExpiration,
}

export default CreditCardTransactionCause;
