import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  PlanItemType,
  RequestApprovalType,
} from './../enums';
import {
  ApprovalRequestVM,
  ResourceApprovalRequestVM,
  UserRequestApprovalDetailsVM,
  UserRequestApprovalsResponse,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ApprovalRequestVMSchema} from '../zodSchemas/ApprovalRequestVMSchema.schema';
import {ResourceApprovalRequestVMSchema} from '../zodSchemas/ResourceApprovalRequestVMSchema.schema';
import {UserRequestApprovalDetailsVMSchema} from '../zodSchemas/UserRequestApprovalDetailsVMSchema.schema';
import {UserRequestApprovalsResponseSchema} from '../zodSchemas/UserRequestApprovalsResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Approvals/AdminApprovalsController.cs

/*
|--------------------------------------------------------------------------
|  get-approval-details 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsApprovalDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsApprovalDetails,
 *    isLoading: isAdminApprovalsApprovalDetailsLoading,
 *    invalidateExact: invalidateAdminApprovalsApprovalDetails,
 *  } = useAdminApprovalsApprovalDetailsQuery({
 *    queryParams: {
 *        id,
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsApprovalDetailsQuery = queryHookFactory<
  {queryParams?: {id?: number; type?: ApprovalType}},
  UserRequestApprovalDetailsVM // return type
>(
  'useAdminApprovalsApprovalDetailsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-approval-details`,
      null,
      queryParams
    ),
  {responseSchema: UserRequestApprovalDetailsVMSchema}
);

// CACHED
export const useAdminApprovalsApprovalDetailsQueryCached = queryHookFactory<
  {queryParams?: {id?: number; type?: ApprovalType}},
  UserRequestApprovalDetailsVM // return type
>(
  'useAdminApprovalsApprovalDetailsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-approval-details`,
      null,
      queryParams
    ),
  {responseSchema: UserRequestApprovalDetailsVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsApprovals,
 *    isLoading: isAdminApprovalsApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsApprovals,
 *  } = useAdminApprovalsApprovalsQuery({
 *    queryParams: {
 *        status,
 *        type,
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsApprovalsQuery = queryHookFactory<
  {
    queryParams?: {
      status?: ApprovalStatus;
      type?: PlanItemType;
      useNewApprovalSystem?: boolean;
    };
  },
  ApprovalRequestVM[] // return type
>(
  'useAdminApprovalsApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(() => `admin-approvals/get-approvals`, null, queryParams),
  {responseSchema: z.array(ApprovalRequestVMSchema)}
);

// CACHED
export const useAdminApprovalsApprovalsQueryCached = queryHookFactory<
  {
    queryParams?: {
      status?: ApprovalStatus;
      type?: PlanItemType;
      useNewApprovalSystem?: boolean;
    };
  },
  ApprovalRequestVM[] // return type
>(
  'useAdminApprovalsApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(() => `admin-approvals/get-approvals`, null, queryParams),
  {responseSchema: z.array(ApprovalRequestVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-resource-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsResourceApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsResourceApprovals,
 *    isLoading: isAdminApprovalsResourceApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsResourceApprovals,
 *  } = useAdminApprovalsResourceApprovalsQuery({
 *    queryParams: {
 *        status,
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsResourceApprovalsQuery = queryHookFactory<
  {queryParams?: {status?: ApprovalStatus; useNewApprovalSystem?: boolean}},
  ResourceApprovalRequestVM[] // return type
>(
  'useAdminApprovalsResourceApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-resource-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ResourceApprovalRequestVMSchema)}
);

// CACHED
export const useAdminApprovalsResourceApprovalsQueryCached = queryHookFactory<
  {queryParams?: {status?: ApprovalStatus; useNewApprovalSystem?: boolean}},
  ResourceApprovalRequestVM[] // return type
>(
  'useAdminApprovalsResourceApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-resource-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ResourceApprovalRequestVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUserRequestApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsUserRequestApprovals,
 *    isLoading: isAdminApprovalsUserRequestApprovalsLoading,
 *    invalidateExact: invalidateAdminApprovalsUserRequestApprovals,
 *  } = useAdminApprovalsUserRequestApprovalsQuery({
 *    queryParams: {
 *        isAscending,
 *        isPendingManagerRequest,
 *        pageNumber,
 *        pageSize,
 *        requestType,
 *        searchTerm,
 *        sortColumn,
 *        status
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsUserRequestApprovalsQuery = queryHookFactory<
  {
    queryParams?: {
      isAscending?: boolean;
      isPendingManagerRequest?: boolean;
      pageNumber?: number;
      pageSize?: number;
      requestType?: RequestApprovalType;
      searchTerm?: string;
      sortColumn?: string;
      status?: ApprovalStatus;
    };
  },
  UserRequestApprovalsResponse // return type
>(
  'useAdminApprovalsUserRequestApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/user-request-approvals`,
      null,
      queryParams
    ),
  {responseSchema: UserRequestApprovalsResponseSchema}
);

// CACHED
export const useAdminApprovalsUserRequestApprovalsQueryCached =
  queryHookFactory<
    {
      queryParams?: {
        isAscending?: boolean;
        isPendingManagerRequest?: boolean;
        pageNumber?: number;
        pageSize?: number;
        requestType?: RequestApprovalType;
        searchTerm?: string;
        sortColumn?: string;
        status?: ApprovalStatus;
      };
    },
    UserRequestApprovalsResponse // return type
  >(
    'useAdminApprovalsUserRequestApprovalsQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `admin-approvals/user-request-approvals`,
        null,
        queryParams
      ),
    {responseSchema: UserRequestApprovalsResponseSchema},
    {staleTime: 600000}
  );

/*
|--------------------------------------------------------------------------
|  get-user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUserRequestApprovalsLegacyQuery} from '@generated/hooks';
 *
 *  const {
 *    data: adminApprovalsUserRequestApprovalsLegacy,
 *    isLoading: isAdminApprovalsUserRequestApprovalsLegacyLoading,
 *    invalidateExact: invalidateAdminApprovalsUserRequestApprovalsLegacy,
 *  } = useAdminApprovalsUserRequestApprovalsLegacyQuery({
 *    queryParams: {
 *        status,
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsUserRequestApprovalsLegacyQuery =
  queryHookFactory<
    {queryParams?: {status?: ApprovalStatus; type?: ApprovalsDiscriminator}},
    UserRequestApprovalDetailsVM[] // return type
  >(
    'useAdminApprovalsUserRequestApprovalsLegacyQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `admin-approvals/get-user-request-approvals`,
        null,
        queryParams
      ),
    {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)}
  );

// CACHED
export const useAdminApprovalsUserRequestApprovalsLegacyQueryCached =
  queryHookFactory<
    {queryParams?: {status?: ApprovalStatus; type?: ApprovalsDiscriminator}},
    UserRequestApprovalDetailsVM[] // return type
  >(
    'useAdminApprovalsUserRequestApprovalsLegacyQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `admin-approvals/get-user-request-approvals`,
        null,
        queryParams
      ),
    {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)},
    {staleTime: 600000}
  );

/*
|--------------------------------------------------------------------------
|  get-pending-manager-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePendingManagerApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: pendingManagerApprovals,
 *    isLoading: isPendingManagerApprovalsLoading,
 *    invalidateExact: invalidatePendingManagerApprovals,
 *  } = usePendingManagerApprovalsQuery({
 *    queryParams: {
 *        useNewApprovalSystem
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const usePendingManagerApprovalsQuery = queryHookFactory<
  {queryParams?: {useNewApprovalSystem?: boolean}},
  ApprovalRequestVM[] // return type
>(
  'usePendingManagerApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-pending-manager-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ApprovalRequestVMSchema)}
);

// CACHED
export const usePendingManagerApprovalsQueryCached = queryHookFactory<
  {queryParams?: {useNewApprovalSystem?: boolean}},
  ApprovalRequestVM[] // return type
>(
  'usePendingManagerApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-pending-manager-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(ApprovalRequestVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-pending-manager-user-request-approvals 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePendingManagerUserRequestApprovalsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: pendingManagerUserRequestApprovals,
 *    isLoading: isPendingManagerUserRequestApprovalsLoading,
 *    invalidateExact: invalidatePendingManagerUserRequestApprovals,
 *  } = usePendingManagerUserRequestApprovalsQuery({
 *    queryParams: {
 *        type
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const usePendingManagerUserRequestApprovalsQuery = queryHookFactory<
  {queryParams?: {type?: ApprovalsDiscriminator}},
  UserRequestApprovalDetailsVM[] // return type
>(
  'usePendingManagerUserRequestApprovalsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `admin-approvals/get-pending-manager-user-request-approvals`,
      null,
      queryParams
    ),
  {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)}
);

// CACHED
export const usePendingManagerUserRequestApprovalsQueryCached =
  queryHookFactory<
    {queryParams?: {type?: ApprovalsDiscriminator}},
    UserRequestApprovalDetailsVM[] // return type
  >(
    'usePendingManagerUserRequestApprovalsQuery',
    ({queryParams}) =>
      addQueryParams(
        () => `admin-approvals/get-pending-manager-user-request-approvals`,
        null,
        queryParams
      ),
    {responseSchema: z.array(UserRequestApprovalDetailsVMSchema)},
    {staleTime: 600000}
  );
