import {LanguagePreference, ProgramSearchCategory} from './../enums';
import {
  GetStartedItemVM,
  InitiativePurposeDescriptionVM,
  ProgramAdminVM,
  ProgramDetailsVM,
  ProgramFilterVM,
  ProgramInitiativeIncentiveVM,
  ProgramParticipantVM,
  ProgramReviewVM,
  ProgramSearchResult,
  ProgramSearchVM,
  ProgramSkillVM,
  ProgramUrlVM,
  ProgramUserRecommendationVM,
} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {ProgramDetailsVMSchema} from '../zodSchemas/ProgramDetailsVMSchema.schema';
import {ProgramUrlVMSchema} from '../zodSchemas/ProgramUrlVMSchema.schema';
import {ProgramInitiativeIncentiveVMSchema} from '../zodSchemas/ProgramInitiativeIncentiveVMSchema.schema';
import {GetStartedItemVMSchema} from '../zodSchemas/GetStartedItemVMSchema.schema';
import {ProgramAdminVMSchema} from '../zodSchemas/ProgramAdminVMSchema.schema';
import {ProgramSearchVMSchema} from '../zodSchemas/ProgramSearchVMSchema.schema';
import {ProgramSearchResultSchema} from '../zodSchemas/ProgramSearchResultSchema.schema';
import {ProgramFilterVMSchema} from '../zodSchemas/ProgramFilterVMSchema.schema';
import {ProgramSkillVMSchema} from '../zodSchemas/ProgramSkillVMSchema.schema';
import {ProgramParticipantVMSchema} from '../zodSchemas/ProgramParticipantVMSchema.schema';
import {ProgramUserRecommendationVMSchema} from '../zodSchemas/ProgramUserRecommendationVMSchema.schema';
import {ProgramReviewVMSchema} from '../zodSchemas/ProgramReviewVMSchema.schema';
import {InitiativePurposeDescriptionVMSchema} from '../zodSchemas/InitiativePurposeDescriptionVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/ProgramController.cs

/*
|--------------------------------------------------------------------------
|  get-available-providers 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAvailableProvidersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: availableProviders,
 *    isLoading: isAvailableProvidersLoading,
 *    invalidateExact: invalidateAvailableProviders,
 *  } = useAvailableProvidersQuery(null)
 *
 */

export const useAvailableProvidersQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useAvailableProvidersQuery', () => `program/get-available-providers`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useAvailableProvidersQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useAvailableProvidersQuery',
  () => `program/get-available-providers`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-durations 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDurationsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: durations,
 *    isLoading: isDurationsLoading,
 *    invalidateExact: invalidateDurations,
 *  } = useDurationsQuery(null)
 *
 */

export const useDurationsQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useDurationsQuery', () => `program/get-durations`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useDurationsQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useDurationsQuery',
  () => `program/get-durations`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-initiative-purpose-descriptions 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useInitiativePurposeDescriptionsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: initiativePurposeDescriptions,
 *    isLoading: isInitiativePurposeDescriptionsLoading,
 *    invalidateExact: invalidateInitiativePurposeDescriptions,
 *  } = useInitiativePurposeDescriptionsQuery({
 *    queryParams: {
 *        programIds
 *    }
 *  })
 *
 */

export const useInitiativePurposeDescriptionsQuery = queryHookFactory<
  {queryParams?: {programIds?: number[]}},
  InitiativePurposeDescriptionVM[] // return type
>(
  'useInitiativePurposeDescriptionsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `program/get-initiative-purpose-descriptions`,
      null,
      queryParams
    ),
  {responseSchema: z.array(InitiativePurposeDescriptionVMSchema)}
);

// CACHED
export const useInitiativePurposeDescriptionsQueryCached = queryHookFactory<
  {queryParams?: {programIds?: number[]}},
  InitiativePurposeDescriptionVM[] // return type
>(
  'useInitiativePurposeDescriptionsQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `program/get-initiative-purpose-descriptions`,
      null,
      queryParams
    ),
  {responseSchema: z.array(InitiativePurposeDescriptionVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-max-possible-price-for-filter 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useMaxPossiblePriceForFilterQuery} from '@generated/hooks';
 *
 *  const {
 *    data: maxPossiblePriceForFilter,
 *    isLoading: isMaxPossiblePriceForFilterLoading,
 *    invalidateExact: invalidateMaxPossiblePriceForFilter,
 *  } = useMaxPossiblePriceForFilterQuery(null)
 *
 */

export const useMaxPossiblePriceForFilterQuery = queryHookFactory<
  null,
  number // return type
>(
  'useMaxPossiblePriceForFilterQuery',
  () => `program/get-max-possible-price-for-filter`,
  {responseSchema: z.number()}
);

// CACHED
export const useMaxPossiblePriceForFilterQueryCached = queryHookFactory<
  null,
  number // return type
>(
  'useMaxPossiblePriceForFilterQuery',
  () => `program/get-max-possible-price-for-filter`,
  {responseSchema: z.number()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-participants/{programId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useParticipantsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: participants,
 *    isLoading: isParticipantsLoading,
 *    invalidateExact: invalidateParticipants,
 *  } = useParticipantsQuery({
 *    programId
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useParticipantsQuery = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramParticipantVM[] // return type
>(
  'useParticipantsQuery',
  ({programId}) => `program/get-participants/${programId}`,
  {responseSchema: z.array(ProgramParticipantVMSchema)}
);

// CACHED
export const useParticipantsQueryCached = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramParticipantVM[] // return type
>(
  'useParticipantsQuery',
  ({programId}) => `program/get-participants/${programId}`,
  {responseSchema: z.array(ProgramParticipantVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-program-initiatives/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramInitiativesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programInitiatives,
 *    isLoading: isProgramInitiativesLoading,
 *    invalidateExact: invalidateProgramInitiatives,
 *  } = useProgramInitiativesQuery({
 *    id
 *  })
 *
 */

export const useProgramInitiativesQuery = queryHookFactory<
  {id: number | string}, // path variables
  ProgramInitiativeIncentiveVM[] // return type
>(
  'useProgramInitiativesQuery',
  ({id}) => `program/get-program-initiatives/${id}`,
  {responseSchema: z.array(ProgramInitiativeIncentiveVMSchema)}
);

// CACHED
export const useProgramInitiativesQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  ProgramInitiativeIncentiveVM[] // return type
>(
  'useProgramInitiativesQuery',
  ({id}) => `program/get-program-initiatives/${id}`,
  {responseSchema: z.array(ProgramInitiativeIncentiveVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-programs 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programs,
 *    isLoading: isProgramsLoading,
 *    invalidateExact: invalidatePrograms,
 *  } = useProgramsQuery(null)
 *
 */

export const useProgramsQuery = queryHookFactory<
  null,
  ProgramAdminVM[] // return type
>('useProgramsQuery', () => `program/get-programs`, {
  responseSchema: z.array(ProgramAdminVMSchema),
});

// CACHED
export const useProgramsQueryCached = queryHookFactory<
  null,
  ProgramAdminVM[] // return type
>(
  'useProgramsQuery',
  () => `program/get-programs`,
  {responseSchema: z.array(ProgramAdminVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-program-titles 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramTitlesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programTitles,
 *    isLoading: isProgramTitlesLoading,
 *    invalidateExact: invalidateProgramTitles,
 *  } = useProgramTitlesQuery({
 *    queryParams: {
 *        category,
 *        languages
 *    }
 *  })
 *
 */

export const useProgramTitlesQuery = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      languages?: LanguagePreference[];
    };
  },
  ProgramSearchVM[] // return type
>(
  'useProgramTitlesQuery',
  ({queryParams}) =>
    addQueryParams(() => `program/get-program-titles`, null, queryParams),
  {responseSchema: z.array(ProgramSearchVMSchema)}
);

// CACHED
export const useProgramTitlesQueryCached = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      languages?: LanguagePreference[];
    };
  },
  ProgramSearchVM[] // return type
>(
  'useProgramTitlesQuery',
  ({queryParams}) =>
    addQueryParams(() => `program/get-program-titles`, null, queryParams),
  {responseSchema: z.array(ProgramSearchVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-program-types 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramTypesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programTypes,
 *    isLoading: isProgramTypesLoading,
 *    invalidateExact: invalidateProgramTypes,
 *  } = useProgramTypesQuery(null)
 *
 */

export const useProgramTypesQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useProgramTypesQuery', () => `program/get-program-types`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useProgramTypesQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useProgramTypesQuery',
  () => `program/get-program-types`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-providers 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProvidersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: providers,
 *    isLoading: isProvidersLoading,
 *    invalidateExact: invalidateProviders,
 *  } = useProvidersQuery(null)
 *
 */

export const useProvidersQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useProvidersQuery', () => `program/get-providers`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useProvidersQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useProvidersQuery',
  () => `program/get-providers`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-recommendations/{programId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRecommendationsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: recommendations,
 *    isLoading: isRecommendationsLoading,
 *    invalidateExact: invalidateRecommendations,
 *  } = useRecommendationsQuery({
 *    programId
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useRecommendationsQuery = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramUserRecommendationVM[] // return type
>(
  'useRecommendationsQuery',
  ({programId}) => `program/get-recommendations/${programId}`,
  {responseSchema: z.array(ProgramUserRecommendationVMSchema)}
);

// CACHED
export const useRecommendationsQueryCached = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramUserRecommendationVM[] // return type
>(
  'useRecommendationsQuery',
  ({programId}) => `program/get-recommendations/${programId}`,
  {responseSchema: z.array(ProgramUserRecommendationVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-reviews/{programId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReviewsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: reviews,
 *    isLoading: isReviewsLoading,
 *    invalidateExact: invalidateReviews,
 *  } = useReviewsQuery({
 *    programId
 *  })
 *
 */

export const useReviewsQuery = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramReviewVM[] // return type
>('useReviewsQuery', ({programId}) => `program/get-reviews/${programId}`, {
  responseSchema: z.array(ProgramReviewVMSchema),
});

// CACHED
export const useReviewsQueryCached = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramReviewVM[] // return type
>(
  'useReviewsQuery',
  ({programId}) => `program/get-reviews/${programId}`,
  {responseSchema: z.array(ProgramReviewVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-skills/{programId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSkillsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: skills,
 *    isLoading: isSkillsLoading,
 *    invalidateExact: invalidateSkills,
 *  } = useSkillsQuery({
 *    programId
 *  })
 *
 */

export const useSkillsQuery = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramSkillVM[] // return type
>('useSkillsQuery', ({programId}) => `program/get-skills/${programId}`, {
  responseSchema: z.array(ProgramSkillVMSchema),
});

// CACHED
export const useSkillsQueryCached = queryHookFactory<
  {programId: number | string}, // path variables
  ProgramSkillVM[] // return type
>(
  'useSkillsQuery',
  ({programId}) => `program/get-skills/${programId}`,
  {responseSchema: z.array(ProgramSkillVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-topics 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTopicsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: topics,
 *    isLoading: isTopicsLoading,
 *    invalidateExact: invalidateTopics,
 *  } = useTopicsQuery(null)
 *
 */

export const useTopicsQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useTopicsQuery', () => `program/get-topics`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useTopicsQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useTopicsQuery',
  () => `program/get-topics`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-types 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTypesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: types,
 *    isLoading: isTypesLoading,
 *    invalidateExact: invalidateTypes,
 *  } = useTypesQuery(null)
 *
 */

export const useTypesQuery = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>('useTypesQuery', () => `program/get-types`, {
  responseSchema: z.array(ProgramFilterVMSchema),
});

// CACHED
export const useTypesQueryCached = queryHookFactory<
  null,
  ProgramFilterVM[] // return type
>(
  'useTypesQuery',
  () => `program/get-types`,
  {responseSchema: z.array(ProgramFilterVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-url/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: url,
 *    isLoading: isUrlLoading,
 *    invalidateExact: invalidateUrl,
 *  } = useUrlQuery({
 *    id
 *  })
 *
 */

export const useUrlQuery = queryHookFactory<
  {id: number | string}, // path variables
  ProgramUrlVM // return type
>('useUrlQuery', ({id}) => `program/get-url/${id}`, {
  responseSchema: ProgramUrlVMSchema,
});

// CACHED
export const useUrlQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  ProgramUrlVM // return type
>(
  'useUrlQuery',
  ({id}) => `program/get-url/${id}`,
  {responseSchema: ProgramUrlVMSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  program-finder-search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramFinderSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programFinderSearch,
 *    isLoading: isProgramFinderSearchLoading,
 *    invalidateExact: invalidateProgramFinderSearch,
 *  } = useProgramFinderSearchQuery({
 *    queryParams: {
 *        category,
 *        durationIds,
 *        keywords,
 *        languages,
 *        maxPrice,
 *        minPrice,
 *        programTypeIds,
 *        providerIds,
 *        skillIds,
 *        startDate
 *    }
 *  })
 *
 */

export const useProgramFinderSearchQuery = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      durationIds?: number[];
      keywords?: string;
      languages?: LanguagePreference[];
      maxPrice?: number;
      minPrice?: number;
      programTypeIds?: number[];
      providerIds?: number[];
      skillIds?: number[];
      startDate?: string;
    };
  },
  ProgramSearchVM[] // return type
>(
  'useProgramFinderSearchQuery',
  ({queryParams}) =>
    addQueryParams(() => `program/program-finder-search`, null, queryParams),
  {responseSchema: z.array(ProgramSearchVMSchema)}
);

// CACHED
export const useProgramFinderSearchQueryCached = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      durationIds?: number[];
      keywords?: string;
      languages?: LanguagePreference[];
      maxPrice?: number;
      minPrice?: number;
      programTypeIds?: number[];
      providerIds?: number[];
      skillIds?: number[];
      startDate?: string;
    };
  },
  ProgramSearchVM[] // return type
>(
  'useProgramFinderSearchQuery',
  ({queryParams}) =>
    addQueryParams(() => `program/program-finder-search`, null, queryParams),
  {responseSchema: z.array(ProgramSearchVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-details/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramDetailsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programDetails,
 *    isLoading: isProgramDetailsLoading,
 *    invalidateExact: invalidateProgramDetails,
 *  } = useProgramDetailsQuery({
 *    id
 *  })
 *
 */

export const useProgramDetailsQuery = queryHookFactory<
  {id: number | string}, // path variables
  ProgramDetailsVM | null // return type
>('useProgramDetailsQuery', ({id}) => `program/get-details/${id}`, {
  responseSchema: ProgramDetailsVMSchema.nullable(),
});

// CACHED
export const useProgramDetailsQueryCached = queryHookFactory<
  {id: number | string}, // path variables
  ProgramDetailsVM | null // return type
>(
  'useProgramDetailsQuery',
  ({id}) => `program/get-details/${id}`,
  {responseSchema: ProgramDetailsVMSchema.nullable()},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-started 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramStartedQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programStarted,
 *    isLoading: isProgramStartedLoading,
 *    invalidateExact: invalidateProgramStarted,
 *  } = useProgramStartedQuery(null)
 *
 */

export const useProgramStartedQuery = queryHookFactory<
  null,
  GetStartedItemVM[] // return type
>('useProgramStartedQuery', () => `program/get-started`, {
  responseSchema: z.array(GetStartedItemVMSchema),
});

// CACHED
export const useProgramStartedQueryCached = queryHookFactory<
  null,
  GetStartedItemVM[] // return type
>(
  'useProgramStartedQuery',
  () => `program/get-started`,
  {responseSchema: z.array(GetStartedItemVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramSearchQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programSearch,
 *    isLoading: isProgramSearchLoading,
 *    invalidateExact: invalidateProgramSearch,
 *  } = useProgramSearchQuery({
 *    queryParams: {
 *        category,
 *        durationIds,
 *        keywords,
 *        languages,
 *        maxPrice,
 *        minPrice,
 *        programTypeIds,
 *        providerIds,
 *        skillIds,
 *        startDate
 *    }
 *  })
 *
 */

export const useProgramSearchQuery = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      durationIds?: number[];
      keywords?: string;
      languages?: LanguagePreference[];
      maxPrice?: number;
      minPrice?: number;
      programTypeIds?: number[];
      providerIds?: number[];
      skillIds?: number[];
      startDate?: string;
    };
  },
  ProgramSearchResult[] // return type
>(
  'useProgramSearchQuery',
  ({queryParams}) => addQueryParams(() => `program/search`, null, queryParams),
  {responseSchema: z.array(ProgramSearchResultSchema)}
);

// CACHED
export const useProgramSearchQueryCached = queryHookFactory<
  {
    queryParams?: {
      category?: ProgramSearchCategory;
      durationIds?: number[];
      keywords?: string;
      languages?: LanguagePreference[];
      maxPrice?: number;
      minPrice?: number;
      programTypeIds?: number[];
      providerIds?: number[];
      skillIds?: number[];
      startDate?: string;
    };
  },
  ProgramSearchResult[] // return type
>(
  'useProgramSearchQuery',
  ({queryParams}) => addQueryParams(() => `program/search`, null, queryParams),
  {responseSchema: z.array(ProgramSearchResultSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  search-initiative-programs 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSearchInitiativeProgramsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: searchInitiativePrograms,
 *    isLoading: isSearchInitiativeProgramsLoading,
 *    invalidateExact: invalidateSearchInitiativePrograms,
 *  } = useSearchInitiativeProgramsQuery(null)
 *
 */

export const useSearchInitiativeProgramsQuery = queryHookFactory<
  null,
  ProgramSearchResult[] // return type
>(
  'useSearchInitiativeProgramsQuery',
  () => `program/search-initiative-programs`,
  {responseSchema: z.array(ProgramSearchResultSchema)}
);

// CACHED
export const useSearchInitiativeProgramsQueryCached = queryHookFactory<
  null,
  ProgramSearchResult[] // return type
>(
  'useSearchInitiativeProgramsQuery',
  () => `program/search-initiative-programs`,
  {responseSchema: z.array(ProgramSearchResultSchema)},
  {staleTime: 600000}
);
