import {AcademyIntegrationChatChannelResponse} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {AcademyIntegrationChatChannelResponseSchema} from '../zodSchemas/AcademyIntegrationChatChannelResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyIntegrationController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/integrations/chat/channels 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesIntegrationsChatChannelsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesIntegrationsChatChannels,
 *    isLoading: isAcademiesIntegrationsChatChannelsLoading,
 *    invalidateExact: invalidateAcademiesIntegrationsChatChannels,
 *  } = useAcademiesIntegrationsChatChannelsQuery({
 *    academyId
 *  })
 *
 */

export const useAcademiesIntegrationsChatChannelsQuery = queryHookFactory<
  {academyId: number}, // path variables
  AcademyIntegrationChatChannelResponse // return type
>(
  'useAcademiesIntegrationsChatChannelsQuery',
  ({academyId}) => `academies/${academyId}/integrations/chat/channels`,
  {responseSchema: AcademyIntegrationChatChannelResponseSchema}
);

// CACHED
export const useAcademiesIntegrationsChatChannelsQueryCached = queryHookFactory<
  {academyId: number}, // path variables
  AcademyIntegrationChatChannelResponse // return type
>(
  'useAcademiesIntegrationsChatChannelsQuery',
  ({academyId}) => `academies/${academyId}/integrations/chat/channels`,
  {responseSchema: AcademyIntegrationChatChannelResponseSchema},
  {staleTime: 600000}
);
