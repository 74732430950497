import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import AcademyWelcomeDrawer from './AcademyWelcomeDrawer';
import {AcademyUserStatus} from '@generated/enums';
import {
  NewWindowFragment,
  userAcademiesRoute,
  userAcademyRoute,
} from '@utils/ClientPaths';
import {useAddAcademyUserMutation} from '@generated/hooks';
import {useAcademy} from '@hooks/apiEndpoints/academy/queries';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {
  useAcademiesUserCompanyByIdQuery,
  useAcademiesUsersPutMutation,
} from '@generated/hooks';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {useAuth} from '@utils/oidc-auth-wrapper';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {usePublishedAcademiesQueryCached} from '@generated/hooks/academy.get.hooks';

function AcademyDrawerContainer({
  title,
  transitionOnRender,
  prevPath = '',
}: {
  title?: string;
  transitionOnRender?: boolean;
  prevPath?: string;
}) {
  const {academyId} = useParams();
  const navigate = useNavigate();
  const {user} = useAuth();
  const {showSkillsTagging} = useShowSkillsTagging();
  const linkToLxpAcademies = useBackToLxpLink(user, {
    type: 'academy',
    resourceId: academyId,
    isInternalLink: prevPath === userAcademiesRoute(),
  });
  const openedFromNewWindow = location.href
    .toLowerCase()
    .includes(NewWindowFragment.toLowerCase());

  // Fetch selected academy
  const {data: academy} = useAcademy(parseInt(academyId));

  const navigateToAcademyContent = () => navigate(userAcademyRoute(academyId));

  // Check if user is already in academy
  const {
    data: enrolledAcademyUser,
    isLoading: isFetchingEnrolledAcademyUser,
    invalidateExact: invalidateEnrolledAcademyUserQuery,
  } = useAcademiesUserCompanyByIdQuery({
    academyId: parseInt(academyId),
    userCompanyId: user?.userCompanyId,
  });

  const {invalidateExact: invalidateAcademiesList} =
    usePublishedAcademiesQueryCached(
      {queryParams: {includeSkills: showSkillsTagging}},
      {enabled: false}
    );

  useEffect(() => {
    if (
      enrolledAcademyUser?.status === AcademyUserStatus.InProgress ||
      enrolledAcademyUser?.status === AcademyUserStatus.Completed
    ) {
      navigate(userAcademyRoute(academyId));
    }
  }, [enrolledAcademyUser, academyId, navigate]);

  const addUserToAcademy = useAddAcademyUserMutation({
    onSuccess: () => {
      invalidateEnrolledAcademyUserQuery();
      invalidateAcademiesList();
      navigateToAcademyContent();
    },
  });

  const academiesUsersPut = useAcademiesUsersPutMutation({
    onSuccess: () => {
      invalidateAcademiesList();
      return navigateToAcademyContent();
    },
  });
  const handleClickJoinAcademy = async () => {
    if (enrolledAcademyUser?.status === AcademyUserStatus.InProgress) {
      /**
       * User is enrolled, simply redirect to academy content
       */
      return navigateToAcademyContent();
    }

    if (
      enrolledAcademyUser?.status === AcademyUserStatus.NotStarted ||
      enrolledAcademyUser?.status === AcademyUserStatus.Dropped
    ) {
      return academiesUsersPut.mutate({
        payload: {
          status: AcademyUserStatus.InProgress,
        },
        pathVars: {
          academyId: parseInt(academyId),
          userCompanyId: user?.userCompanyId,
        },
      });
    }

    await addUserToAcademy.mutateAsync({
      payload: {
        userCompanyId: user?.userCompanyId,
        academyId: parseInt(academyId),
        status: AcademyUserStatus.InProgress,
      },
    });
    // Once the user's been added to the academy,
    // set its status to "in progress"
    academiesUsersPut.mutate({
      payload: {
        status: AcademyUserStatus.InProgress,
      },
      pathVars: {
        academyId: parseInt(academyId),
        userCompanyId: user?.userCompanyId,
      },
    });
  };

  /** if opened from a new window, don't show a back button */
  const handleOnClose = ((): (() => void) | null =>
    openedFromNewWindow
      ? null
      : () => {
          linkToLxpAcademies
            ? (window.location.href = linkToLxpAcademies)
            : navigate(userAcademiesRoute());
        })();

  useSetPageTitle(title);

  // Render loading screen until user enrollment information is fetched
  if (isFetchingEnrolledAcademyUser) {
    return <LoadingSpinner center />;
  }

  return (
    <AcademyWelcomeDrawer
      transitionOnRender={transitionOnRender}
      clickJoinAcademy={handleClickJoinAcademy}
      userFirstName={user?.firstName}
      academy={academy}
      onClose={handleOnClose}
      renderPageInsteadOfDrawer={false}
    />
  );
}

export default AcademyDrawerContainer;
