// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/User/UserDetailsVM.cs

import {z} from 'zod';
import {CalendarProviderSchema} from './CalendarProviderSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {LanguagePreferenceSchema} from './LanguagePreferenceSchema.schema';
import {FinanceTypeSchema} from './FinanceTypeSchema.schema';

export const UserDetailsVMSchema = z.object({
  canViewAcademiesFeature: z.boolean(),
  companyId: z.number(),
  connectedCalendar: CalendarProviderSchema.nullable().optional(),
  currency: CurrencyCodeSchema,
  email: z.string(),
  firstLogin: z.string().nullable().optional(),
  firstName: z.string(),
  hasAcceptedStripeTerms: z.boolean().nullable().optional(),
  isAcademiesIntegratedExperienceDisabled: z.boolean(),
  isAcademyApprover: z.boolean(),
  isAdmin: z.boolean(),
  isBeta: z.boolean(),
  isContentMarketplaceIntegratedExperienceDisabled: z.boolean(),
  isEligibleForPersonalLearningBudget: z.boolean(),
  isManager: z.boolean(),
  languagePreference: LanguagePreferenceSchema,
  lastName: z.string(),
  lxpSsoUrl: z.string(),
  plbType: FinanceTypeSchema.nullable().optional(),
  ssoProvider: z.string().nullable().optional(),
  userCompanyId: z.number(),
});
