import {CostCenterVM} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {CostCenterVMSchema} from '../zodSchemas/CostCenterVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Company/CompanyController.cs

/*
|--------------------------------------------------------------------------
|  get-cost-centers 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCostCentersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: costCenters,
 *    isLoading: isCostCentersLoading,
 *    invalidateExact: invalidateCostCenters,
 *  } = useCostCentersQuery(null)
 *
 *  @access Manager Access Only
 *
 */

export const useCostCentersQuery = queryHookFactory<
  null,
  CostCenterVM[] // return type
>('useCostCentersQuery', () => `company/get-cost-centers`, {
  responseSchema: z.array(CostCenterVMSchema),
});

// CACHED
export const useCostCentersQueryCached = queryHookFactory<
  null,
  CostCenterVM[] // return type
>(
  'useCostCentersQuery',
  () => `company/get-cost-centers`,
  {responseSchema: z.array(CostCenterVMSchema)},
  {staleTime: 600000}
);
