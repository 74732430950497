import {useEffect, useState} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import {useMutation, useQuery} from 'react-query';
import {
  getUpdateAcademyLevelRm,
  getUpdateAcademyLevelOrderRm,
} from '@store/apiEndpoints/academy/mutations';
import {getAcademyLevelDetailsQuery} from '@store/apiEndpoints/academy/queries';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import AddCustomProgramDrawerContainer from '../AddCustomProgram/AddCustomProgramDrawer.container';
import AddMarketplaceProgramDrawerContainer from '../AddMarketplaceProgram/AddMarketplaceProgramDrawer.container';
import LevelsScreenHeader from './LevelsScreenHeader';
import DeleteLevelItemContainer from './LevelsSideNav/DeleteLevelItem.container';
import {setShowContentUpsertModal} from '@components/admin/pages/academies/Levels/LevelsScreen';
import {
  useAcademyLevelVMsQuery,
  useAcademyStepVMsQuery,
} from '@hooks/apiEndpoints/academy/queries';
import {AcademyLevelVM, AcademyLevelPayload} from '@generated/interfaces';
import {
  mapTextToEnglish,
  mapTextToUserLanguage,
} from '@utils/enumMapping/MapTextToUserLanguage';
import {useAuth} from '@utils/oidc-auth-wrapper';
import AddCatalogContentDrawer from '../AddCatalogContentDrawer/AddCatalogContentDrawer.container';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface LevelsScreenHeaderContainerProps {
  selectedLevelId: number;
  academyId: number;
  setShowCustomContentModal: (show: boolean) => void;
  setShowDiscussionModal: (show: boolean) => void;
  setShowContentUpsertModal: setShowContentUpsertModal;
  hasRenamedLevel: boolean;
  isNewLevel: boolean;
  getScreenNavItemUrlPath: (levelId: number, _new?: boolean) => string;
}

function LevelsScreenHeaderContainer({
  hasRenamedLevel,
  selectedLevelId,
  academyId,
  setShowCustomContentModal,
  setShowContentUpsertModal,
  setShowDiscussionModal,
  isNewLevel,
  getScreenNavItemUrlPath,
}: LevelsScreenHeaderContainerProps) {
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [showMarketplaceProgramDrawer, setShowMarketplaceProgramDrawer] =
    useState(false);
  const [showCatalogContentDrawer, setShowCatalogContentDrawer] =
    useState(false);
  const [showCustomProgramDrawer, setShowCustomProgramDrawer] = useState(false);
  const [levelDetails, setLevelDetails] = useState<AcademyLevelVM>(null);

  const {data: levels, invalidateExact: invalidateLevels} =
    useAcademyLevelVMsQuery({academyId, excludeUserProgress: true});

  const {data: steps, invalidateExact: invalidateSteps} =
    useAcademyStepVMsQuery(selectedLevelId);

  const level = levels?.find(({id}) => id === selectedLevelId);
  const academyLevelDetailsRq = getAcademyLevelDetailsQuery(selectedLevelId);
  const getLevelQuery = useQuery<AcademyLevelVM>(
    academyLevelDetailsRq.queryKey,
    () =>
      simpleQueryFn(academyLevelDetailsRq.path, (data) => {
        setLevelDetails(data);
      })
  );

  const {user} = useAuth();

  useEffect(() => {
    getLevelQuery.refetch();
  }, [selectedLevelId, hasRenamedLevel]);

  // Academy Level Order Mutator
  const updateAcademyLevelOrder = useMutation(
    (args: {id: number; order: number}) => {
      const updateAcademyLevelOrder = getUpdateAcademyLevelOrderRm(args);
      return simpleMutationFn<null>(
        updateAcademyLevelOrder.path,
        updateAcademyLevelOrder.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateLevels();
      },
      onError: async () => {
        datadogLogs.logger.error(
          `Error while updating academy level order, Academy ID: ${academyId}`
        );
      },
    }
  );

  const updateAcademyLevel = useMutation(
    (args: AcademyLevelPayload) => {
      const updateAcademyLevel = getUpdateAcademyLevelRm(args);
      return simpleMutationFn<null>(
        updateAcademyLevel.path,
        updateAcademyLevel.payload
      );
    },
    {
      onSuccess: () => {
        getLevelQuery.refetch();
        invalidateLevels();
      },
      onError: () => {
        datadogLogs.logger.error(
          `Error while updating academy level, Academy ID: ${academyId}`
        );
      },
    }
  );

  return (
    <>
      <LevelsScreenHeader
        handleMoveLevelToBottom={async () =>
          await updateAcademyLevelOrder.mutateAsync({
            order: levels?.length,
            id: selectedLevelId,
          })
        }
        handleMoveLevelToTop={async () =>
          await updateAcademyLevelOrder.mutateAsync({
            order: 1,
            id: selectedLevelId,
          })
        }
        selectedLevelId={selectedLevelId}
        hideAddStepButton={
          steps?.length === 0 && user?.isAcademiesIntegratedExperienceDisabled
        }
        isLXPUser={!user?.isAcademiesIntegratedExperienceDisabled}
        description={levelDetails?.description}
        onClickShowCustomContentModal={() => setShowCustomContentModal(true)}
        onClickShowDiscussionModal={() => setShowDiscussionModal(true)}
        setShowContentUpsertModal={setShowContentUpsertModal}
        updateLevel={(newTitle: string, newDescription: string) =>
          updateAcademyLevel.mutateAsync({
            academyId,
            id: selectedLevelId,
            title: mapTextToEnglish(newTitle),
            description: newDescription,
            order: level?.order,
            requiredId: level?.requiredId,
          })
        }
        title={mapTextToUserLanguage(levelDetails?.title)}
        onClickDelete={() => {
          setShowDeleteSectionModal(true);
        }}
        onClickAddMarketplaceProgram={() => {
          setShowMarketplaceProgramDrawer(true);
        }}
        onClickAddCatalogContent={() => {
          setShowCatalogContentDrawer(true);
        }}
        onClickAddCustomProgram={() => {
          setShowCustomProgramDrawer(true);
        }}
        isNewLevel={isNewLevel}
      />
      {showDeleteSectionModal && (
        <DeleteLevelItemContainer
          getScreenNavItemUrlPath={getScreenNavItemUrlPath}
          steps={steps}
          onCancel={() => {
            setShowDeleteSectionModal(false);
          }}
          academyId={academyId}
          levelId={selectedLevelId}
          moveContentSectionOptions={levels
            .filter(({id}) => {
              return id !== selectedLevelId;
            })
            .map(({id, title}) => {
              return {title, id};
            })}
          isSectionContentEmpty={steps?.length === 0}
        />
      )}
      {showMarketplaceProgramDrawer && (
        <AddMarketplaceProgramDrawerContainer
          onClose={({shouldRefresh}) => {
            setShowMarketplaceProgramDrawer(false);
            if (shouldRefresh) {
              invalidateSteps();
            }
          }}
          levelId={selectedLevelId}
          existingSteps={steps}
        />
      )}
      {showCatalogContentDrawer && (
        <AddCatalogContentDrawer
          onClose={(callbackOptions) => {
            setShowCatalogContentDrawer(false);
            if (callbackOptions.shouldRefresh) {
              invalidateSteps();
            }
          }}
          existingSteps={steps}
        />
      )}
      {showCustomProgramDrawer && (
        <AddCustomProgramDrawerContainer
          existingSteps={steps}
          onClose={({shouldRefresh}) => {
            setShowCustomProgramDrawer(false);
            if (shouldRefresh) {
              invalidateSteps();
            }
          }}
          levelId={selectedLevelId}
        />
      )}
    </>
  );
}

export default LevelsScreenHeaderContainer;
