import {AcademyUserVM} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {AcademyUserVMSchema} from '../zodSchemas/AcademyUserVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyUsersController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesUsers,
 *    isLoading: isAcademiesUsersLoading,
 *    invalidateExact: invalidateAcademiesUsers,
 *  } = useAcademiesUsersQuery({
 *    academyId,
 *      queryParams: {
 *        incompleteStepId
 *    }
 *  })
 *
 */

export const useAcademiesUsersQuery = queryHookFactory<
  {academyId: number; queryParams?: {incompleteStepId?: number}}, // path variables
  AcademyUserVM[] // return type
>(
  'useAcademiesUsersQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users`,
      {academyId},
      queryParams
    ),
  {responseSchema: z.array(AcademyUserVMSchema)}
);

// CACHED
export const useAcademiesUsersQueryCached = queryHookFactory<
  {academyId: number; queryParams?: {incompleteStepId?: number}}, // path variables
  AcademyUserVM[] // return type
>(
  'useAcademiesUsersQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users`,
      {academyId},
      queryParams
    ),
  {responseSchema: z.array(AcademyUserVMSchema)},
  {staleTime: 600000}
);
