import ContentTypeIcon from '@blocks/ContentTypeIcon';
import {LabelAndIconContainer} from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreen.container';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  BUTTON_HEIGHT,
  LearnInModal,
} from '@components/reusable/Modal/Modal.style';
import {ContentType, CustomizableLearningCategory} from '@generated/enums';
import {AcademyStepVM} from '@generated/interfaces';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Form} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import {useAttachmentsLogic} from './Attachments';
import {
  AcademyStepContentPayload,
  AttachmentResponse,
  AttachmentVM,
} from '@models/serverModels';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {AcademyStepContentModalPayload} from '@models/clientModels';
import {normalizeFormOutput} from './AddEditContent.normalizers';
import {getStepTypeConfig} from './AddEditContent.config';
import useFeatureFlags from '@hooks/useFeatureFlags';
import useShouldShowRichTextEditor from '@components/reusable/RichTextEditor/utils/useShouldShowRichTextEditor';
import {isProductionAny} from '@utils/environment-helpers';

export interface AddEditContentProps {
  step?: AcademyStepVM;
  contentType: ContentType;
  attachment?: AttachmentVM | AttachmentResponse;
  uploadProgress?: number | undefined;
  noModal?: boolean;
  onSubmit: (args: AcademyStepContentModalPayload) => void;
  onDeleteAttachment: () => void | Promise<void>;
  visible?: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onClickDelete: (id: number) => void;
  learningCategory: CustomizableLearningCategory;
  onCancelUpload?: () => Promise<void>;
  /** @deprecated @todo use component statically */
  stepRequirementComponent?: JSX.Element;
}

const MainContainer = styled.div`
  .ql-container {
    min-height: 80px;
  }
`;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
`;

/**
 * Wiki: https://github.com/learnin-inc/learn-in/wiki/AddEditContent-Modal
 */
const AddEditContent: React.FC<AddEditContentProps> = ({
  contentType,
  step,
  visible,
  noModal,
  attachment,
  uploadProgress,
  onSubmit,
  onDeleteAttachment,
  onCancel,
  onClickDelete,
  isLoading,
  stepRequirementComponent,
  learningCategory,
  onCancelUpload,
}) => {
  const [form] = Form.useForm();

  const {isFeatureFlagOn} = useFeatureFlags();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const attachmentProps = useAttachmentsLogic({
    initialAttachmentValue: attachment,
    initialLinkValue: step?.links?.[0],
    onCancelUpload,
  });

  const {
    fileToUpload,
    shouldDeleteAttachment,
    isDeletingAttachment,
    getAttachmentPayload,
    waitForAttachmentDeletion,
  } = attachmentProps;

  const {contentDescription, component: StepTypeForm} =
    getStepTypeConfig(contentType);

  const modalTitle = i18n.t(
    step?.id ? k.CTA__EDIT_ITEM__FORMAT : k.CTA__NEW_ITEM__FORMAT,
    {
      item: contentDescription,
    }
  );

  const shouldShowRichTextEditor = useShouldShowRichTextEditor();

  const getLabelSaveButton = () => {
    switch (true) {
      case !!fileToUpload:
        return i18n.t(k.FILE__UPLOAD__SAVE_AND_UPLOAD_CONTENT);
      case shouldDeleteAttachment:
        return i18n.t(k.FILE__UPLOAD__SAVE_AND_DELETE_ATTACHMENT);
      default:
        return i18n.t(k.CTA__SAVE_ITEM__FORMAT, {
          item: contentDescription,
        });
    }
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      setIsSaving(true);

      await waitForAttachmentDeletion(onDeleteAttachment);

      const formFields = normalizeFormOutput(form);

      onSubmit({
        contentType,
        ...getAttachmentPayload(),
        ...(formFields as AcademyStepContentPayload),
      });
    } catch (error) {
      // form validation failed
      if (!isProductionAny) {
        console.error(error);
      }
    }
  };

  const renderForm = () => {
    return (
      <MainContainer>
        <Form layout="vertical" form={form}>
          <div style={{marginBottom: '24px'}}>
            <LabelAndIconContainer>
              <ContentTypeIcon contentType={contentType} />{' '}
              <ModalTitle>{modalTitle}</ModalTitle>
            </LabelAndIconContainer>
          </div>

          {/* Dynamic form by step type */}
          <StepTypeForm
            step={step}
            learningCategory={learningCategory}
            attachmentProps={{
              ...attachmentProps,
              contentUploadPercent: uploadProgress,
            }}
            canShowAllowSkip={
              learningCategory === CustomizableLearningCategory.Academy &&
              isFeatureFlagOn.SkipAcademyStep
            }
            showRichTextUploads={shouldShowRichTextEditor}
            stepRequirementComponent={stepRequirementComponent}
          />
        </Form>
      </MainContainer>
    );
  };

  if (noModal) {
    return (
      <>
        {renderForm()}
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <LearnInButton
            tag={ButtonTags.Primary}
            style={{
              width: 'fit-content',
              height: BUTTON_HEIGHT,
              padding: '0px 16px',
            }}
            onClick={handleSubmit}>
            {getLabelSaveButton()}
          </LearnInButton>
        </div>
      </>
    );
  }
  return (
    <LearnInModal
      width={800}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={getLabelSaveButton()}
      buttonDisabled={isDeletingAttachment || isLoading || isSaving}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={onCancel}
      onOk={handleSubmit}
      footerLeft={
        step?.id ? (
          <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <LearnInButton
              noPadding
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={() => {
                onClickDelete(step.id);
              }}>
              {i18n.t(k.CTA__DELETE_ITEM__FORMAT, {item: contentDescription})}
            </LearnInButton>
          </div>
        ) : null
      }
      ariaLabel={modalTitle}>
      {renderForm()}
    </LearnInModal>
  );
};

export default AddEditContent;
