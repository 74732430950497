import {TimeZoneVM, UserEventVM} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {TimeZoneVMSchema} from '../zodSchemas/TimeZoneVMSchema.schema';
import {UserEventVMSchema} from '../zodSchemas/UserEventVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Incentives/TimeIncentiveController.cs

/*
|--------------------------------------------------------------------------
|  get-time-balance 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTimeBalanceQuery} from '@generated/hooks';
 *
 *  const {
 *    data: timeBalance,
 *    isLoading: isTimeBalanceLoading,
 *    invalidateExact: invalidateTimeBalance,
 *  } = useTimeBalanceQuery(null)
 *
 */

export const useTimeBalanceQuery = queryHookFactory<
  null,
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>('useTimeBalanceQuery', () => `time/get-time-balance`, {responseSchema: null});

// CACHED
export const useTimeBalanceQueryCached = queryHookFactory<
  null,
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useTimeBalanceQuery',
  () => `time/get-time-balance`,
  {responseSchema: null},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-timezones 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useTimezonesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: timezones,
 *    isLoading: isTimezonesLoading,
 *    invalidateExact: invalidateTimezones,
 *  } = useTimezonesQuery(null)
 *
 */

export const useTimezonesQuery = queryHookFactory<
  null,
  TimeZoneVM[] // return type
>('useTimezonesQuery', () => `time/get-timezones`, {
  responseSchema: z.array(TimeZoneVMSchema),
});

// CACHED
export const useTimezonesQueryCached = queryHookFactory<
  null,
  TimeZoneVM[] // return type
>(
  'useTimezonesQuery',
  () => `time/get-timezones`,
  {responseSchema: z.array(TimeZoneVMSchema)},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  get-user-events 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserEventsQuery} from '@generated/hooks';
 *
 *  const {
 *    data: userEvents,
 *    isLoading: isUserEventsLoading,
 *    invalidateExact: invalidateUserEvents,
 *  } = useUserEventsQuery(null)
 *
 */

export const useUserEventsQuery = queryHookFactory<
  null,
  UserEventVM[] // return type
>('useUserEventsQuery', () => `time/get-user-events`, {
  responseSchema: z.array(UserEventVMSchema),
});

// CACHED
export const useUserEventsQueryCached = queryHookFactory<
  null,
  UserEventVM[] // return type
>(
  'useUserEventsQuery',
  () => `time/get-user-events`,
  {responseSchema: z.array(UserEventVMSchema)},
  {staleTime: 600000}
);
