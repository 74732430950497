import {AcademyStatus} from './../enums';
import {IAcademyBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IAcademyBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIAcademyBasicInfoMockData(
  args?: Partial<IAcademyBasicInfo>
): IAcademyBasicInfo {
  return {
    academyProfileImageUrl: faker.image.imageUrl(),
    degreedId: String(faker.datatype.number()),
    description: faker.lorem.lines(),
    discussionId: String(faker.datatype.number()), // optional
    duration: faker.datatype.number(), // optional
    endDate: String(faker.date.past()), // optional
    groupId: String(faker.datatype.number()),
    id: faker.datatype.number(),
    invitationEmail: faker.datatype.boolean(),
    joinId: String(faker.datatype.number()),
    name: faker.name.fullName(),
    restrictedAccess: faker.datatype.boolean(),
    startDate: String(faker.date.past()), // optional
    status: AcademyStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IAcademyBasicInfoMockData: IAcademyBasicInfo = {
  academyProfileImageUrl: 'https://loremflickr.com/640/480',
  degreedId: '1',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  discussionId: '1', // optional
  duration: 123, // optional
  endDate: '2023-03-29T22:17:19.738072', // optional
  groupId: '1',
  id: 1,
  invitationEmail: true,
  joinId: '1',
  name: 'Bobby Johnson',
  restrictedAccess: true,
  startDate: '2023-03-29T22:17:19.738072', // optional
  status: AcademyStatus.Draft,
};
