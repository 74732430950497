import {Input} from 'antd';
import styled from 'styled-components';
import {EditOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {pxToRem} from '@utils/styleMixins';
import Grid from '@blocks/Grid';
import {Breakpoints} from '@utils/responsiveUtils';

export const Page = styled.section`
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  padding-left: 117px;
  padding-right: 20px;
  margin-bottom: 24px;
`;

export const Card = styled.section`
  display: flex;
  justify-content: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  padding: 24px;
  max-width: 928px;
  width: 100%;
  margin: 0 auto 24px;

  &:first-child {
    margin-top: 24px;
  }
`;

export const CardContent = styled.section`
  width: 100%;
`;

export const Header = styled.h1`
  font-family: Roboto;
  color: ${COLORS.Neutral900};
  font-size: ${pxToRem(20, 16)};
  font-weight: 500;
  margin-bottom: -3px;
`;

export const Subheader = styled.h3`
  color: ${COLORS.Neutral600};
  font-size: ${pxToRem(16, 16)};
  font-weight: 400;
  margin-bottom: 10px;
`;

export const TextArea = styled(Input.TextArea)`
  border-radius: 4px;
  border-color: ${COLORS.Neutral300};
`;

export const NameInput = styled(LearnInInput)`
  font-size: 0.875rem;
`;

export const AttachmentCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
  border: 1px solid #bcbdbe;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;
`;

export const EditIcon = styled(EditOutlined)`
  margin-right: 8px;
`;

interface DateInputProps {
  hasError?: boolean;
}
export const DateInput = styled(Grid.Container<DateInputProps>)`
  height: 68px;
  margin-bottom: ${({hasError}) => (hasError ? '48px' : '24px')};
  @media (max-width: ${Breakpoints.Tablet}px) {
    height: 166px;
  }
`;

export const DateInputItem = styled.div`
  display: flex;
  gap: 16px;
  alignitems: flex-start;
`;

export const DateText = styled.div`
  margin-top: 18px;
`;
