import {useState, useRef} from 'react';
import {computeFileChecksumMd5} from '@utils/fileUtils';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {getDeleteAcademyStepContentAttachmentRm} from '@store/apiEndpoints/academy/mutations';
import {
  AttachmentType,
  CustomizableLearningCategory,
  PlanItemType,
  ReimbursementItemType,
} from '@generated/enums';
import {useMutation} from 'react-query';
import {
  largeAttachmentQueryFn,
  multipleAttachmentProgressQueryFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {CustomProgramAttachmentPayload} from '@models/serverModels';
import {notify} from '@components/user/notifications';
import {AttachmentLinkType} from '@generated/enums';

export const getUploadChunkQueryKey = (contentId: number): string[] => {
  return ['contentAttachmentUpload', String(contentId)];
};

/*
|-----------------------------------------------------------
| Interfaces
|-----------------------------------------------------------
*/

type AttachmentPayload = {
  contentId?: number;
  index?: number;
  linkType?: AttachmentLinkType;
};

interface UseChunkUpload {
  chunkUpload: (
    attachment: any,
    {contentId, index, linkType}: AttachmentPayload
  ) => Promise<void>;
  cancelUpload: () => Promise<void>;
  multipleUploadProgress: {
    progress: number;
    index: string;
  };
  uploadProgress: undefined | number;
  fileId: string;
}

interface UseChunkUploadProps {
  query: any;
  customizableLearningCategory:
    | CustomizableLearningCategory
    | ReimbursementItemType
    | PlanItemType;
  onComplete?: () => void;
  onError?: () => void;
}

/*
|-----------------------------------------------------------
| Main
|-----------------------------------------------------------
*/

const CHUNK_BATCH_SIZE = 3;

// Set default value to normal usage, eventually we can remove this
export default function useChunkUpload({
  query,
  customizableLearningCategory,
  onComplete,
  onError,
}: UseChunkUploadProps): UseChunkUpload {
  // STATE
  const abortController = useRef(new AbortController());
  const [multipleUploadProgress, setMultipleUploadProgress] = useState({
    progress: 0,
    index: '',
  });
  const {uploadProgress, onStart, onUpdateProgress, onFinish} =
    useChunkUploadUtils();
  const [fileId, setFileId] = useState('');

  // MUTATIONS
  const deleteContentAttachmentMutation = useMutation(
    (attachmentId: number) => {
      const deleteAcademyStepContentAttachmentRm =
        getDeleteAcademyStepContentAttachmentRm(attachmentId);
      return simpleDeleteFn<null>(
        deleteAcademyStepContentAttachmentRm.path,
        deleteAcademyStepContentAttachmentRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.deleteCustomProgramContentAttachmentSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramContentAttachmentError();
      },
    }
  );

  const fileUploadMutation = useMutation(
    ({
      attachmentChunk,
      contentId,
      index,
    }: {
      attachmentChunk: CustomProgramAttachmentPayload;
      contentId: number;
      index?: number;
    }) => {
      const queryRq = query(contentId, customizableLearningCategory);
      if (index === undefined) {
        return largeAttachmentQueryFn(queryRq.path, attachmentChunk);
      }
      return multipleAttachmentProgressQueryFn(
        queryRq.path,
        attachmentChunk,
        index,
        setMultipleUploadProgress
      );
    },
    {
      onError: ({request}) => {
        if (request.status === 400) {
          notify.uploadContentAttachmentError();
        }
      },
    }
  );

  // Function to upload entire file (contains function to upload each chunk)
  async function chunkUpload(
    attachment: any,
    {contentId, index, linkType}: AttachmentPayload
  ) {
    // Calculate total number of chunks
    const totalChunks = Math.ceil(
      attachment.size / UploadConstraints.MinChunkSize
    );

    const chunks = Array.from({length: totalChunks}, (_, i) =>
      attachment.slice(
        i * UploadConstraints.MinChunkSize,
        (i + 1) * UploadConstraints.MinChunkSize
      )
    );

    // Function to upload a single chunk
    async function uploadSingleChunk(
      chunkIdx: number,
      chunk: Blob,
      attachmentId?: number
    ) {
      // Get checksum
      const chunkCheckSum = await computeFileChecksumMd5(chunk);

      // Upload chunk
      const res = await fileUploadMutation.mutateAsync({
        attachmentChunk: {
          file: chunk,
          id: contentId,
          linkedItemId: contentId,
          linkType,
          chunkNumber: chunkIdx + 1, // 1-indexed, not 0-indexed
          chunkSize: chunk.size,
          totalChunks,
          totalSize: attachment.size,
          chunkCheckSum,
          fileName: attachment?.name,
          type: attachment?.type?.includes('video')
            ? AttachmentType.Video
            : AttachmentType.File,
          attachmentId,
        },
        contentId,
        index,
      });

      return res;
    }

    /** UPLOAD FIRST CHUNK */
    onStart();
    const firstChunk = chunks[0];
    const attachmentId = await uploadSingleChunk(0, firstChunk);
    setFileId(attachmentId);
    if (chunks.length === 2) {
      const secondChunk = chunks[1];
      await uploadSingleChunk(1, secondChunk, attachmentId);
    } else if (chunks.length > 2) {
      const lastChunk = chunks[chunks.length - 1];

      // Function to upload a batch of chunks in parallel
      async function uploadChunkBatch(startIdx: number) {
        const batchChunks = chunks.slice(startIdx, startIdx + CHUNK_BATCH_SIZE);

        // Upload all chunks in the batch in parallel
        await Promise.all(
          batchChunks.map((chunk, idx) => {
            const chunkIdx = startIdx + idx;

            // Do not upload last chunk
            if (chunkIdx >= chunks.length - 1) {
              return Promise.resolve();
            }

            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(
                  uploadSingleChunk(chunkIdx, chunk, attachmentId).then(() => {
                    console.log(`Chunk ${chunkIdx + 1} uploaded`);
                  })
                );
              }, 750 * idx);
            });
          })
        );

        // Update progress after each batch completes
        onUpdateProgress((state) =>
          Math.min(state + (batchChunks.length * 100) / totalChunks, 100)
        );
      }

      // Upload the remaining chunks in batches of size `n`
      for (let i = 1; i < totalChunks; i += CHUNK_BATCH_SIZE) {
        if (abortController.current.signal.aborted) {
          await deleteContentAttachmentMutation.mutateAsync(attachmentId);
          onError?.();
          return;
        }
        await uploadChunkBatch(i);
      }

      await uploadSingleChunk(chunks.length - 1, lastChunk, attachmentId);
      onComplete?.();
    }
  }

  const cancelUpload = async () => {
    try {
      abortController.current.abort();
      notify.uploadContentAttachmentCancellation();
      onFinish();
    } catch (e) {
      console.error(e);
    }
  };

  return {
    cancelUpload,
    chunkUpload,
    multipleUploadProgress,
    fileId,
    uploadProgress,
  };
}

export function useChunkUploadUtils() {
  const [uploadProgress, setUploadProgress] = useState<undefined | number>(
    undefined
  );
  return {
    uploadProgress,
    onStart: () => setUploadProgress(0),
    onUpdateProgress: setUploadProgress,
    onFinish: () => setUploadProgress(undefined),
  };
}
