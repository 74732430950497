// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CreditCardTransactionType.cs

enum CreditCardTransactionType {
  Capture,
  Refund,
  SpendingLimitIncrease,
}

export default CreditCardTransactionType;
