import {ErrorMessage} from '@components/reusable/LearnInForm';
import {i18n, k} from '@i18n/translate';
import {
  formatPastingOptions,
  slimToolbarOptionsWithLink,
} from '@utils/reactQuillUtils';
import ReactQuill from 'react-quill';
import {handleLicenseApprovalGuidelineChange} from '../AddUpdateLicenseDrawer.handlers';
import {
  LicenseLabel,
  LicenseReactQuill,
} from '../AddUpdateLicenseDrawer.styled';
import {
  LicenseFormFieldErrors,
  LicenseFormFields,
} from '../AddUpdateLicenseDrawer.types';

interface LicenseApprovalGuidelinesItemProps {
  approvalGuideline: string;
  approvalGuidelineRef: React.MutableRefObject<ReactQuill | undefined>;
  formFieldErrors: LicenseFormFieldErrors;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setApprovalGuideline: (approvalGuideline: string) => void;
}

function LicenseApprovalGuidelinesItem({
  approvalGuideline,
  approvalGuidelineRef,
  formFieldErrors,
  setFormFieldErrors,
  setApprovalGuideline,
}: LicenseApprovalGuidelinesItemProps) {
  return (
    <section>
      <LicenseLabel
        margin="0 0 0.75rem 0"
        htmlFor="approval-guideline"
        onClick={() => approvalGuidelineRef.current?.focus()}>
        {i18n.t(k.LICENSE__APPROVAL_GUIDELINES__LABEL)}
      </LicenseLabel>
      <LicenseReactQuill
        id="approval-guideline"
        formats={formatPastingOptions}
        theme="snow"
        value={approvalGuideline || ''}
        modules={{toolbar: slimToolbarOptionsWithLink}}
        onChange={(editor) => {
          handleLicenseApprovalGuidelineChange({
            editor,
            formFieldErrors,
            setFormFieldErrors,
            setLicenseApprovalGuideline: setApprovalGuideline,
          });
        }}
        ref={approvalGuidelineRef}
      />
      <ErrorMessage
        message={formFieldErrors[LicenseFormFields.ApprovalGuideline]}
      />
    </section>
  );
}

export default LicenseApprovalGuidelinesItem;
