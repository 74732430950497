import {SectionNav} from './SectionNav.view';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {UserPaths, AdminPaths} from '@utils/ClientPaths';
import {theme} from '@utils/color';
import {saveLocalAcademyActivity} from '@hooks/academies/academyUserActivity';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {
  useAcademyLevelsQueryCached,
  useAcademyQueryCached,
} from '@generated/hooks/academy.get.hooks';

export const SectionNavContainer = () => {
  const {academyId, levelId} = useParams();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {showSkillsTagging} = useShowSkillsTagging();

  const {data: academy} = useAcademyQueryCached({
    academyId: parseInt(academyId),
    queryParams: {includeSkills: showSkillsTagging},
  });
  const {data: sections, isLoading} = useAcademyLevelsQueryCached({
    academyId: parseInt(academyId),
    queryParams: {excludeUserProgress: false},
  });

  const handleClickSectionNavCard = (sectionId: number) => {
    // TODO: add ClientPath functions (ACA-2159)
    if (pathname.includes(AdminPaths.Academies)) {
      navigate(`/admin/academies/view/${academyId}/content/${sectionId}`);
    } else if (pathname.includes(UserPaths.Academies)) {
      navigate(`/user/academies/view/${academyId}/content/${sectionId}`);
    } else if (pathname.includes(UserPaths.Permissions)) {
      navigate(
        `/user/permissions/academies/${academyId}/view/content/${sectionId}`
      );
    }
    // Save last visited level
    saveLocalAcademyActivity(academyId, {
      lastLevel: sectionId,
      lastPosition: null,
    });
  };

  return (
    <SectionNav
      isLoading={isLoading}
      levelId={parseInt(levelId)}
      sections={sections}
      branding={
        !!academy?.branding?.primaryBrandColor // Legacy academies without brand colors will use the default theme
          ? academy.branding
          : theme
      }
      showBrandingScreenDisplay={pathname.includes('branding')}
      onClickSectionNavCard={handleClickSectionNavCard}
      isActivePlanItem={pathname.includes(UserPaths.Academies)}
    />
  );
};
