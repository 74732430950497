import {i18n, k} from '@i18n/translate';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import AcademiesCard from '../AcademiesCard/AcademiesCard';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {AcademyWithMembershipStatusVM} from '@generated/interfaces';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {Skeleton} from 'antd';
import range from 'lodash/range';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AcademiesListProps {
  academies: AcademyWithMembershipStatusVM[];
  isLoading: boolean;
  onClickViewAcademy: (academy: AcademyWithMembershipStatusVM) => void;
  title?: string;
}

function AcademiesList({
  academies,
  isLoading,
  onClickViewAcademy,
  title,
}: AcademiesListProps) {
  const {showSkillsTagging} = useShowSkillsTagging();
  useSetPageTitle(title);
  return (
    <>
      <PageTitleBar title={i18n.t(k.ACADEMY__PLURAL)} />
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: 18,
          }}>
          {range(0, 10).map((index) => {
            return (
              <Skeleton
                key={index}
                active
                style={{width: '45%', margin: '8px'}}
              />
            );
          })}
        </div>
      ) : (
        <PageContentLayout style={{marginTop: '16px'}}>
          {academies.map((academy) => (
            <AcademiesCard
              academy={academy}
              key={academy.id}
              onClickViewAcademy={() => onClickViewAcademy(academy)}
              canShowSkills={showSkillsTagging}
            />
          ))}
        </PageContentLayout>
      )}
    </>
  );
}

export default AcademiesList;
