import {
  CreditCardTransactionCause,
  CreditCardTransactionType,
} from './../enums';
import {CreditCardTransaction} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: CreditCardTransaction

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreditCardTransactionMockData(
  args?: Partial<CreditCardTransaction>
): CreditCardTransaction {
  return {
    ...getBaseEntityMockData(),
    amount: faker.datatype.number(),
    authorId: faker.datatype.number(), // optional
    cause: CreditCardTransactionCause.CardUse,
    creditCardId: faker.datatype.number(),
    currency: faker.word.noun(),
    id: faker.datatype.number(),
    merchantId: String(faker.datatype.number()),
    notes: faker.word.noun(),
    providerTransactionId: String(faker.datatype.number()),
    timeStamp: String(faker.datatype.number()),
    type: CreditCardTransactionType.Capture,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreditCardTransactionMockData: CreditCardTransaction = {
  ...BaseEntityMockData,
  amount: 5,
  authorId: 1, // optional
  cause: CreditCardTransactionCause.CardUse,
  creditCardId: 1,
  currency: 'string',
  id: 1,
  merchantId: '1',
  notes: 'string',
  providerTransactionId: '1',
  timeStamp: '5',
  type: CreditCardTransactionType.Capture,
};
