import ReportsBySectionView from './ReportsBySection.view';
import {Routes, Route, useNavigate, useParams} from 'react-router-dom';
import {AcademyReportsPageTabs} from '@utils/ClientPaths';
import {ReportsBySectionContainerProps} from './ReportsBySection.interfaces';
import {
  AcademyOverviewLevelReportVm,
  AcademyOverviewStepReportVm,
} from '@generated/interfaces';
import {ReportsBySectionDetailsTableDrawer} from '../ReportsBySectionDetailsTable';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {sortByOrder} from '@utils/sort';
import {useAcademyOverviewLevelReportQueryCached} from '@generated/hooks/report.get.hooks';

function ReportsBySectionContainer({basePath}: ReportsBySectionContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const navigate = useNavigate();
  const {academyId} = useParams();
  const {data: academyOverviewLevelReport} =
    useAcademyOverviewLevelReportQueryCached({
      academyId: parseInt(academyId),
    });

  const nestedTableDataSource = academyOverviewLevelReport?.reduce(
    (nestedTableData, level) => {
      nestedTableData[level.academyLevelId] = level.steps
        .sort(sortByOrder)
        .map((step, i) => ({
          ...step,
          id: i,
        }));
      return nestedTableData;
    },
    {}
  );

  const handleClickLevelName = (row: AcademyOverviewLevelReportVm) => {
    navigate(
      [basePath, AcademyReportsPageTabs.Sections, row.academyLevelId].join('/')
    );
  };

  const onClickRowNestedTable = (row: AcademyOverviewStepReportVm) => {
    navigate(
      [
        basePath,
        AcademyReportsPageTabs.Sections,
        'step',
        row.academyStepId,
      ].join('/')
    );
  };

  const handleCloseDetailsDrawer = () => {
    navigate([basePath, AcademyReportsPageTabs.Sections].join('/'));
  };

  return (
    <>
      <ReportsBySectionView
        dataSource={academyOverviewLevelReport
          ?.sort(sortByOrder)
          .map((r, i) => ({...r, id: i}))}
        nestedTableDataSource={nestedTableDataSource}
        onClickLevelName={handleClickLevelName}
        onClickRowNestedTable={onClickRowNestedTable}
      />
      <Routes>
        <Route
          path="sections/step/:academyStepId/*"
          element={
            <ReportsBySectionDetailsTableDrawer
              showBulkNudgeButton={isFeatureFlagOn.NudgeEmails}
              onClose={handleCloseDetailsDrawer}
            />
          }
        />
        <Route
          path="sections/:academyLevelId"
          element={
            <ReportsBySectionDetailsTableDrawer
              onClose={handleCloseDetailsDrawer}
            />
          }
        />
      </Routes>
    </>
  );
}

export default ReportsBySectionContainer;
