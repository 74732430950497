import {useState, useMemo} from 'react';
import NoDataText from '@blocks/NoDataText';
import {AcademyPeopleParticipantsTabContainerProps} from './AcademyPeopleParticipantsTab.interfaces';
import AcademyPeopleParticipantsTabView from './AcademyPeopleParticipantsTab.view';
import translate from '@i18n/translate';
import {useParams} from 'react-router-dom';
import {useAcademyUsersQueryCached} from '@generated/hooks/academy.get.hooks';

function AcademyPeopleParticipantsTabContainer({}: AcademyPeopleParticipantsTabContainerProps) {
  const [searchInputValue, setSearchInputValue] = useState('');
  const {academyId} = useParams();
  const {data: participants, isLoading} = useAcademyUsersQueryCached({
    academyId: parseInt(academyId),
  });
  const filteredTableData = useMemo(() => {
    return participants?.filter(({name}) =>
      name?.toLocaleLowerCase().includes(searchInputValue.toLowerCase())
    );
  }, [searchInputValue, participants]);

  if (!isLoading && !participants?.length) {
    return (
      <NoDataText
        header={translate('ACADEMY__NO_PARTICIPANTS_HEADER')}
        subHeader={translate('ACADEMY__NO_PARTICIPANTS_SUBHEADER')}
      />
    );
  }
  return (
    <AcademyPeopleParticipantsTabView
      searchInputValue={searchInputValue}
      onChangeSearchInput={(e) => setSearchInputValue(e)}
      participantsTableDataSource={filteredTableData}
    />
  );
}

export default AcademyPeopleParticipantsTabContainer;
