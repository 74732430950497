import {UserSearchResultVM} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {UserSearchResultVMSchema} from '../zodSchemas/UserSearchResultVMSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserCompanyController/UserCompanyController.cs

/*
|--------------------------------------------------------------------------
|  {companyId}/users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCompaniesUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: companiesUsers,
 *    isLoading: isCompaniesUsersLoading,
 *    invalidateExact: invalidateCompaniesUsers,
 *  } = useCompaniesUsersQuery({
 *    companyId,
 *      queryParams: {
 *        limit,
 *        name
 *    }
 *  })
 *
 */

export const useCompaniesUsersQuery = queryHookFactory<
  {companyId: number; queryParams?: {limit?: number; name?: string}}, // path variables
  UserSearchResultVM[] // return type
>(
  'useCompaniesUsersQuery',
  ({companyId, queryParams}) =>
    addQueryParams(
      ({companyId}) => `companies/${companyId}/users`,
      {companyId},
      queryParams
    ),
  {responseSchema: z.array(UserSearchResultVMSchema)}
);

// CACHED
export const useCompaniesUsersQueryCached = queryHookFactory<
  {companyId: number; queryParams?: {limit?: number; name?: string}}, // path variables
  UserSearchResultVM[] // return type
>(
  'useCompaniesUsersQuery',
  ({companyId, queryParams}) =>
    addQueryParams(
      ({companyId}) => `companies/${companyId}/users`,
      {companyId},
      queryParams
    ),
  {responseSchema: z.array(UserSearchResultVMSchema)},
  {staleTime: 600000}
);
