import {LxpContentType} from './../enums';
import {LxpContentSearchResult} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LxpContentSearchResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLxpContentSearchResultMockData(
  args?: Partial<LxpContentSearchResult>
): LxpContentSearchResult {
  return {
    contentType: LxpContentType.Unknown,
    dateUpdated: String(faker.date.past()), // optional
    id: faker.datatype.number(),
    isHosted: faker.datatype.boolean(),
    title: faker.company.catchPhrase(),
    url: faker.internet.url(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LxpContentSearchResultMockData: LxpContentSearchResult = {
  contentType: LxpContentType.Unknown,
  dateUpdated: '2023-03-29T22:17:19.738072', // optional
  id: 1,
  isHosted: true,
  title: 'Advanced Web Design',
  url: 'https://this-person-does-not-exist.com/en', // optional
};
