import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {useNavigate} from 'react-router-dom';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {NewWindowIcon} from '@assets/new-window-icon';
import attachmentPreviewImg from '@assets/contentAttachmentPreview.png';
import styled from 'styled-components';
import {Video} from 'cloudinary-react';
import ContextMenuButton from '@blocks/ContextMenu';
import {ContentLink} from '@components/reusable/ContentLink';
import {formatUrl} from '@utils/stringUtils';
import {getPlaceholderImgSrc, resizeIfCloudinary} from '@utils/image-utils';
import {ForwardOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {
  AcademyStepType,
  ContentType,
  CurrencyCode,
  CustomizableLearningCategory,
  DisplayStatus,
  DisplayStatusWarningLevel,
  PlanItemType,
  RsvpStatus,
} from '@generated/enums';
import {
  CustomContentLink,
  ProgramDetailsVM,
  UserPlanProgramItemVM,
} from '@models/serverModels';
import {
  AcademyStepVM,
  AttachmentVM,
  UserItemStatusVM,
} from '@generated/interfaces';
import {
  getProgramDetailsFromAcademyPath,
  userCustomProgramDetailRoute,
} from '@utils/ClientPaths';
import {ContentCompleteButton} from '@blocks/CustomProgramUserDrawer/ContentCompleteButton';
import SearchCard from '@components/reusable/ItemSearchCard/SearchCard';
import {
  RequestApprovalButton,
  RequestLicenseButton,
  SelectedButton,
  ViewDetailsButton,
  ViewLicenseButton,
} from '@components/reusable/Button/LearnInButtons';
import {CheckCircleOutlined} from '@ant-design/icons/lib/icons';
import ContentCardContainer from '@blocks/CustomProgramUserDrawer/ContentCard.container';
import PreApprovalDrawer from '@components/user/pages/components/preApprovalDrawer/PreApprovalDrawer.container';
import WithStepIndexRule from '@components/user/pages/academy/AcademyContent/WithStepIndexRule';
import DeleteProgramItemModal from '@blocks/deleteProgramItemModal/DeleteProgramItemModal';
import {Breakpoints} from '@utils/responsiveUtils';
import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import {inPlanWithActiveLicense} from '@utils/itemUtils';
import {
  AttachmentTypeString,
  UserPlanItemStatusString,
} from '@models/clientEnums';
import DeleteLicensedOrCustomProgramItemModal from '@blocks/deleteLicensedOrCustomProgramItemModal/DeleteLicensedOrCustomProgramItemModal';
import {formatCurrency, useLocalizedAmountDisclaimer} from '@utils/moneyUtils';
import ActionButton from '@blocks/actionButton/ActionButton';
import {localizeDuration, localizeGeneric} from '@utils/l10nUtils';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {getLicenseCost} from '@utils/licenseUtils';
import {getCalendarLinks} from '@blocks/CustomProgramUserDrawer/EventFooterCTAs/utils';
import LxpContentCard from '@components/reusable/ItemSearchCard/LxpContentCard';

const mapAcademyStepTypeToContentType = (
  type: AcademyStepType
): ContentType | null => {
  switch (type) {
    case AcademyStepType.TextContent:
      return ContentType.Text;
    case AcademyStepType.ProjectContent:
      return ContentType.Project;
    case AcademyStepType.Discussion:
      return ContentType.Discussion;
    case AcademyStepType.EventContent:
      return ContentType.Event;
    default:
      return null;
  }
};

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Title = styled.h1`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  line-height: 1.5rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContentLinkText = styled.button`
  color: ${COLORS.Blue800};
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ContentContainerLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const AttachmentPreviewImg = styled.img`
  border: 1px solid ${COLORS.Neutral300};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.Neutral600};
  }
`;

const CardContainer = styled.div`
  background: ${COLORS.White};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  transition: 0.3s opacity;
  margin-bottom: 16px;
  margin-right: 10px;
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 10px;
  .ant-dropdown {
    // Prevents overlapping academy header when scrolling with open dropdowns
    z-index: 1;
  }
  @media (max-width: ${Breakpoints.Desktop}px) {
    margin-right: 0;
    border-radius: 0;
  }
`;

const CustomContentCardContainer = styled.div`
  width: 100%;
  padding: 24px;
`;

const ProgramCompletedWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ProgramCompletedText = styled.span`
  border-right: solid 1px ${COLORS.Neutral300};
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

const ProgramSkippedWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ProgramSkippedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

export const CustomContentStepDescription = styled.div`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
  margin-bottom: 16px;
  width: 100%;
  a {
    color: ${COLORS.Blue800};
  }
  .ql-editor {
    padding: 0 !important;
  }
  .ql-container {
    font-size: 1rem;
  }
  .ql-container.ql-disabled .ql-tooltip {
    display: none;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px auto 24px;
  width: 100%;
`;
const LinkContainer = styled.div`
  margin-bottom: 16px;
`;

/*
|--------------------------------------------------------------------------
| Utility functions
|--------------------------------------------------------------------------
*/
/**
 * Temporary solution to adapt the new status VM to the old RSVP status
 * until backend completes the migration also for custom programs
 * TODO: ACA-2168
 */
function temporarilyAdaptRsvpStatus(status: UserItemStatusVM): RsvpStatus {
  switch (status.displayStatus) {
    case DisplayStatus.RsvpedYes:
      return RsvpStatus.Yes;
    case DisplayStatus.RsvpedNo:
      return RsvpStatus.No;
    case DisplayStatus.RsvpedNone:
      return RsvpStatus.None;
    default:
      return;
  }
}

export function getHeaderData({
  step,
  tooltip,
  usersCurrency,
  exchangeRate,
}: {
  step?: AcademyStepVM;
  program?: ProgramDetailsVM;
  tooltip: string;
  usersCurrency: CurrencyCode;
  exchangeRate: number;
}) {
  return (
    step.type === AcademyStepType.MarketplaceProgram && {
      title: i18n.t(k.MONEY__TOTAL_COST),
      value: getLicenseCost(step, usersCurrency, exchangeRate),
      tooltip,
    }
  );
}

/*
|--------------------------------------------------------------------------
| Card Component
|--------------------------------------------------------------------------
*/

export enum StepItemCTAButtonType {
  Completed,
  Selected,
  ViewProgram,
  MarkComplete,
  RequestApproval,
  RequestLicense,
  ViewLicense,
  Requested,
  Rejected,
  Skipped,
}

export interface StepCardProps {
  academyId: number;
  attachments: AttachmentVM[];
  cardCount: number;
  credSparkDemoEnabled: boolean;
  deletePlanItem: () => void;
  exchangeRate: number;
  formattedDescription: string;
  index: number;
  isActivePlanItem: boolean;
  levelId: number;
  links: CustomContentLink[];
  markCustomProgramAsComplete: () => void;
  markMarketplaceProgramAsComplete: () => void;
  plan: UserPlanProgramItemVM[];
  program: ProgramDetailsVM;
  shouldBeInFocus?: boolean;
  skipProgram: (contentId: number) => void;
  step: AcademyStepVM;
  unmarkAsCompleted: () => void;
  unskipProgram: (contentId: number) => void;
  usersCurrency: CurrencyCode;
  onProgramStatusChanged?: () => void;
  withIndexRules?: boolean;
  isCompleted?: boolean;
}

function StepCard({
  academyId,
  attachments,
  cardCount,
  credSparkDemoEnabled,
  deletePlanItem,
  exchangeRate,
  formattedDescription,
  index,
  isActivePlanItem,
  levelId,
  links,
  markCustomProgramAsComplete,
  markMarketplaceProgramAsComplete,
  plan,
  program,
  shouldBeInFocus,
  skipProgram,
  step,
  unskipProgram,
  usersCurrency,
  unmarkAsCompleted,
  onProgramStatusChanged,
  withIndexRules,
  isCompleted,
}: StepCardProps) {
  const [showApprovalDrawer, setShowApprovalDrawer] = useState(false);
  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    useState(false);
  const [showViewLicenseModal, setShowViewLicenseModal] = useState(false);
  const [ctaButtons, setCtaButtons] = useState(null);
  const [isRemoveProgramModalVisible, setIsRemoveProgramModalVisible] =
    useState(false);
  const [
    isRemoveLicensedProgramModalVisible,
    setIsRemoveLicensedProgramModalVisible,
  ] = useState(false);
  const [_approvalRequested, setApprovalRequested] = useState(false);
  const approvedAmount = 0;
  const navigate = useNavigate();
  const first = index === 0;
  const last = index === cardCount - 1;
  const attachmentIsVideo =
    attachments?.[0]?.type === AttachmentTypeString.VideoString;
  const attachmentIsFile =
    attachments?.[0]?.type === AttachmentTypeString.FileString;
  const includeSeeDetails = step.type !== AcademyStepType.TextContent;
  const programInPlan = program && plan.find((p) => p.programId === program.id);
  let localizedAmount: string;
  let useDisclaimer: boolean;

  const {isFeatureFlagOn} = useFeatureFlags();

  const handleClickProgramDetails = useCallback(() => {
    switch (step.type) {
      case AcademyStepType.MarketplaceProgram:
        navigate(getProgramDetailsFromAcademyPath(academyId, step.programId));
        break;
      case AcademyStepType.CustomProgram:
        navigate(
          userCustomProgramDetailRoute(step.programId, true, {
            from: `academyId${academyId}`,
          })
        );
        break;
      default:
        break;
    }
  }, [navigate, step.programId, step.type]);

  const handleMarkAsCompleted = useCallback(() => {
    if (!isActivePlanItem) return;
    step.type === AcademyStepType.MarketplaceProgram
      ? markMarketplaceProgramAsComplete()
      : markCustomProgramAsComplete();
  }, [
    isActivePlanItem,
    markMarketplaceProgramAsComplete,
    markCustomProgramAsComplete,
    step.status.displayStatus,
    step.type,
  ]);

  // Approval Drawer
  const handleClickRequestApproval = useCallback(() => {
    if (!isActivePlanItem) return;
    setShowApprovalDrawer(true);
  }, [isActivePlanItem]);

  const handleApprovalDrawerOk = () => {
    if (!isActivePlanItem) return;
    setShowApprovalDrawer(false);
    onProgramStatusChanged?.();
  };

  const handleApprovalDrawerCancel = () => {
    if (!isActivePlanItem) return;
    setShowApprovalDrawer(false);
  };

  // Handle Request License
  const handleClickRequestLicense = useCallback(() => {
    if (!isActivePlanItem) return;
    setShowRequestLicenseDrawer(true);
  }, [isActivePlanItem]);

  const handleLicenseDrawerOk = () => {
    if (!isActivePlanItem) return;
    setCtaButtons(getCtaButtons(StepItemCTAButtonType.Requested));
    setShowRequestLicenseDrawer(false);
  };

  const handleLicenseDrawerCancel = () => {
    if (!isActivePlanItem) return;
    setShowRequestLicenseDrawer(false);
  };

  // Handle View License
  const handleClickViewLicense = useCallback(() => {
    if (!isActivePlanItem) return;
    setShowViewLicenseModal(true);
  }, [isActivePlanItem]);

  // Selected Handlers
  const handleRemoveProgramModalOk = () => {
    if (!isActivePlanItem) return;
    setApprovalRequested(false);
    onProgramStatusChanged?.();
  };

  const handleClickSelected = useCallback(() => {
    if (!isActivePlanItem) return;
    programInPlan?.licenseId > 0
      ? setIsRemoveLicensedProgramModalVisible(true)
      : setIsRemoveProgramModalVisible(true);
  }, [isActivePlanItem, programInPlan?.licenseId]);

  const handleSettingsActions = useMemo(() => {
    return () => {
      // do nothing
    };
  }, []);

  const getCtaButtonType = useCallback(
    function getCtaButtonType(): StepItemCTAButtonType {
      if (
        step.type === AcademyStepType.TextContent &&
        step.status.displayStatus !== DisplayStatus.Skipped
      ) {
        return StepItemCTAButtonType.MarkComplete;
      } else if (step.status.displayStatus === DisplayStatus.Completed) {
        return StepItemCTAButtonType.Completed;
      } else if (step.status.displayStatus === DisplayStatus.Skipped) {
        return StepItemCTAButtonType.Skipped;
      } else if (step?.licenseId) {
        if (
          inPlanWithActiveLicense(
            program?.selected,
            programInPlan?.status === UserPlanItemStatusString.Approved,
            programInPlan?.licenseId > 0,
            programInPlan?.daysUntilExpiration
          )
        ) {
          return StepItemCTAButtonType.ViewLicense;
        } else if (programInPlan?.status === UserPlanItemStatusString.Pending) {
          return StepItemCTAButtonType.Requested;
        } else if (
          programInPlan?.status === UserPlanItemStatusString.Rejected
        ) {
          return StepItemCTAButtonType.Rejected;
        } else if (
          !programInPlan?.licenseId &&
          programInPlan?.status === UserPlanItemStatusString.Approved
        ) {
          return StepItemCTAButtonType.Selected;
        } else {
          return StepItemCTAButtonType.RequestLicense;
        }
      } else if (program?.selected) {
        return StepItemCTAButtonType.Selected;
      } else if (program?.approvalRequired) {
        return StepItemCTAButtonType.RequestApproval;
      } else if (step.type === AcademyStepType.CustomProgram) {
        return StepItemCTAButtonType.ViewProgram;
      } else {
        return StepItemCTAButtonType.RequestApproval;
      }
    },
    [
      program?.approvalRequired,
      program?.selected,
      programInPlan?.daysUntilExpiration,
      programInPlan?.licenseId,
      programInPlan?.status,
      step?.licenseId,
      step.status.displayStatus,
      step.type,
    ]
  );

  const showVideoPlayer = useMemo(() => {
    return (
      attachmentIsVideo && (
        <Video
          cloudName="dsmsrmuyr"
          publicId={attachments[0]?.fileName}
          sourceTypes="mp4"
          controls
          width={640}
          style={{maxWidth: '100%'}}
        />
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments?.[0]?.fileName]);

  const getCtaButtons = useCallback(
    function getCtaButtons(ctaButtonType?: StepItemCTAButtonType) {
      ctaButtonType = ctaButtonType || getCtaButtonType();
      const buttons = [];
      switch (ctaButtonType) {
        case StepItemCTAButtonType.Completed:
          buttons.push(
            <ProgramCompletedWrapper key="completed">
              <CheckCircleOutlined
                style={{
                  color: COLORS.Green700,
                  fontSize: '1.375rem',
                }}
              />
              <ProgramCompletedText>
                {i18n.t(k.STATUS__COMPLETED)}
              </ProgramCompletedText>
            </ProgramCompletedWrapper>
          );
          break;
        case StepItemCTAButtonType.Skipped:
          buttons.push(
            <ProgramSkippedWrapper key="skipped">
              <ForwardOutlined
                aria-hidden="true"
                style={{
                  color: COLORS.Neutral600,
                  fontSize: '1.375rem',
                }}
              />
              <ProgramSkippedText>
                {i18n.t(k.STATUS__SKIPPED)}
              </ProgramSkippedText>
            </ProgramSkippedWrapper>
          );
          break;
        case StepItemCTAButtonType.Selected:
          buttons.push(
            <SelectedButton key="selected" onClick={handleClickSelected} />
          );
          break;
        case StepItemCTAButtonType.ViewProgram:
          buttons.push(
            <LearnInButton
              aria-label={i18n.t(k.PROGRAM__CUSTOM__VIEW__FORMAT, {
                item: step.title,
              })}
              key="viewProgram"
              tag={ButtonTags.Secondary}
              onClick={handleClickProgramDetails}>
              {i18n.t(k.PROGRAM__VIEW)}
            </LearnInButton>
          );
          break;
        case StepItemCTAButtonType.MarkComplete:
          buttons.push(
            <ContentCompleteButton
              title={step.title}
              key="complete"
              completed={isCompleted}
              onClick={handleMarkAsCompleted}
              disabled={!step.isCompletable}
            />
          );

          break;
        case StepItemCTAButtonType.ViewLicense:
          buttons.push(
            <ViewLicenseButton
              aria-label={i18n.t(k.A11Y__VIEW_ITEM__FORMAT, {item: step.title})}
              key="viewLicense"
              onClick={handleClickViewLicense}
            />
          );
          break;
        case StepItemCTAButtonType.RequestLicense:
          buttons.push(
            <RequestLicenseButton
              title={step.title}
              key="requestLicense"
              onClick={handleClickRequestLicense}
            />
          );
          break;
        case StepItemCTAButtonType.RequestApproval:
          buttons.push(
            <RequestApprovalButton
              title={step.title}
              key="requestApproval"
              onClick={handleClickRequestApproval}
            />
          );
          break;
        case StepItemCTAButtonType.Requested:
          buttons.push(
            <LearnInButton disabled key="requested" tag={ButtonTags.Primary}>
              {i18n.t(k.STATUS__REQUESTED)}
            </LearnInButton>
          );
          break;
        case StepItemCTAButtonType.Rejected:
          buttons.push(
            <ActionButton
              item={{
                ...programInPlan,
                programApprovalRequired: programInPlan.approvalRequired,
              }}
              itemType={programInPlan.itemType}
              handleShowRequestLicenseDrawer={setShowRequestLicenseDrawer}
              onSettings={handleSettingsActions}
            />
          );
          break;
        default:
          return null;
      }

      const contextContainerId = `context-container-${step.id}`;
      const isProgramType =
        AcademyStepType.MarketplaceProgram ||
        step.type === AcademyStepType.CustomProgram;

      const canMarkCompleteMarketplace =
        step.type === AcademyStepType.MarketplaceProgram &&
        step.status.displayStatus !== DisplayStatus.Completed &&
        step.status.displayStatus !== DisplayStatus.NotStarted &&
        step.status.displayStatus !== DisplayStatus.FundsRequested &&
        step.status.displayStatus !== DisplayStatus.AwaitingApproval;

      const canMarkCompleteCustom =
        step.type === AcademyStepType.CustomProgram &&
        program?.selected &&
        step.status.displayStatus !== DisplayStatus.Completed;
      const canSkipProgram =
        step.status.displayStatus !== DisplayStatus.Completed &&
        step.status.displayStatus !== DisplayStatus.Skipped;
      const canUnskipProgram =
        step.status.displayStatus === DisplayStatus.Skipped;
      const showSkipOption =
        (step.allowSkip && isFeatureFlagOn.SkipAcademyStep && canSkipProgram) ||
        (!isFeatureFlagOn.SkipAcademyStep && canSkipProgram);
      const showUnskipOption =
        (step.allowSkip &&
          isFeatureFlagOn.SkipAcademyStep &&
          canUnskipProgram) ||
        (!isFeatureFlagOn.SkipAcademyStep && canUnskipProgram);

      const menuItems = [
        showSkipOption && {
          label: i18n.t(isProgramType ? k.PROGRAM__SKIP : k.CONTENT__SKIP),
          onClick: () => {
            skipProgram(step.id);
          },
        },
        showUnskipOption && {
          label: i18n.t(isProgramType ? k.PROGRAM__UNSKIP : k.CONTENT__UNSKIP),
          onClick: () => {
            unskipProgram(step.id);
          },
        },
        (canMarkCompleteMarketplace || canMarkCompleteCustom) && {
          label: i18n.t(k.CTA__MARK_AS_COMPLETE),
          onClick: handleMarkAsCompleted,
        },
        step.status.displayStatus === DisplayStatus.Completed && {
          label: i18n.t(k.CTA__UNMARK_AS_COMPLETED),
          onClick: handleMarkAsCompleted,
        },
      ].filter((item) => !!item);
      return [
        ...buttons,
        includeSeeDetails && (
          <ViewDetailsButton
            title={step.title}
            key="seeDetails"
            onClick={handleClickProgramDetails}
          />
        ),
        <div
          key="contextContainer"
          id={contextContainerId}
          style={{
            marginLeft: '8px',
            flexGrow: 1,
            textAlign: 'right',
          }}>
          {menuItems.length ? (
            <ContextMenuButton
              title={step.title}
              key="context"
              size="large"
              overlayPlacement="bottomRight"
              popupContainerId={contextContainerId}
              itemId={step.id}
              menuItems={menuItems}
            />
          ) : null}
        </div>,
      ];
    },
    [
      handleClickProgramDetails,
      getCtaButtonType,
      handleClickRequestApproval,
      handleClickRequestLicense,
      handleClickSelected,
      handleClickViewLicense,
      handleMarkAsCompleted,
      handleSettingsActions,
      includeSeeDetails,
      program?.selected,
      programInPlan,
      skipProgram,
      step.id,
      step.status.displayStatus,
      step.title,
      step.type,
      step.allowSkip,
      unmarkAsCompleted,
      unskipProgram,
      isFeatureFlagOn.SkipAcademyStep,
    ]
  );

  useEffect(() => {
    setCtaButtons(getCtaButtons());
  }, [plan, program, step, getCtaButtons]);
  const showContentCard = [
    AcademyStepType.EventContent,
    AcademyStepType.ProjectContent,
    AcademyStepType.Discussion,
  ].includes(step.type);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldBeInFocus) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [shouldBeInFocus]);

  const tooltip = useLocalizedAmountDisclaimer({
    amount: step?.licenseCost || step?.cost,
    sourceCurrency: step?.licenseCost ? step?.licenseCurrency : step?.currency,
    usersCurrency,
    localizedAmount: program?.licenseCost
      ? formatCurrency(
          program?.licenseCost ? program?.licenseCost : step?.cost,
          usersCurrency,
          exchangeRate
        )
      : localizedAmount,
    useDisclaimer,
  });

  const getProgramMetaData = () => {
    switch (step.type) {
      case AcademyStepType.MarketplaceProgram:
        return [
          localizeGeneric(step?.programType),
          localizeDuration(step?.duration, isFeatureFlagOn.DurationStrings),
          step?.licenseId && i18n.t(k.LICENSE__AVAILABLE),
        ];
      case AcademyStepType.CustomProgram:
        return [
          localizeGeneric(step?.programType),
          localizeDuration(step?.duration, isFeatureFlagOn.DurationStrings),
          step.cohortDisplayed &&
            i18n.t(
              step.isFutureCohort
                ? k.COHORT__NEXT_START__FORMAT
                : k.COHORT__CURRENT__FORMAT,
              {
                date: parseToLocalMoment(
                  step.cohortDisplayed.startDateUtc
                ).format(DATE_FORMAT.MONTH_DAY_YEAR),
              }
            ),
        ];
      default:
        return [];
    }
  };

  const isLXPContent = step.type === AcademyStepType.LxpContent;

  const renderCard = () => {
    if (isLXPContent) {
      return (
        <CardContainer
          first={first}
          last={last}
          style={{marginLeft: withIndexRules ? '20px' : null}}
          ref={containerRef}>
          {isLXPContent && (
            <LxpContentCard
              step={step as any}
              onToggleCompletion={handleMarkAsCompleted}
              smallImageUrl={step.smallImageUrl}
              isCompleted={isCompleted}
            />
          )}
        </CardContainer>
      );
    }

    return (
      <CardContainer
        first={first}
        last={last}
        style={{marginLeft: withIndexRules ? '20px' : null}}
        ref={containerRef}>
        {showContentCard ? (
          <ContentCardContainer
            showSkipProgramButton={
              !isFeatureFlagOn.SkipAcademyStep || step.allowSkip
            }
            showUnskipProgramButton={
              !isFeatureFlagOn.SkipAcademyStep || step.allowSkip
            }
            messagesPreview={step.messagesPreview}
            userMessages={step.userMessages}
            messageCount={step.messageCount}
            discussionId={step?.discussionId}
            isFullPage={false}
            academyId={academyId}
            submissionCount={step?.peerSubmissionCount}
            showSkipProjectButton={
              (isFeatureFlagOn.SkipAcademyStep && step.allowSkip) ||
              !isFeatureFlagOn.SkipAcademyStep
            }
            dueDate={step.dueDateUtc}
            dueDateUtc={step.dueDateUtc}
            allowPeerVisibility={step?.allowPeerVisibility}
            links={step.links}
            isActivePlanItem={isActivePlanItem}
            levelId={levelId}
            rsvpStatus={temporarilyAdaptRsvpStatus(step.status)}
            contentType={mapAcademyStepTypeToContentType(step.type)}
            title={step.title}
            attachments={attachments}
            description={formattedDescription}
            startDateUtc={step.startDateUtc}
            endDateUtc={step.endDateUtc}
            showIndexRule={false}
            submissionType={step.submissionType}
            submittedFile={
              !!step.submittedFiles?.length &&
              step.submittedFiles[step?.submittedFiles?.length - 1]
            }
            submittedLink={
              !!step.submittedLinks?.length &&
              step.submittedLinks[step.submittedLinks.length - 1]
            }
            submittedResponse={step.submittedResponse}
            id={step.id}
            status={step.status}
            skipProgram={skipProgram}
            unskipProgram={unskipProgram}
            order={undefined}
            eventTimeZone={step.eventTimeZone}
            eventLink={step.eventLink}
            customizableLearningCategory={CustomizableLearningCategory.Academy}
            projectContentCompletedOn={step.projectContentCompletedOn}
            peerSubmissionCount={step.peerSubmissionCount}
            feedback={step.feedback}
            calendarLinks={getCalendarLinks({
              googleCalendarLink: step?.googleCalendarLink,
              outlookCalendarLink: step?.outlookCalendarLink,
            })}
            completed={step.status?.isDone}
          />
        ) : step.type === AcademyStepType.MarketplaceProgram ||
          step.type === AcademyStepType.CustomProgram ? (
          <SearchCard
            id={step.id}
            provider={step.providerName}
            providerImgSrc={step.providerLogoUrl}
            title={step.title}
            previewImgSrc={
              !!step.smallImageUrl
                ? resizeIfCloudinary(step.smallImageUrl, null, 307)
                : getPlaceholderImgSrc(step.id)
            }
            description={formattedDescription}
            buttons={ctaButtons}
            metadata={getProgramMetaData()}
            removeBottomMargin={true}
            headerData={getHeaderData({
              step,
              program,
              tooltip,
              usersCurrency,
              exchangeRate,
            })}
          />
        ) : (
          <CustomContentCardContainer>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <ContentContainerLeftColumn>
                <Title style={{marginBottom: '16px'}}>{step.title}</Title>
                {/* Description */}
                <CustomContentStepDescription>
                  {!!formattedDescription && (
                    <RichTextEditor
                      value={formattedDescription}
                      readOnly
                      theme="bubble"
                    />
                  )}
                  {/* Check with Taylor on when this code can be removed. See LI-5906 for more details */}
                  {credSparkDemoEnabled &&
                    !!step?.title &&
                    step?.title?.toLowerCase().includes('assessment') && (
                      <div
                        id="credspark-container"
                        className="credsparkQuiz"
                        data-quiz-id="skill-a-self-assessment-727b2f6dc4e04">
                        <p style={{height: '500px'}}>
                          <iframe
                            id="skill-a-self-assessment-727b2f6dc4e04-iframe"
                            title="skill-a-self-assessment-727b2f6dc4e04-iframe"
                            src="https://app.credspark.com/assessments/skill-a-self-assessment-727b2f6dc4e04/assessment_responses/new?"
                            width="100%"
                            style={{border: 'none'}}
                            height="100%"
                          />
                        </p>
                      </div>
                    )}
                  {attachmentIsVideo && (
                    <VideoContainer>{showVideoPlayer}</VideoContainer>
                  )}
                </CustomContentStepDescription>
                {/* Link */}
                {!!links?.[0] && (
                  <LinkContainer>
                    <ContentLink
                      title={links[0].title}
                      key={links[0].id}
                      url={formatUrl(links[0].url)}
                    />
                  </LinkContainer>
                )}
                {/* File Attachment Link */}
                {attachmentIsFile && (
                  <LinkContainer>
                    <ContentLinkText
                      key={attachments[0]?.url}
                      onClick={() => {
                        window.open(attachments[0]?.url, '_blank').focus();
                      }}>
                      <NewWindowIcon
                        style={{marginRight: '9px', width: '18px'}}
                        fill={COLORS.Blue800}
                      />
                      {i18n.t(k.FILE__ATTACHMENT_OPEN)}
                    </ContentLinkText>
                  </LinkContainer>
                )}
              </ContentContainerLeftColumn>
              {/* Right Container, File Attachment Preview */}
              {attachmentIsFile && (
                <button
                  onClick={() => {
                    window.open(attachments[0]?.url, '_blank').focus();
                  }}
                  style={{marginLeft: '16px', marginBottom: '10px'}}>
                  <AttachmentPreviewImg
                    style={{maxHeight: '200px', maxWidth: '200px'}}
                    src={
                      attachments[0]?.image ||
                      attachments[0]?.url ||
                      attachmentPreviewImg
                    }
                    onError={(e: any) => {
                      e.target.onerror = null;
                      e.target.src = attachmentPreviewImg;
                    }}
                  />
                </button>
              )}
            </div>
            <ButtonWrapper style={{marginTop: '24px'}}>
              {ctaButtons}
            </ButtonWrapper>
          </CustomContentCardContainer>
        )}
      </CardContainer>
    );
  };

  return (
    <>
      {withIndexRules ? (
        <WithStepIndexRule
          cardCount={cardCount}
          index={index}
          role="article"
          aria-label={i18n.t(k.A11Y__STEP__TITLE__FORMAT, {title: step.title})}>
          {renderCard()}
        </WithStepIndexRule>
      ) : (
        <> {renderCard()} </>
      )}
      {/* Drawers */}
      {showApprovalDrawer && (
        <PreApprovalDrawer
          itemId={step.programId}
          planItemType={PlanItemType.Program}
          currency={step.currency}
          visible={showApprovalDrawer}
          onOk={handleApprovalDrawerOk}
          onCancel={handleApprovalDrawerCancel}
        />
      )}
      {isRemoveProgramModalVisible && (
        <DeleteProgramItemModal
          isVisible={isRemoveProgramModalVisible}
          setIsVisible={setIsRemoveProgramModalVisible}
          programId={step.programId}
          programCurrency={step.currency}
          onOk={handleRemoveProgramModalOk}
          approvedAmount={approvedAmount}
        />
      )}
      {isRemoveLicensedProgramModalVisible && (
        <DeleteLicensedOrCustomProgramItemModal
          isVisible={isRemoveLicensedProgramModalVisible}
          setIsVisible={setIsRemoveLicensedProgramModalVisible}
          itemId={step.programId}
          onOk={() => {
            deletePlanItem();
            // Update button state
            program.selected = false;
            setCtaButtons(getCtaButtons());
          }}
        />
      )}
      {showViewLicenseModal && (
        <LicenseViewerModal
          provider={step.providerName}
          licenseId={step?.licenseId}
          programId={program?.id}
          onClose={() => {
            setShowViewLicenseModal(false);
          }}
        />
      )}
      {showRequestLicenseDrawer && (
        <PreApprovalDrawer
          visible={showRequestLicenseDrawer}
          itemId={step.programId}
          planItemType={PlanItemType.Program}
          currency={step.currency}
          onOk={handleLicenseDrawerOk}
          onCancel={handleLicenseDrawerCancel}
          licenseId={step?.licenseId}
        />
      )}
    </>
  );
}

export default StepCard;
