import React from 'react';
import {ContentType, CustomizableLearningCategory} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import EventForm from './EventForm';
import ProjectForm from './ProjectForm';
import {AcademyStepVM} from '@generated/interfaces';
import CustomContentForm from './CustomContentForm';
import DiscussionForm from './DiscussionForm';
import {charLimitErrorMessage} from '@components/reusable/LearnInForm';

export interface FormProps {
  step?: AcademyStepVM;
  showRichTextUploads: boolean;
  canShowAllowSkip: boolean;
  attachmentProps: any;
  learningCategory: CustomizableLearningCategory;
  /** @deprecated */
  stepRequirementComponent?: JSX.Element;
}

interface StepTypeConfig {
  contentDescription: string;
  component: React.FC<FormProps>;
}

export const getStepTypeConfig = (contentType: ContentType): StepTypeConfig => {
  const types = {
    [ContentType.Text]: {
      contentDescription: i18n.t(k.GENERIC__CONTENT),
      component: CustomContentForm,
    },
    [ContentType.Project]: {
      contentDescription: i18n.t(k.PROJECT),
      component: ProjectForm,
    },
    [ContentType.Event]: {
      contentDescription: i18n.t(k.EVENT),
      component: EventForm,
    },
    [ContentType.Discussion]: {
      contentDescription: i18n.t(k.DISCUSSION),
      component: DiscussionForm,
    },
  };
  return types[contentType];
};

/** Form validation & error messages */
export enum CharLimits {
  Title = 70,
  Description = 10000,
}

export const getFormFieldErrors = () => ({
  FileUploadInFlight: i18n.t(k.VALIDATION__IMAGE_IS_UPLOADING),
  MissingTitle: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.GENERIC__TITLE).toLocaleLowerCase(),
  }),
  MissingDescription: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.GENERIC__DESCRIPTION).toLocaleLowerCase(),
  }),
  CharLimitTitle: charLimitErrorMessage('title', CharLimits.Title),
  CharLimitDescription: charLimitErrorMessage(
    'description',
    CharLimits.Description
  ),
  submissionType: i18n.t(k.VALIDATION__SELECT_SUBMISSION_TYPE__POLITE),
  eventDate: i18n.t(k.VALIDATION__CHOOSE_EVENT_DATE__POLITE),
  startTime: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.TIME__START).toLocaleLowerCase(),
  }),
  endTime: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.TIME__END).toLocaleLowerCase(),
  }),
  MissingTimeZone: i18n.t(k.VALIDATION__SELECT_TIMEZONE__POLITE),
  dueDate: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.DATE__DUE_DATE).toLocaleLowerCase(),
  }),
  dueTime: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
    fieldName: i18n.t(k.TIME__DUE).toLocaleLowerCase(),
  }),
});
