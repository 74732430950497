import {useEffect, useState} from 'react';

/** @returns boolean of on/off state. Only supports booleans. */
export const useParamInSessionStorage = (
  featureFlagValue: boolean,
  sessionItemName: string,
  queryString: string
) => {
  const searchParams = new URLSearchParams(queryString);
  const sessionItemValue = searchParams.get(sessionItemName);

  if (sessionItemValue === 'true' && featureFlagValue === true) {
    sessionStorage.setItem(sessionItemName, 'true');
    return true;
  } else if (sessionItemValue === 'false' || featureFlagValue === false) {
    sessionStorage.removeItem(sessionItemName);
    return false;
  }

  return false;
};
