// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/MissingApprovalReason.cs

enum MissingApprovalReason {
  NoResponse,
  NoApprovalRequired,
  ApprovalObtainedManually,
  Fraud,
  OutOfPolicyUnintentional,
  OutOfPolicyIntentional,
  OutOfPolicyGeneral,
  MoreInformationNeeded,
}

export default MissingApprovalReason;
