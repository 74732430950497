import {useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {useLocation, useNavigate} from 'react-router-dom';
import {AcademyVM} from '@generated/interfaces';
import {
  BarChartOutlined,
  ExperimentOutlined,
  LayoutOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {useGetDefaultAcademyTheme} from '@utils/academyBranding';
import {
  AcademyDrawerSideBar,
  AcademyDrawerTabs,
  DrawerNavItem,
} from './AcademyDrawerSideBar';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useAuth} from '@utils/oidc-auth-wrapper';
import translate from '@i18n/translate';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {useManagedAcademiesQueryCached} from '@generated/hooks/permission.get.hooks';
import {useAcademiesIntegrationsChatChannelsQueryCached as useAcademyChatChannelsQueryCached} from '@generated/hooks/academyIntegration.get.hooks';
import {useProjectsQueryCached} from '@generated/hooks/academy.get.hooks';

export interface AcademyDrawerSideBarProps {
  academy?: AcademyVM;
  children: React.ReactNode;
  currentLevelTitle?: string;
  topAlign?: number;
  isLoading: boolean;
  subTitle?: React.ReactNode;
  getScreenNavItemUrlPath: (academyId: number, screen: string) => string;
  isActivePlanItem: boolean;
}

export default function AcademyDrawerSideBarContainer({
  academy,
  children,
  currentLevelTitle = '',
  topAlign,
  isLoading,
  getScreenNavItemUrlPath,
  isActivePlanItem,
}: AcademyDrawerSideBarProps) {
  const LOCATIONS = [
    {key: 'content', value: i18n.t(k.GENERIC__OVERVIEW)},
    {key: 'people', value: i18n.t(k.GENERIC__PEOPLE)},
    {key: 'projects', value: i18n.t(k.PROJECT__PLURAL)},
    {key: 'reports', value: i18n.t(k.REPORT__PLURAL)},
    {key: 'branding', value: i18n.t(k.BRAND__BRANDING)},
  ];

  const [isCollapsed, setIsCollapsed] = useState(true);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isBranding = pathname.includes('branding');
  const selectedLocation = LOCATIONS.find((location) =>
    pathname.includes(location.key)
  );
  const {showSkillsTagging} = useShowSkillsTagging();

  const {isFeatureFlagOn} = useFeatureFlags();

  // Data Fetching
  const {isAdmin} = useAuth();
  const {data: projects} = useProjectsQueryCached(
    {academyId: academy?.id},
    {enabled: !!academy?.id}
  );
  const {data: academyPermissions} = useManagedAcademiesQueryCached(null);

  const {user} = useAuth();

  const academyPermission = academyPermissions?.find(
    ({academyId}) => academyId === academy?.id
  );
  const hasReviewProjectPermission =
    isAdmin || academyPermission?.canReviewProject;

  const showProjectsNavItem =
    isBranding || (hasReviewProjectPermission && !!projects?.length);
  const showReportsNavItem =
    isAdmin || isBranding || academyPermission?.canViewReport;

  const {data: chatChannel, isLoading: isChatChannelLoading} =
    useAcademyChatChannelsQueryCached(
      {academyId: academy?.id},
      {enabled: !!academy?.id}
    );

  const selectedPage = (key: string) => {
    return pathname.includes(key) ? `${i18n.t(k.GENERIC__SELECTED_PAGE)} ` : '';
  };

  const navItems: DrawerNavItem[] = [
    {
      onClick: () => {
        navigate(getScreenNavItemUrlPath(academy?.id, 'content'));
      },
      isSelected: pathname.includes(AcademyDrawerTabs.Content) || isBranding,
      key: AcademyDrawerTabs.Content,
      ariaLabel: `${selectedPage(AcademyDrawerTabs.Content)}${i18n.t(
        k.ACADEMY__OVERVIEW_PAGE
      )}`,
      label: (
        <>
          <LayoutOutlined aria-hidden="true" /> {i18n.t(k.GENERIC__OVERVIEW)}
        </>
      ),
    },
    showProjectsNavItem && {
      onClick: () => navigate(getScreenNavItemUrlPath(academy?.id, 'projects')),
      isSelected: pathname.includes(AcademyDrawerTabs.Projects),
      key: AcademyDrawerTabs.Projects,
      ariaLabel: `${selectedPage(AcademyDrawerTabs.Projects)}${i18n.t(
        k.ACADEMY__PROJECT_PAGE
      )}`,
      label: (
        <>
          <ExperimentOutlined aria-hidden="true" /> {i18n.t(k.PROJECT__PLURAL)}
        </>
      ),
    },
    isFeatureFlagOn.AcademyPeople && {
      onClick: () => navigate(getScreenNavItemUrlPath(academy?.id, 'people')),
      isSelected: pathname.includes(AcademyDrawerTabs.People),
      key: AcademyDrawerTabs.People,
      ariaLabel: `${selectedPage(AcademyDrawerTabs.People)}${i18n.t(
        k.ACADEMY__PEOPLE_PAGE
      )}`,
      label: (
        <>
          <TeamOutlined aria-hidden="true" /> {i18n.t(k.GENERIC__PEOPLE)}
        </>
      ),
    },
    showReportsNavItem && {
      onClick: () => navigate(getScreenNavItemUrlPath(academy?.id, 'reports')),
      isSelected: pathname.includes('reports'),
      key: AcademyDrawerTabs.Reports,
      ariaLabel: `${selectedPage(AcademyDrawerTabs.Reports)}${i18n.t(
        k.ACADEMY__REPORT_PAGE
      )}`,
      label: (
        <>
          <BarChartOutlined aria-hidden="true" /> {i18n.t(k.REPORT__PLURAL)}
        </>
      ),
    },
  ]
    .filter(Boolean)
    .map((navItem) => {
      if (isBranding) {
        navItem.onClick = () => {
          /* disable nav items for branding screen*/
        };
      }
      return navItem;
    });

  useGetDefaultAcademyTheme(academy);
  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <AcademyDrawerSideBar
      chatChannel={chatChannel}
      isChatChannelLoading={isChatChannelLoading}
      academy={academy}
      currentLevelTitle={currentLevelTitle}
      handleCollapse={handleCollapse}
      isCollapsed={isCollapsed}
      isLoading={isLoading}
      navItems={navItems}
      subTitle={selectedLocation?.value}
      isBranding={isBranding}
      title={`${translate('WELCOME')} ${user?.firstName}!`}
      topAlign={topAlign}
      canShowSkills={showSkillsTagging}
      isActivePlanItem={isActivePlanItem}>
      {children}
    </AcademyDrawerSideBar>
  );
}
