import {ExchangeRatePayload} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {ExchangeRatePayloadSchema} from '../zodSchemas/ExchangeRatePayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Localization/LocalizationController.cs

/*
|--------------------------------------------------------------------------
|  exchange-rates 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useExchangeRatesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: exchangeRates,
 *    isLoading: isExchangeRatesLoading,
 *    invalidateExact: invalidateExchangeRates,
 *  } = useExchangeRatesQuery(null)
 *
 */

export const useExchangeRatesQuery = queryHookFactory<
  null,
  ExchangeRatePayload[] // return type
>('useExchangeRatesQuery', () => `localization/exchange-rates`, {
  responseSchema: z.array(ExchangeRatePayloadSchema),
});

// CACHED
export const useExchangeRatesQueryCached = queryHookFactory<
  null,
  ExchangeRatePayload[] // return type
>(
  'useExchangeRatesQuery',
  () => `localization/exchange-rates`,
  {responseSchema: z.array(ExchangeRatePayloadSchema)},
  {staleTime: 600000}
);
